import React, {useContext, useEffect, useState} from "react";
import { Link, useParams } from "react-router-dom"
import { Container, Card, CardBody, Col, Row, } from "reactstrap"
import { MainContext } from "contexts/Main.context";
import { placeholder } from "assets";
const NewsDetails = () => {

	let { id } = useParams();
	const { backend, formatter } = useContext(MainContext);
	const [rows, setRows] = useState([]);

	useEffect(() => {
			pullData();
		},[]
	);
	const pullData = async () => {
		const {data} = await backend.getNewsletter(id);
		if(data){
			setRows(data);
		}
	};

	const render = () => {
    return (
			<div className="content" dangerouslySetInnerHTML={{__html: rows.content}}></div>
    );
	};
	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Row>
						<Col lg={12}>
							<Card className="no-hover">
								<CardBody>
									{/*<Link to="/uv-newsletter">*/}
									{/*		<h3 className="text-primary">*/}
									{/*				<i className="bx bx-arrow-back" />{' '}*/}
									{/*				Volver*/}
									{/*		</h3>*/}
									{/*</Link>*/}
									<div className="pt-3">
										<div className="row justify-content-center">
											<div className="col-xl-8">
												<div>
													<div className="text-center mt-4">
															<h1>{rows.name}</h1>
													</div>
												 	<hr />
													<div className="text-center">
														<Row>
															<Col sm={12}>
																<div className="mt-4 mt-sm-0">
																	<p className="text-muted mb-2">Fecha:</p>
																	<h5 className="font-size-15">{formatter.date(rows.cratedAt ?? new Date())}</h5>
																</div>
															</Col>
														</Row>
													</div>
													<hr />
													<div className="my-5">
															<img src={rows.thumbnailUri ?? placeholder} alt="" className="img-thumbnail mx-auto d-block" />
													</div>
													<hr />
													<div className="mt-4">
														<div id="text-div" className="text-muted font-size-20" style={{textAlign: 'justify'}}>
															{render()}
														</div>
														<hr />
													</div>
												</div>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default NewsDetails
