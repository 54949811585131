import React, { useState } from "react"
import UvBoletines from "components/Newsletter/uv-Boletines"
import { Col, Container, Row, TabContent, TabPane } from "reactstrap"

const UvNewsletter = () => {

	const [activeTab, toggleTab] = useState("1")
	const toggle = tab => {
		if (activeTab !== tab) toggleTab(tab)
	}

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Row className="align-items-center">
						<Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Anuncios </h4> </Col>
						<Col xs={12} md={6}>
							<Row className="d-flex justify-content-end">
								<Col xs={12} md={4} className=""></Col>
								<Col xs={12} md={4} className=""></Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col xs="12">
							<TabContent className="" activeTab={activeTab}>
								<TabPane tabId="1">
									<Row className="">
										<Col xs="12">
											<UvBoletines/>
										</Col>
									</Row>
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};
export default UvNewsletter
