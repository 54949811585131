import React, {useState, useEffect, useContext} from "react"
import Select from 'react-select'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import { MainContext } from "contexts/Main.context";
import { NoticiasBlog } from "components/Helpers/Noticiasblog";
import { CustomPagination } from "components/Common/CustomPagination";
import { Container, Row, Col, Button, CardBody, Card, Label, Input } from "reactstrap"

const Newsletter = () => {

  const defaultFilter = {
        id: '',
        date_from: '',
        date_to: '',
  };

  const [id, setId] = useState([]);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);
  const [page, setPage] = useState(1);
  const {backend} = useContext(MainContext);
  const [totalPages, setTotalPages] = useState(1);

  //GET
  useEffect(() => {
    pullData();
    pullId();
    },[page]
  );
  //NEWS
  const pullData = async () => {
    const {data, meta} = await backend.getNews({page, where: filter});
    if(data){
      setTotalPages(meta.lastPage);
      setRows(data);
    }
  };
  //IDs
  const pullId = async () => {
    const {data} = await backend.getNews({page, limit: 999, attributes:["id","name"]});
    if(data){
      setId(data);
    }
  };
  const options = (array) => {
    const options = [{id: "All", value: "",  label: "todos"}]
    array.map((el) => options.push({id: el.id, value: el.id,  label: el.name}));
    return options
  };

  return (
    <React.Fragment>
      <MetaTags> <title> Anuncios | Condominio la raya </title> </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Anuncios </h4> </Col>
            <Col xs={12} md={6}>
              <Row className="d-flex justify-content-end">
                <Col xs={12} md={4} className="d-flex justify-content-end"></Col>
                <Col xs={12} md={4} className="mb-4">
                  <Link to="/newsletter/create-post">
                    <Button
                      type="button"
                      color="success"
                      className="w-100" >
                        Crear
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-4 d-flex align-items-center">
            <Col xs={12} md={2} className="mt-3 mb-0">
              <Label className=""> Desde: </Label>
              <Input
                type="date"
                className="form-control"
                name="date_from"
                onChange={(ev)=> {
                    const _form = {...filter};
                    _form.date_from = ev.target.value;
                    setFilter(_form);
                }}
                />
            </Col>
            <Col xs={12} md={2} className="mt-3 mb-0">
              <Label className=""> Hasta: </Label>
              <Input
                type="date"
                name="date_to"
                className="form-control"
                onChange={(ev)=> {
                    const _form = {...filter};
                    _form.date_to = ev.target.value;
                    setFilter(_form);
                }}
                />
            </Col>
            <Col xs={12} md={2} className="mt-3 mb-0">
              <Label>Nombre:</Label>
              <Select
                isMulti={true}
                placeholder="Ingresar..."
                options={options(id)}
                onChange={(selected)=> {
                  const result = selected.filter(el => el.value === 'All');
                  const _form = {...filter};
                  result[0] ? _form.id = "" : _form.id = selected.map(el => el.value).join();
                  setFilter(_form);}}
                />
            </Col>
            <Col xs={12} md={2}> <br/> <br/>
              <Button type="button" color="primary" className="w-100" onClick={()=> pullData()}>
                Filtrar
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  < NoticiasBlog rows={rows}/>
                  <Row className="mt-4">
                    <CustomPagination currentPage={page} setPage={setPage} totalPages={totalPages}/>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Newsletter;

