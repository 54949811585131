import React, { useContext, useState } from "react";
import PropTypes, { bool, number, string } from 'prop-types';
import Select from "react-select";
import { MainContext } from "contexts/Main.context";
import { PhoneDelete } from "./uv-phone-delete";
import { TableWrapper } from "components/Helpers/TableWrapper";
import { EnumService } from "services/enum.service";
import {Label, Input, Modal, Row, CardTitle, Button, Col, Container} from 'reactstrap';
import { useTable } from "react-table";

export const PhoneViews = ({active, toggle, phones, user}) => {	
	const defaultForm = {
    user_id: user,
    number:'',
		extension:'',
    type:'',
		attendant:'',
	}
	
	const [form, setForm] = useState(defaultForm);
	const {backend, showLoader, toastAlert, changeHandler, formatter} = useContext(MainContext);
	const [element, setElement ] = useState({});
	const [modals, setModals] = useState({create: false, update: false, delete: false});
	const [create, setCreate] = useState(false);

	const toggleModal = (modal) => {
		const mdl = {...modals};
		mdl[modal] = !mdl[modal];
		setModals(mdl);
	};

	const submitHandler = () => {        
		showLoader(true);
		uploadData()
		setCreate(false);
	};
	const uploadData = async () => {
		const resp = await backend.newPhone(form);
		showLoader(false);
		if (resp.status) {
			toastAlert({body: 'Creado correctamente correctamente', color: 'primary'});
			location.reload();
		} else {
			toastAlert({body: 'Error inesperado', color: 'warning'});
		}
	};

	const columns = [
		{name: 'Tipo'},
		{name: 'Números'},
		{name: 'Encargado'},
		{name: 'Extensión'},
		{name: ''}
	];
	return(
		<React.Fragment>
			{modals.delete ? (<PhoneDelete active={modals.delete} phone={element} toggle={toggleModal} />) : (<></>)}
			<Modal 
				centered
				isOpen={active}
				toggle={() => { toggle('phones'); }} >
				<Container style={{minHeight: "200px"}}>
					<Row>
						<CardTitle className="mt-4">
							Telefonos de usuario
						</CardTitle>
						<Button 
							onClick={() => {toggle('phones');}} 
							type="button" 
							className="close"
							data-dismiss="modal" 
							aria-label="Close">
						</Button>
					</Row>
					<Row>
						<Col>
							<Button 
								type="button" 
								color={'primary'} 
								data-toggle="modal" 
								data-target="#myModal"
								className="float-end font-size-15 mt-2 mb-4"
								onClick={() => {setCreate(!create)}}>
									Crear
							</Button>
						</Col>
					</Row>
					{create ? 
					<Modal  centered isOpen={active} toggle={() => {setCreate(!create)}}>
						<Container>
							<Row>
								<CardTitle className="mt-4">
									Crear teléfono
								</CardTitle>
								<Button 
									onClick={() => {setCreate(!create)}} 
									type="button" 
									className="close"
									data-dismiss="modal" 
									aria-label="Close">
								</Button>
							</Row>
							<form className="my-4">
								<Row>
									<Col xs={12} sm={6} className="mb-3">
										<Label>Tipo de telefono:</Label>
										<Select 
											required
											isMulti={false}
											name="type"
											placeholder="Ingresar..."
											options={EnumService.typePhones} 
											onChange={(selected)=> {
												const _form = {...form};
												_form.type = selected.value,
												setForm(_form);}}
										/>
									</Col>
									{ form.type === "OFFICE" ? 	
										<Col xs={12} sm={6} className="mb-3">
											<Label>Extensión:</Label>
											<Input 
												required
												name='extension'
												type='number'
												className="form-control" 
												onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
										</Col>
									: <></>}
									<Col xs={12} sm={6} className="mb-3">
										<Label>Encargado:</Label>
										<Input 
											required
											name='attendant'
											className="form-control" 
											onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
									</Col>
									<Col xs={12} sm={6} className="mb-3">
										<Label>Número:</Label>
										<Input 
											required
											name='number'
											type='number'
											className="form-control" 
											onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
									</Col>
									<Col xs={12}>
										<Button
											type="button" 
											color={'primary'} 
											data-toggle="modal" 
											data-target="#myModal"
											className="float-end font-size-15 w-100"
											onClick={()=> submitHandler()}>
												Guardar número
										</Button>
									</Col>
								</Row>
							</form>
						</Container>
					</Modal>
					:<></>}
					<Row>
						<Col>
							<TableWrapper headers={columns}>
								{phones ? phones.map((el => <tr key={el.id}>
									<td className="text-nowrap">{formatter.typeEnum(el.type)}</td>
									<td className="text-nowrap">{el.number}</td>
									<td className="text-nowrap">{el.attendant ?? 'Sin especificar'}</td>
									<td className="text-nowrap">{el.extension ?? 'Sin especificar'}</td>
									<td className="text-nowrap">
										<Row className="row-buttons-container flex-nowrap d-flex justify-content-end">
											<Col xs={12} className="my-2">
												<Button 
													type="button" 
													color={'danger'} 
													data-toggle="modal" 
													data-target="#myModal"
													className="font-size-15 w-100"
													onClick={() => {setElement(el.id); toggleModal('delete')}}>
														Eliminar
												</Button>
											</Col>
										</Row>
									</td>
								</tr>)): <></>}
							</TableWrapper>
						</Col>
					</Row>
				</Container>
			</Modal>
		</React.Fragment>
	);
};
PhoneViews.propTypes = {
	active: PropTypes.bool,
	toggle: PropTypes.func,
	phones: PropTypes.any,
	user: PropTypes.any
};