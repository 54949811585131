import React, {useContext, useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import {TableWrapper} from "../../components/Helpers/TableWrapper";
import {MainContext} from '../../contexts/Main.context';
import {Card, CardBody, Col, Container, Row} from "reactstrap"
import {CustomPagination} from "../../components/Common/CustomPagination";

export const UvMeetings = () => {

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1); 
    const {backend, formatter} = useContext(MainContext);
    const [totalPages, setTotalPages] = useState(1);

    //get
    useEffect(() => {
        pullData();
    }, [page])
    const pullData = async () => {
        const {data, meta} = await backend.getMeetings({page});
        if(data){
            setTotalPages(meta.lastPage);
            setRows(data);
        }
    };

    const columns = [
        {name: '#'},
        {name: 'Fecha de Creacion'},
        {name: 'Título'},
        {name: 'Descripción'},
        {name: 'Fecha'},
        {name: 'Fecha Límite'},
        {name: 'Url'},
        {name: ''},
    ];
    return (
        <React.Fragment>
            <MetaTags> <title> Ver reuniones | Condominio la raya </title> </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Reuniones </h4> </Col>
                        <Col xs={12} md={6}>
                            <Row className="d-flex justify-content-end">
                                <Col xs={12} md={4} className="mb-4">
                                </Col>
                                <Col xs={12} md={4} className="mb-4">
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive text-nowrap">
                                                <TableWrapper headers={columns}>
                                                    {rows ? rows.map((meetings =><tr key={meetings.id} style={{verticalAlign: 'middle'}}>
                                                        <td className="text-nowrap font-size-15 py-3">{meetings.id}</td>
                                                        <td className="text-nowrap font-size-15">{formatter.date(meetings.createdAt)}</td>
                                                        <td className="text-nowrap font-size-15">{meetings.title}</td>
                                                        <td className="text-nowrap font-size-15">{meetings.description}</td>
                                                        <td className="text-nowrap font-size-15">{formatter.date(meetings.date)}</td>
                                                        <td className="text-nowrap font-size-15">{formatter.date(meetings.finishDate)}</td>
                                                        <td className="text-nowrap font-size-15"><a href={meetings.url}>{meetings.url}</a></td>
                                                    </tr>)) : (<></>)}
                                                </TableWrapper>
                                            </div>
                                            <Row className="mt-4">
                                                <CustomPagination currentPage={page} setPage={setPage} totalPages={totalPages}/>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};