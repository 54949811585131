import React, {useContext, useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import Select from "react-select";
import {TableWrapper} from "../../components/Helpers/TableWrapper";
import {MainContext} from '../../contexts/Main.context';
import {Create} from "components/Meetings/Create";
import {Delete} from "components/Meetings/Delete";
import {Update} from "components/Meetings/Update";
import {Button, Card, CardBody, Col, Container, Label, Row, Input} from "reactstrap"
import {CustomPagination} from "../../components/Common/CustomPagination";

const Meetings = () => {

    const defaultValues = {
        title:'',
        id:'',
    };

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [id, setId] = useState([]);
    const [name, setName] = useState([]);
    const {backend, formatter, changeHandler} = useContext(MainContext);
    const [totalPages, setTotalPages] = useState(1);
    const [filter, setFilter] = useState(defaultValues);
    const [meeting, setMeeting ] = useState({});

    //get
    useEffect(() => {
        pullData();
        pullId();
    }, [page])
    const pullData = async () => {
        const {data, meta} = await backend.getMeetings({page, where: filter});
        if(data){
            setTotalPages(meta.lastPage);
            setRows(data);
        }
    };
    const pullId = async () => {
        const {data} = await backend.getMeetings({page, limit: 999, attributes:["id", "title"]});
        if(data){
            setId(data);
            setName(data);
        }
    };
    const options = (withAll = true, array, name = false) => {
        const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
        array.map((el) => _options.push({id:el.id, value: name ? el.title : el.id,  label: name ? el.title : el.id}));
        return _options;
    };

    const [modals, setModals] = useState({
        create: false,
        delete: false,
        update: false,
    });

    const toggleModal = (modal) => {
        const mdl = {...modals};
        mdl[modal] = !mdl[modal];
        setModals(mdl);
    };

    const columns = [
        {name: '#'},
        {name: 'Fecha de Creacion'},
        {name: 'Título'},
        {name: 'Descripción'},
        {name: 'Fecha'},
        {name: 'Fecha Límite'},
        {name: 'Url'},
        {name: ''},
    ];
    return (
        <React.Fragment>
            <MetaTags> <title> Reuniones | Condominio la raya </title> </MetaTags>
            {modals.create ? (<Create active={modals.create} toggle={toggleModal} />) : (<></>)}
            {modals.delete ? (<Delete active={modals.delete} meeting={meeting} toggle={toggleModal} />) : (<></>)}
            {modals.update ? (<Update active={modals.update} meeting={meeting} toggle={toggleModal} />) : (<></>)}

            <div className="page-content">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Reuniones </h4> </Col>
                        <Col xs={12} md={6}>
                            <Row className="d-flex justify-content-end">
                                <Col xs={12} md={4} className="mb-4">
                                </Col>
                                <Col xs={12} md={4} className="mb-4">
                                    <Button 
                                    type="button" 
                                    color="success"
                                    className="w-100"
                                    onClick={() => {toggleModal('create');}}>
                                        Crear
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mb-4 d-flex align-items-center">
                        <Col xs={12} md={2} className="mt-3 mb-0">
                            <Label>Nombre:</Label>
                            <Select 
                                isMulti={true}
                                placeholder="Ingresar..."
                                options={options(true, name, true)} 
                                onChange={(selected)=> {
                                const result = selected.filter(el => el.value == 'All');
                                const _form = {...filter};
                                result[0] ? _form.title = "" : _form.title = selected.map(el => el.value).join();
                                setFilter(_form);}}
                                />
                        </Col>
                        <Col xs={12} md={2} className="mt-3 mb-0">
                            <Label>Buscar por #:</Label>
                            <Select 
                                isMulti={true}
                                placeholder="Ingresar..."
                                options={options(true, id)} 
                                onChange={(selected)=> {
                                    const result = selected.filter(el => el.value == 'All');
                                    const _form = {...filter};
                                    result[0] ? _form.id = "" : _form.id = selected.map(el => el.value).join();
                                    setFilter(_form);}}
                            />
                        </Col>
                        <Col xs={12} md={2}> <br/> <br/>
                            <Button type="button" color="primary" className="w-100" onClick={()=> pullData()}>
                                Filtrar
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive text-nowrap">
                                                <TableWrapper headers={columns}>
                                                    {rows ? rows.map((meetings =><tr key={meetings.id} style={{verticalAlign: 'middle'}}>
                                                        <td className="text-nowrap font-size-15 py-3">{meetings.id}</td>
                                                        <td className="text-nowrap font-size-15">{formatter.date(meetings.createdAt)}</td>
                                                        <td className="text-nowrap font-size-15">{meetings.title}</td>
                                                        <td className="text-nowrap font-size-15">{meetings.description}</td>
                                                        <td className="text-nowrap font-size-15">{formatter.date(meetings.date)}</td>
                                                        <td className="text-nowrap font-size-15">{formatter.date(meetings.finishDate)}</td>
                                                        <td className="text-nowrap font-size-15"><a href={meetings.url}>{meetings.url}</a></td>
                                                        <td className="text-nowrap font-size-14">
                                                            <Row className="row-buttons-container flex-nowrap">
                                                                <Col xs={12} xxl={6} className="my-2">
                                                                    <Button 
                                                                        type="button" 
                                                                        color={'info'} 
                                                                        outline={true} 
                                                                        data-toggle="modal" 
                                                                        data-target="#myModal"
                                                                        className="font-size-15 h-100 w-100"
                                                                        onClick={() => {setMeeting(meetings.id); toggleModal('update')}}>
                                                                            Detalles
                                                                    </Button>
                                                                </Col>
                                                                <Col xs={12} xxl={6} className="my-2">
                                                                    <Button 
                                                                        type="button" 
                                                                        color="danger" 
                                                                        outline={true} 
                                                                        data-toggle="modal"
                                                                        className="font-size-15 h-100 w-100"
                                                                        onClick={() => {setMeeting(meetings.id); toggleModal('delete')}}>
                                                                            Eliminar
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>)) : (<></>)}
                                                </TableWrapper>
                                            </div>
                                            <Row className="mt-4">
                                                <CustomPagination currentPage={page} setPage={setPage} totalPages={totalPages}/>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Meetings;