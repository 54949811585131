import React, {useContext, useState} from "react";
import {Button, FormGroup, Input, Label, Row, Col} from "reactstrap";
import {MainContext} from "../../../../../contexts/Main.context";
import {EnumService} from "../../../../../services/enum.service";
import PropTypes from "prop-types";

const ModalProof = ({paymentRequest, toggle}) => {

    const defaultForm = {
        amount: paymentRequest.total,
        method: '',
        paymentRequestId: paymentRequest.id,
        date: '',//2022-06-06 03:49:56
        nop: '',
        bank: '',
        image: '',
        secondImage: '',
        payerNote: '',
    };

    const {backend, showLoader, toastAlert, changeHandler} = useContext(MainContext);
    const [form, setForm] = useState(defaultForm);

    const submitHandler = (ev) => {
        showLoader(true);
        ev.preventDefault();
        uploadData()
        //sed to the backend
    };

    const uploadData = async () => {
        const resp = await backend.newPayment(form);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'Subido correctamente', color: 'primary'});
            toggle();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
    }

    return(
        <form onSubmit={submitHandler}>
            <Row>
                <div className="col-xl col-sm-6">
                    <FormGroup className="mt-6 mb-0">
                        <Label>Fecha del Pago*</Label>
                        <Input onChange={(ev)=>{changeHandler(ev,form, setForm)}}
                               className="form-control" type="date" name="date">
                        </Input>
                    </FormGroup>
                </div>
                <div className="col-md-6 col-sm-12">
                    <FormGroup className="mt-6 mb-0">
                        <Label>Metodo de Pago*</Label>
                        <Input type="select" className="form-control select2-search-disable" name="method"
                               onChange={(ev)=>{changeHandler(ev,form, setForm)}}
                               required={true}>
                            <option value="">Elige</option>
                            {EnumService.paymentMethods.filter(pm => pm.id > 1).map(pm =>
                              (<option key={pm.id} value={pm.slug}>{pm.label}</option>)
                            )}
                        </Input>
                    </FormGroup>
                </div>
            </Row>
            {form.method === 'BANK-TRANSFER' || form.method === 'DEPOSIT' || form.method === 'OTHER' ?
                (<>
                    <Row className="mb-12">
                        <div className="col-xl col-sm-6">
                            <FormGroup className="mt-6 mb-0">
                                <Label>NOP*</Label>
                                <Input onChange={(ev)=>{changeHandler(ev,form, setForm)}}
                                       className="form-control" type="text" name="nop" required={true}/>
                            </FormGroup>
                        </div>
                        <div className="col-xl col-sm-6">
                            <FormGroup className="mt-6 mb-0">
                                <Label>Banco*</Label>s
                                <Input type="select" className="form-control select2-search-disable" name="bank"
                                       onChange={(ev)=>{changeHandler(ev,form, setForm)}}
                                       required={true}>
                                    <option value="">Elige</option>
                                    {EnumService.banks.map(pm =>
                                      (<option key={pm.id} value={pm.slug}>{pm.label}</option>)
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                    </Row>
                </>) : (<></>)}
                    <Row className="mb-12">
                        <div className="col-xl col-sm-6">
                            <FormGroup className="mt-6 mb-0">
                                <Label>Imagen del Comprobante*</Label>
                                <Input onChange={(ev)=>{changeHandler(ev,form, setForm, true)}}
                                       className="form-control" type="file" name="image"/>
                            </FormGroup>
                        </div>
                        <div className="col-xl col-sm-6">
                            <FormGroup className="mt-6 mb-0">
                                <Label>Imagen alternativa</Label>
                                <Input className="form-control" type="file" name="secondImage"
                                       onChange={(ev)=>{changeHandler(ev,form, setForm, true)}}/>
                            </FormGroup>
                        </div>
                    </Row>
            <Row>
                <FormGroup>
                    <Label>Comentario</Label>
                    <Input className="" type="textarea" name="payerNote"
                           onChange={(ev)=>{changeHandler(ev,form,setForm)}} height={300}/>
                </FormGroup>
            </Row>
            <Row>
                <Col sm={12}>
                    <Button color="primary mb-3">
                        Enviar
                    </Button>
                </Col>
            </Row>
        </form>
    )
}

ModalProof.propTypes = {
    paymentRequest: PropTypes.object,
    toggle: PropTypes.func,
};


export default ModalProof
