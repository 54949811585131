import React from "react";

const UvActivities = () => {
    return( 
        <React.Fragment>
            <div className="page-content">
                Hola
            </div>
        </React.Fragment>
    )
}

export default UvActivities