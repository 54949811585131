import React,  {useContext, useState, useEffect} from "react";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import {Button, Card, Col, Row} from "reactstrap"
import { MainContext } from "contexts/Main.context";
import img1 from "../../assets/images/small/img-2.jpg"

 const UvBoletines = ({}) => {

	const defaultFilter = {
    type: '',
	};

  const {backend, formatter, changeHandler} = useContext(MainContext);
  const [page, setPage] = useState(1);
	const [filter, setFilter] = useState(defaultFilter);
  const [rows, setRows] = useState([]);

  useEffect(
    () => {pullData();},
    [page]
  );

  const pullData = async () => {
      const {data, meta} = await backend.getNews({page, where: filter});
      if(data){
        setRows(data);
      }
  };

	return(
		<React.Fragment>
				<Row>
					{rows ? rows.map(news => (
						<Col xl={4} md={6} key={news.id}>
							<Link to={news.id ? `/uv-newsletter/` + news.id : `/uv-newsletter/`} className="text-primary">
								<Card className="p-3 border">
									<div className="p-3">
										<h5>
											<div className="text-dark">
												{news.name}
											</div>
										</h5>
										<p className="text-muted mb-0">{formatter.date(news.cratedAt)}</p>
									</div>
									<div className="position-relative">
										<img src={news.thumbnailUri ?? img1} alt="" className="img-thumbnail"/>
									</div>
									<div className="p-3">
										<div>
											<div className="text-primary">
												<Button type="primary" color={"primary"}>
														Leer más{" "}
														<i className="mdi mdi-arrow-right"/>
												</Button>
											</div>
										</div>
									</div>
								</Card>
							</Link>
						</Col>
                    )) : <></> }
				</Row>
		</React.Fragment>
	);
};	
UvBoletines.propTypes = {
  data: PropTypes.any,
}

export default UvBoletines