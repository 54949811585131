import React, { useState, useContext } from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import { Card, CardBody, CardTitle, Button, Col, CardHeader, TabContent, TabPane} from "reactstrap";
import { MainContext } from "contexts/Main.context";
import img1 from "../../../assets/seguridad-privada.jpg";
import SimpleBar from "simplebar-react"

const Posts = ({data}) => {
  const {formatter} = useContext(MainContext);

  return (
    <React.Fragment>
      <Col xl={12} lg={12}>
        <Card>
          <CardBody>
          <CardTitle className=" pb-3 bg-transparent border-bottom">Nuevos Boletines</CardTitle>
           <SimpleBar style={{ maxHeight: "273px"}}>
               <TabPane className="show" tabId="1">
                 <ul className="list-group list-group-flush">
                   {/* Posts*/}
                   {data ? data.map((post, key) => (
                     <li className="list-group-item py-3 mt-1 mb-2" key={key}>
                       <div className="d-flex">
                         <div className="me-3">
                           <img
                             src={post.thumbnailUri ?? img1}
                             alt="post-image"
                             className="avatar-md h-auto d-block rounded"
                           />
                         </div>
                         <div className="align-self-center overflow-hidden me-auto">
                           <div>
                             <h5 className="font-size-14 text-truncate">
                               <Link to={`/uv-newsletter/${post.id}`} className="text-dark">
                                 {post.name}
                               </Link>
                             </h5>
                             <p className="text-muted mb-0">{formatter.date(post.createdAt)}</p>
                           </div>
                         </div>
                       </div>
                     </li>
                   )): <></>}
                 </ul>
               </TabPane>
           </SimpleBar>
             <div className="">
                 <Link to="/newsletter">
                     <Button type="primary" color={'primary'} className="w-100">
                         Ver más
                     </Button>
                 </Link>
             </div>
         </CardBody>
       </Card>
     </Col>
   </React.Fragment>
  );  
};
Posts.propTypes = {
  data: PropTypes.any,
};
export default Posts