import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { MainContext } from "../../contexts/Main.context"
import { Invoice } from "components/Common/permissions-Invoice"
import { Card, CardBody, Input, Label, Modal, Row, CardTitle, Button, Col } from "reactstrap"

export const UvPermissions = ({ active, toggle, user, session }) => {

  const defaultForm = {
    completeName: user.name + " " + user.surname,
    lote: session.data.lot.slug,
    document: user.document,
    PermissionDocument:"",
    permission: "",
    date: "",
  }

  const { changeHandler } = useContext(MainContext)
	const [showInvoice, setShowInvoice] = useState(false);
  const [form, setForm] = useState(defaultForm)

  return (
    <>
      <Modal isOpen={showInvoice} centered={true}>
        <Card>
          <CardBody>
            <Invoice
              info={form}
              toggle={() => { setShowInvoice(!showInvoice) }}
            />
          </CardBody>
        </Card>
      </Modal>
      <Modal
        size="l"
        centered
        isOpen={active}
        toggle={() => {
          toggle("permissions")
        }}
      >
        <Row>
          <CardTitle className="mt-4 mx-3">
            Ingresa los datos para generar permiso
          </CardTitle>
          <Button
            onClick={() => { toggle("permissions") }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></Button>
        </Row>
        <form>
          <Row className="modal-body">
            <Col xs={12} className="mb-3">
              <Label>Nombre:</Label>
              <Input
                required
                name="completeName"
                value={form.completeName}
                className="form-control"
                onChange={ev => {
                  changeHandler(ev, form, setForm)
                }}
              />
            </Col>
            <Col xs={12} sm={6} className="mb-3">
              <Label>Documento:</Label>
              <Input
                required
                name="document"
                type="number"
                className="form-control"
                onChange={ev => {
                  changeHandler(ev, form, setForm)
                }}
              />
            </Col>
            <Col xs={12} sm={6} className="mb-3">
              <Label>Lote:</Label>
              <Input
                required
                name="lote"
                type="text"
                value={form.lote}
                className="form-control"
                onChange={ev => {
                  changeHandler(ev, form, setForm)
                }}
              />
            </Col>
            <Col xs={12} className="mb-3">
              <Label>Apoderado:</Label>
              <Input
                required
                name="permission"
                placeholder={"Ingresar nombre"}
                value={form.permission}
                className="form-control"
                onChange={ev => {
                  changeHandler(ev, form, setForm)
                }}
              />
            </Col>
            <Col xs={12} className="mb-3">
              <Label>Documento del apoderado:</Label>
              <Input
                required
                name="PermissionDocument"
                placeholder={"Ingresar documento"}
                type="number"
                className="form-control"
                onChange={ev => {
                  changeHandler(ev, form, setForm)
                }}
              />
            </Col>
            <Col xs={12} className="mb-3">
              <Label>Fecha de asamblea:</Label>
              <Input
                required
                name="date"
                type="date"
                value={form.date}
                className="form-control"
                onChange={ev => { changeHandler(ev, form, setForm)}}
              />
            </Col>
            <Col xs={12}>
              <Button
                className="btn btn-success w-100 p-2"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => { setShowInvoice(!showInvoice) }}
              >
                Generar permiso
              </Button>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  )
}
UvPermissions.propTypes = {
  active: PropTypes.bool,
  toggle: PropTypes.func,
  user: PropTypes.object,
  session: PropTypes.object,
}
