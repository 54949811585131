import React, {useContext, useEffect, useState} from "react";
import MetaTags from 'react-meta-tags';
import { UvCreate } from "../../components/Users/uv-create"
import { UvUpdate } from "../../components/Users/uv-update"
import { GuestCreate } from "components/Users/guest-create";
import { GuestUpdate } from "components/Users/guest-update";
import { GuestDelete } from "components/Users/guest-delete";
import { PhoneViews } from "components/Users/uv-phone";
import { Delete } from "components/Users/delete";
import { MainContext } from "../../contexts/Main.context";
import { TableWrapper } from "../../components/Helpers/TableWrapper";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { UvPermissions } from "components/Users/uv-permissions";

const UvProfile = () => {
	
	const {backend, formatter} = useContext(MainContext);
	const resp = backend.getSession();
	const [element, setElement ] = useState({});
	const [user, setUserData] = useState([]);
	const [represent, setUsers] = useState([]);
	const [session, setSession] = useState([]);
	const [_phones, setPhones] = useState([]);
 	const [benef, setBenef] = useState([]);
	const [guest, setGuest] = useState([]);
	const [modals, setModals] = useState({permissions: false, create: false, update: false, delete: false, phones: false, guestPhones: false, guestUpdate: false, });

	useEffect(() => {
		pullLot();
		pullData()
	},[]);

	const where = {
		isRepresentative: resp.data.lot.zone,
    type: "OWNER",
    active: 1,
  }

  const pullData = async () => {
    const { data } = await backend.getUsers({ limit: -1, where })
    if (data) {
      const dataCopy = []
      data.filter(x => {
        if (!x.lots[0]) {
          console.log("no zone-element")
        } else {
          dataCopy.push({
            id: x.id,
            value: x.id,
            label: x.name + " " + x.surname,
            zone: x.lots[0].zone,
          })
        }
      })
      setUsers(dataCopy)
    }
  }

	const pullLot = async () => {
		try {
			const data = await  backend.getUserOfLands(resp.data.lot.id);
			const _benef = data.data.filter(el => el.active == true && el.type == "BENEFICIARY"); 
			const _guest = data.data.filter(el => el.active == true && el.type !== "BENEFICIARY"); 
			setUserData(data.data[0]);
			setSession(resp);
			setBenef(_benef);
			setGuest(_guest);
		}
		catch (error) {
			console.error(`Could not get products: ${error}`);
		}
  };

	const toggleModal = (modal) => {
		const mdl = {...modals};
		mdl[modal] = !mdl[modal];
		setModals(mdl);
	};
    
	const columns = [
		{name: 'Parentesco'},
		{name: 'Nombre'},
		{name: 'Documento'},
		{name: 'Correo'},
	];

	const customColumns = [
		{name: 'Nombre'},
		{name: 'Tipo de documento'},
		{name: 'Documento'},
		{name: 'Fecha de ingreso'},
		{name: 'Fecha de salida'},
		{name: 'Tipo'},
		{name: ''},
	];

	return(
		<React.Fragment>
			<MetaTags> <title> Usuario | Condominio la raya </title> </MetaTags>
			{modals.create 			? (<UvCreate 				active={modals.create} 											toggle={toggleModal} />) : (<></>)}
			{modals.delete 			? (<Delete 					active={modals.delete} 			user={element} 	toggle={toggleModal} />) : (<></>)}
			{modals.guestUpdate ? (<GuestUpdate 		active={modals.guestUpdate} user={element} 	toggle={toggleModal} />) : (<></>)}
			{modals.guestCreate ? (<GuestCreate 		active={modals.guestCreate} user={element} 	toggle={toggleModal} />) : (<></>)}
			{modals.update 			? (<UvUpdate 				active={modals.update} 			user={element} 	toggle={toggleModal} />) : (<></>)}
			{modals.phones 			? (<PhoneViews 			active={modals.phones} 			user={element} 	toggle={toggleModal} phones={_phones}/>) : (<></>)}
			{modals.permissions ? (<UvPermissions 	active={modals.permissions} user={user}			toggle={toggleModal} session={session}/>) : (<></>)}

      <div className="page-content">
        <Container fluid>  
          <Row className="align-items-center">
            <Col xs={12} md={6} className="d-flex justify-content-start mb-4 "> <h4> Datos del propietario </h4> </Col>
            <Col xs={12} md={6}>
              <Row className="d-flex justify-content-end">
                <Col xs={12} md={4} className="d-flex justify-content-end"></Col>
                <Col xs={12} md={4} className="mb-4"></Col>
              </Row>
            </Col>
          </Row>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<div className="">
										<Row>
											<Col xxl={2} sm={3} xs={12}>
												<div className="h-100 d-flex justify-content-center align-items-center">
													<div className="avatar-xl">
														<h1 className="mb-0 avatar-title rounded-circle">{user.name ? user.name.charAt(0) : 'A'}</h1>
													</div>
												</div>
											</Col>
											<Col xxl={8} sm={6} xs={12}>
												<div className="my-4">
													<h6 className="d-inline-block me-2">Nombres:</h6>
													<span>{user.name ?? <mark>Datos sin completar</mark>}</span> <br />

													<h6 className="d-inline-block me-2">Apellidos:</h6>
													<span>{user.surname ?? <mark>Datos sin completar</mark>}</span> <br />

													<h6 className="d-inline-block me-2">E-mail:</h6>
													<span>{user.email ?? <mark>Datos sin completar</mark>}</span> <br />

													<h6 className="d-inline-block me-2">E-mail Alternativo:</h6>
													<span>{user.altEmail ?? <mark>Datos sin completar</mark>}</span> <br />

													<h6 className="d-inline-block me-2">Dirección:</h6>
													<span>{user.address ?? <mark>Datos sin completar</mark>}</span> <br />

													<h6 className="d-inline-block me-2">Documento:</h6>
													<span>{user.document ?? <mark>Datos sin completar</mark>}</span> <br />

													<h6 className="d-inline-block me-2">Representante de zona:</h6>

													<span>{represent[0] ? represent[0].label : <mark>Datos sin completar</mark>}</span> <br />

												</div>
											</Col>
											<Col xxl={2} sm={3} xs={12}>
												<div className="float-end d-flex flex-column justify-content-end h-100">
													<Button 
														type="button" 
														color="info"
														className="font-size-15 w-100 mb-2"
														onClick={() => {setElement(user.id); setPhones(user.phones); toggleModal('phones')}}>
															Telefonos
													</Button>
													<Button 
														type="button" 
														color="info"
														className="font-size-15 w-100"
														onClick={() => {setElement(user.id); toggleModal('update')}}>
															Detalles
													</Button>
												</div>
											</Col>
										</Row>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row className="align-items-center">
            <Col xs={12} md={6} className="d-flex justify-content-start mb-4 mt-5 mb-4 "> <h4> Beneficiarios </h4> </Col>
            <Col xs={12} md={6}>
              <Row className="d-flex justify-content-end">
                <Col xs={12} md={4} className="d-flex justify-content-end"></Col>
                <Col xs={12} md={4} className="mb-4">
									<Button 
										type="button" 
										color="success"
										className="w-100"
										onClick={() => {toggleModal('create')}}>
											Crear beneficiario
									</Button>
                </Col>
              </Row>
            </Col>
          </Row>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<div className="table-responsive  text-nowrap">
										<TableWrapper headers={columns}>
											{ benef ? benef.map((el, key) => 
											<tr key={key}>
												<td  className="text-nowrap">{el.relationship ?? <mark>Datos sin completar</mark>}</td>
												<td  className="text-nowrap">{el.completeName ?? <mark>Datos sin completar</mark>}</td>
												<td  className="text-nowrap">{el.document ?? <mark>Datos sin completar</mark>}</td>
												<td  className="text-nowrap">{el.email ?? <mark>Datos sin completar</mark>}</td>
												<td className="text-nowrap font-size-14">
													<Row className="row-buttons-container flex-nowrap">
														<Col xs={12} md={4} className="my-2">
															<Button 
																type="button" 
																color="info"
																className="font-size-15 h-100 w-100"
																onClick={() => {setElement(el.id); setPhones(el.phones); toggleModal('phones')}}>
																	Telefonos
															</Button>
														</Col>
														<Col xs={12} md={4} className="my-2">
															<Button 
																type="button" 
																color={'info'} 
																outline={true} 
																data-toggle="modal" 
																data-target="#myModal"
																className="font-size-15 h-100 w-100"
																onClick={() => {setElement(el.id); toggleModal('update')}}>
																	Detalles
															</Button>
														</Col>
														<Col xs={12} md={4} className="my-2">
															<Button 
																type="button" 
																color="danger" 
																outline={true} 
																data-toggle="modal"
																className="font-size-15 h-100 w-100"
																onClick={() => {setElement(el.id); toggleModal('delete')}}>
																	Eliminar
															</Button>
														</Col>
													</Row>
												</td>
											</tr>) : <></>}
										</TableWrapper>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row className="align-items-center">
					<Col xs={12} md={6} className="d-flex justify-content-start mt-5 mb-4  "> <h4> Invitados </h4> </Col>
            <Col xs={12} md={6}>
              <Row className="d-flex justify-content-end">
                <Col xs={12} md={4} className="d-flex justify-content-end"></Col>
                <Col xs={12} md={4} className="mb-4">
								<Button 
										type="button" 
										color="success"
										className="w-100"
										onClick={() => {toggleModal('guestCreate')}}>
											Crear invitado
									</Button>
                </Col>
              </Row>
            </Col>
          </Row>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<div className="table-responsive  text-nowrap">
										<TableWrapper headers={customColumns}>
											{ guest ? guest.slice(1).map((el, key) => 
											<tr key={key}>
												<td  className="text-nowrap">{el.name +' '+ el.surname}</td>
												<td  className="text-nowrap">{el.typeDocument ?? <mark>Datos sin completar</mark>}</td>
												<td  className="text-nowrap">{formatter.typeEnum(el.document) ?? <mark>Datos sin completar</mark>}</td>
												<td  className="text-nowrap">{formatter.date(el.visitDate) ?? <mark>Datos sin completar</mark>}</td>
												<td  className="text-nowrap">{formatter.date(el.exitDate) ?? <mark>Datos sin completar</mark>}</td>
												<td  className="text-nowrap">{formatter.typeEnum(el.type) ?? <mark>Datos sin completar</mark>}</td>
												<td className="text-nowrap font-size-14">
													<Row className="row-buttons-container flex-nowrap">
														<Col xs={12} md={4} className="my-2">
															<Button 
																type="button" 
																color="info"
																className="font-size-15 h-100 w-100"
																onClick={() => {setElement(el.id); setPhones(el.phones); toggleModal('phones')}}>
																	Telefonos
															</Button>
														</Col>
														<Col xs={12} md={4} className="my-2">
															<Button 
																type="button" 
																color={'info'} 
																outline={true} 
																data-toggle="modal" 
																data-target="#myModal"
																className="font-size-15 h-100 w-100"
																onClick={() => {setElement(el.id); toggleModal('guestUpdate')}}>
																	Detalles
															</Button>
														</Col>
														<Col xs={12} md={4} className="my-2">
															<Button 
																type="button" 
																color="danger" 
																outline={true} 
																data-toggle="modal"
																className="font-size-15 h-100 w-100"
																onClick={() => {setElement(el.id); toggleModal('delete')}}>
																	Eliminar
															</Button>
														</Col>
													</Row>
												</td>
											</tr>) : <><div></div></>}
										</TableWrapper>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row className="align-items-center">
						<Col xs={12} md={12} className="d-flex justify-content-start mt-5 mb-4  "> <h4> Crear documento para permiso de representación </h4> </Col>
            <Col xs={12} md={6}>
              <Row className="d-flex justify-content-start">
                <Col xs={12} md={4} className="mb-4">
									<Button 
										type="button" 
										color="success"
										className="w-100"
										onClick={() => {toggleModal('permissions')}}>
											Crear Permiso
									</Button>
                </Col>
              </Row>
            </Col>
          </Row>
				</Container>  
			</div>
		</React.Fragment>
	);
};
export default UvProfile;