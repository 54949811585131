import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, CardTitle, Col } from "reactstrap"

export const RepresentativeCard = ({value, name, zone, user, toggle}) => {
	return (
		<Col xxl={3} md={6} xs={12}>
			<Card className="hover" color="">
				<CardBody>
					<CardTitle className="mb-4"> 
						Zona: {value}
					</CardTitle>
					<div>
						<h5 className="mb-4">
							{name}
						</h5>
					<Button
						type="button"
						color="info"
						className="w-100"
						outlines="false"
						onClick={() => {
							toggle('update');
							zone(value);
							user(name)
						}}>
							Actualizar
					</Button>
					</div>
				</CardBody>
			</Card>
		</Col>
	);
}

RepresentativeCard.propTypes = {
	value: PropTypes.string,
	name: PropTypes.string,
	zone: PropTypes.func,
	user: PropTypes.func,
	toggle: PropTypes.func
}