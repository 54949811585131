import React, { useRef, useContext } from "react"
import { logo } from "../../assets"
import PropTypes from "prop-types"
import "./Invoice.scss"

import { PDFExport, savePDF } from "@progress/kendo-react-pdf"
import { MainContext } from "../../contexts/Main.context"
import { Button, Row, Col } from "reactstrap"

export const Invoice = ({ toggle, info }) => {
  const { formatter } = useContext(MainContext)
  const container = useRef(null)
  const pdfExportComponent = useRef(null)

  const exportPDF = () => {
    let element = container.current || document.body
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `PERMISO LOTE ${info.lote}`,
    })
  }

  return (
    <>
      <div className="buttons">
        <div className="d-flex justify-content-center">
          <Row className="">
            <Col>
              <Button onClick={exportPDF} color="primary">
                Descargar
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  toggle()
                }}
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <PDFExport
        ref={pdfExportComponent}
        paperSize="auto"
        fileName={`Permido creado en ${new Date().getFullYear()}`}
        author="Condominio la raya y sashavaca"
      >
        <div className="invoice py-4" ref={container}>
          <div>
            <Row className="header-row">
              <Col sm={3}>
                <img
                  src={logo}
                  alt="Logo del condominio"
                  className="invoice-logo"
                />
              </Col>
              <Col sm={9}>
                <p className="small font-weight-semibold">
                  ASOCIACION ECOLOGICA LA RAYA Y LA SASHAVACA
                </p>
                <p className="m-0 small">RUC 20512844708</p>
              </Col>
            </Row>
            <Row>
              <Col className="my-5" xs={12}>
                <span className="mt-5">
                  {`Yo, ${info.completeName} con DNI ${info.document} de lote ${info.lote} en mis facultades doy poder a ${info.permission} con DNI ${info.PermissionDocument}  para que me represente en la asamblea de fecha ${formatter.date(info.date)}`}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p className="mt-5 text-end">
                {formatter.date(new Date())}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </PDFExport>
    </>
  )
}
Invoice.propTypes = {
  toggle: PropTypes.func,
  info: PropTypes.object,
}
