

import React, {useContext, useEffect, useState} from 'react';
import PropTypes, { bool } from 'prop-types';
import Select from "react-select";
import {MainContext} from '../../contexts/Main.context'
import {Input, Label, Modal, Row, CardTitle, Button, Col} from 'reactstrap';
import {EnumService} from "../../services/enum.service";

export const UvUpdate = ({active, toggle, user}) => {    
    const defaultForm = {
        typeDocument:'',
        completeName:'',
        relationship:'',
        document:'',
        comments:'',
        surname:'',
        email:'',
        name:'',
    }

    const {backend, showLoader, changeHandler, toastAlert} = useContext(MainContext);
    const [form, setForm] = useState(defaultForm);

    const options = (withAll = true, array) => {
        const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
        array.map((el) => _options.push({id:el.id, value: el.value,  label: el.label ? el.label : el.slug}));
        return _options;
    };

    //get
    useEffect(() => {
            pullData();
        },[]
    );
    const pullData = async () => {
        const resp = await backend.getUser(user);
        if(resp && resp.status){
			const vals = {
                "typeDocument": resp.data.typeDocument,
                "completeName": resp.data.completeName,
                "relationship": resp.data.relationship,
                "document": resp.data.document,
                "comments": resp.data.comments,
                "surname": resp.data.surname,
                "email": resp.data.email,
                "name": resp.data.name,
			};
			setForm(vals);
		}
    };

    const submitHandler = () => {
        const _form = {...form};
        _form.completeName = form.name +' '+ form.surname;
        setForm(_form);
        showLoader(true);
        uploadData(_form)
    };
    const uploadData = async (_form) => {
        const resp = await backend.updateUser(user, _form ?? form);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'Creado correctamente correctamente', color: 'primary'});
            toggle('update');
            location.reload();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
    };

    return (
        <Modal 
            size="l" 
            centered
            isOpen={active}
            toggle={() => { toggle('update'); }}>
            <Row>
                <CardTitle className="mt-4 mx-3">
                    Actualizar beneficiario
                </CardTitle>
                <Button 
                    onClick={() => {toggle('update');}} 
                    type="button" 
                    className="close"
                    data-dismiss="modal" 
                    aria-label="Close">
                </Button>
            </Row>
            <form>
                <Row className="modal-body">
                    <Col xs={12} sm={6} className="mb-3">
                        <Label>Parentesco:</Label>
                        <Input 
                            required
                            name="relationship"
                            value={form.relationship ?? "Sin especificar"}
                            className="form-control" 
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                    <Col xs={12} sm={6} className="mb-3">
                        <Label>Nombre:</Label>
                        <Input 
                            required
                            name="name"
                            value={form.name}
                            className="form-control" 
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                    <Col xs={12} sm={6} className="mb-3">
                        <Label>Apellido:</Label>
                        <Input 
                            required
                            name="surname"
                            value={form.surname}
                            className="form-control" 
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                    <Col xs={12} sm={6} className="mb-3">
                        <Label>Correo:</Label>
                        <Input 
                            required
                            name="email"
                            type='email'
                            value={form.email}
                            className="form-control" 
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                    <Col xs={12} sm={6} className="mb-3">
                        <Label>Tipo de documento:</Label>
                        <Select 
                            required
                            isMulti={false}
                            name="typeDocument"
                            placeholder={form.typeDocument}
                            options={options(false, EnumService.typeDocument)} 
                            onChange={(selected)=> {
                                const _form = {...form};
                                _form.typeDocument = selected.value,
                                setForm(_form);}}
                        />
                    </Col>
                    <Col xs={12} sm={6} className="mb-3">
                        <Label>Documento:</Label>
                        <Input 
                            required
                            name="document"
                            type='number'
                            className="form-control" 
                            value={form.document}
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                    <Col xs={12} className="mb-3">
                        <Label>Comentarios:</Label>
                        <Input 
                            type="textarea"
                            name="comments"
                            value={form.comments}
                            className="form-control" 
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                    <Col xs={12}>
                        <Button
                            className="btn btn-success w-100 p-2"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={()=> submitHandler()}>
                                Guardar cambios
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
UvUpdate.propTypes = {
    active: PropTypes.bool,
    toggle: PropTypes.func,
    user: PropTypes.any
};