import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { MainContext } from "contexts/Main.context";
import { Button, Card, CardBody } from "reactstrap"
import { TableWrapper } from "components/Helpers/TableWrapper";
import { Details } from "./details";

export const Lastest = ({data, admin}) => {
  
  const rows = data ? data.slice(0, 10) : [];
  const { formatter } = useContext(MainContext);
	const [payment, setPayment] = useState({});

	const [modals, setModals] = useState({details: false});          
	const toggleModal = (modal) => {
		const mdl = {...modals};
		mdl[modal] = !mdl[modal];
		setModals(mdl);
	};

  return (
    <React.Fragment>
			{modals.details ? (<Details active={modals.details} pay={payment} toggle={toggleModal} admin={admin} />) : (<></>)}
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Ultimos Pagos</div>
          <div className="table-responsive text-nowrap">
            <TableWrapper headers={[
                {name: '#'},
                {name: 'Fecha'},
                {name: 'Monto'},
                {name: 'Tipo'},
                {name: 'Estatus'},
            ]}>
              {rows ? rows.map((el =>
                <tr key={el.id} style={{verticalAlign: 'middle'}}>
									<td className="text-nowrap font-size-14">{el.id}</td>
									<td className="text-nowrap font-size-14">{formatter.date(el.createdAt)}</td>
									<td className="text-nowrap font-size-14">{el.total}</td>
									<td className="text-nowrap font-size-14">{formatter.typeEnum(el.type)}</td>
									<td className="text-nowrap font-size-14">{formatter.typeEnum(el.status)}</td>
									<td/>
									<td className="text-nowrap font-size-14">
										<Button 
											type="button" 
											color="success" 
											outline={el.status === 'PAID'} 
											data-toggle="modal"
											className="font-size-15 h-100 w-100"
											onClick={() => {setPayment(el.id); toggleModal('details')}}>
												{el.status === 'PAID' ? 'Ver detalles' : 'Revisar Pago'}
										</Button>
									</td>
                </tr>)) : (<></>)}
            </TableWrapper>
          </div>
          <Link to={admin ? "/payments" : "/uv-payments"}>
            <Button
              type="button"
              color="success"
              className="btn btn success"
            >
              Ver todos
            </Button>
          </Link>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
Lastest.propTypes = {
	data:PropTypes.array,
  admin:PropTypes.any,
};
