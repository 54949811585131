import React, {useContext, useEffect} from "react"
import {withRouter} from "react-router-dom"
import {MainContext} from "../../contexts/Main.context";

const Logout = props => {
    const {backend} = useContext(MainContext);
    useEffect(() => {
        backend.logOut();
    }, [])
    return <></>
}

export default withRouter(Logout)
