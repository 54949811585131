import React, { useContext, useState, useEffect } from "react"
import { MainContext } from "../../contexts/Main.context"
import Select from "react-select"
import { TableWrapper } from "components/Helpers/TableWrapper"
import { CustomPagination } from "components/Common/CustomPagination"
import { Button, Card, CardBody, Col, Container, Row, Label } from "reactstrap"
import { Create } from "components/Comments/create"

export const Comments = () => {
  const defaultFilter = {
    landId: "",
  }

  const { backend, showLoader, formatter } = useContext(MainContext)
  const [modals, setModals] = useState({
    create: false,
    update: false,
    delete: false,
  })
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [rows, setRows] = useState([])
  const [lot, setLot] = useState(defaultFilter)
  const [filter, setFilter] = useState({})
  const [names, setNames] = useState([{}])

  //get
  useEffect(() => {
    pullData()
    pullId()
  }, [page])

  const pullData = async () => {
    showLoader(true)
    const { data, meta } = await backend.getComments({ page, where: filter })
    if (data) {
      setTotalPages(meta.lastPage)
      setRows(data)
    }
    showLoader(false)
  }
  const pullId = async () => {
    showLoader(true)
    const { data } = await backend.getLots({
      page,
      limit: 999,
      attributes: ["id", "slug"],
    })
    if (data) {
      setNames(data)
    }
    showLoader(false)
  }

  const options = array => {
    const options = [{ id: "All", value: "", label: "todos" }]
    array.map(el =>
      options.push({
        id: el.id,
        value: el.id,
        label: el.slug ? el.slug : el.id,
      })
    )
    return options
  }

  const toggleModal = modal => {
    const mdl = { ...modals }
    mdl[modal] = !mdl[modal]
    setModals(mdl)
  }

  const columns = [
    { name: "#" },
    { name: "Lote" },
    { name: "Comentario" },
    { name: "Fecha" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        {modals.create ? (
          <Create active={modals.create} toggle={toggleModal} />
        ) : (
          <></>
        )}
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="d-flex justify-content-start mb-4">
              <h4> Comentarios </h4>
            </Col>
            <Col xs={12} md={6}>
              <Row className="d-flex justify-content-end">
                <Col
                  xs={12}
                  md={4}
                  className="d-flex justify-content-end mb-4"
                />
                <Col xs={12} md={4} className="mb-4">
                  <Button
                    type="button"
                    color="success"
                    className="w-100"
                    onClick={() => {
                      toggleModal("create")
                    }}
                  >
                    Crear
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-4 d-flex align-items-center">
            <Col xs={12} sm={4} md={2} className="mt-3 mb-0">
              <Label className=""> Lotes: </Label>
              <Select
                isMulti={true}
                options={options(names)}
                placeholder="Ingresar..."
                onChange={selected => {
                  const result = selected.filter(el => el.id == "All")
                  const _filter = { ...filter }
                  result[0]
                    ? (_filter.landId = "")
                    : (_filter.landId = selected.map(el => el.value).join())
                  setFilter(_filter)
                }}
              />
            </Col>
            <Col xs={12} md={2}>
              {" "}
              <br /> <br />
              <Button
                type="button"
                color="primary"
                className="w-100"
                onClick={() => pullData()}
              >
                Filtrar
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Row>
                <Card>
                  <CardBody>
                    <div className="table-responsive text-nowrap">
                      <TableWrapper headers={columns}>
                        {rows ? (
                          rows.map(el => (
                            <tr key={el.id}>
                              <td className="text-nowrap">{el.id}</td>
                              <td className="text-nowrap">{el.slug}</td>
                              <td className="text-wrap">{el.content}</td>
                              <td className="text-nowrap">
                                {formatter.date(el.createdAt)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </TableWrapper>
                    </div>
                    <Row className="mt-4">
                      <CustomPagination
                        currentPage={page}
                        setPage={setPage}
                        totalPages={totalPages}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
