import PropTypes from "prop-types";
import Select from "react-select";
import { MainContext } from "contexts/Main.context";
import { EnumService } from "services/enum.service";
import React, {useContext, useState, useEffect} from "react";
import {Input, Label, Modal, Row, Button, Col, CardTitle} from 'reactstrap';


export const StatusUpdate = ({active, toggle, transaction}) => {

    const defaultForm = {
        amount: 0,

    }
	const {backend, showLoader, toastAlert, changeHandler} = useContext(MainContext);
	const [form, setForm] = useState(defaultForm);
    const [status, setStatus] = useState([]);

    useEffect(() => {
        pullStatus()
    }, [])

	//update
	const submitHandler = () => {
		showLoader(true);
		uploadData()
	};
	const uploadData = async () => {
		const resp = await backend.updateStatus(form);
		showLoader(false);
		if (resp.status) {
			toastAlert({body: 'Actualizado correctamente', color: 'primary'});
			toggle('status');
			location.reload();
		} else {
			toastAlert({body: 'Error inesperado', color: 'warning'});
		}
	};

    const pullStatus = async () => {
        const {data} = await backend.getStatus();
        if(data){
            setStatus(data);
        }
    };

	return(
        <Modal 
            size="l" 
            centered
            isOpen={active}
            toggle={() => { toggle('status'); }}>
            <Row>
                <CardTitle className="mt-4 mx-3">
                    Actualizar estado de cuenta
                </CardTitle>
                <Button 
                    onClick={() => {toggle('status');}} 
                    type="button" 
                    className="close"
                    data-dismiss="modal" 
                    aria-label="Close">
                </Button>
            </Row>
            <Row className="modal-body">
                <Col xs={12} className="mb-3">
                    <Label>Monto:</Label>
                    <Input 
                        name="amount"
                        type='number'
                        value={form.amount}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12}>
                    <Button
                        className="btn btn-success w-100 p-2"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={()=> submitHandler()}>
                        Guardar cambios
                    </Button>
                </Col>
            </Row>
        </Modal>
	);
};
StatusUpdate.propTypes = {
	active: PropTypes.bool,
	toggle: PropTypes.func,
	transaction: PropTypes.any
}