import React from 'react';
import {Table} from 'reactstrap';
import PropTypes from "prop-types";

/*
* header: {name*, slug, className}
* */

export const TableWrapper = ({children, headers = []}) => {
    return (
        <>
            <Table bordered={false} borderless={false} hover={false} striped={true} size="sm" responsive={true}>
                <thead>
                    <tr>
                        {headers.map((el, id) => (<th className={el.className ?? ''} key={id}>{el.name}</th>))}
                    </tr>
                </thead>
                <tbody>
                {/*<tr><th scope="row">1</th></tr>*/}
                {children}
                </tbody>
            </Table>
        </>
    );
}

TableWrapper.propTypes = {
    children: PropTypes.any,
    headers: PropTypes.array
}