import React, { useEffect, useContext, useState } from "react"
import CardContact from "./card-contact"
import { Container, Row, Col } from "reactstrap"
import { MainContext } from "contexts/Main.context"

const UvUsers = () => {

  const defaultFilter = {
    type: 'OWNER',
  };

  const { backend, formatter } = useContext(MainContext);
  const [filter, setFilter] = useState(defaultFilter);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(
    () => {pullData();},
    [page]
  );

  const pullData = async () => {
      const {data} = await backend.getUsers({limit: 9999, page, where: filter});
      if(data){
        setRows(data);
      }
  };  

  return( 
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Lista de vecinos </h4> </Col>
            <Col xs={12} md={6}>
              <Row className="d-flex justify-content-end">
                <Col xs={12} md={4} className="d-flex justify-content-end"></Col>
                <Col xs={12} md={4} className="">
                </Col>
              </Row>
            </Col>
          </Row>          
          <Row>
            {rows ? rows.map(user => (
              <CardContact user={user} key={ user.id } />
            )): <></> }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default UvUsers