import PropTypes from "prop-types"
import Select from "react-select"
import { MainContext } from "contexts/Main.context"
import React, { useContext, useState, useEffect } from "react"
import { Label, Modal, Row, Button, Col, CardTitle } from "reactstrap"

export const Update = ({ active, toggle, zone, user }) => {
  const attributes = ["surname", "name", "id"]

  const where = {
    type: "OWNER",
    active: 1,
  }

  const defaultForm = {
    isRepresentative: "",
  }

  const { backend, showLoader, toastAlert } = useContext(MainContext)
  const [form, setForm] = useState(defaultForm)
  const [users, setUsers] = useState([])
  const [id, setId] = useState(user.id)

  useEffect(() => {
    pullUsers()
  }, [])

  const pullUsers = async () => {
    const { data } = await backend.getUsers({ limit: 999, attributes, where })
    if (data) {
      const dataCopy = []
      data.filter(x => {
        if (!x.lots[0]) {
          console.log("no zone-element")
        } else {
          dataCopy.push({
            id: x.id,
            value: x.id,
            label: x.name + " " + x.surname,
            zone: x.lots[0].zone,
          })
        }
      })
      setUsers(dataCopy.filter(el => el.zone == zone))
    }
  }

  //update
  const submitHandler = () => {
    showLoader(true)
    uploadData()
  }
  const uploadData = async () => {
    const resp = await backend.updateUser(id, form)
    showLoader(false)
    if (resp.status) {
      toastAlert({ body: "Actualizado correctamente", color: "primary" })
      toggle("update")
      location.reload()
    } else {
      toastAlert({ body: "Error inesperado", color: "warning" })
    }
  }

  return (
    <Modal
      size="l"
      centered
      isOpen={active}
      toggle={() => {
        toggle("update")
      }}
    >
      <Row>
        <CardTitle className="mt-4 mx-3">Actualizar Representante</CardTitle>
        <Button
          onClick={() => {
            toggle("update")
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        ></Button>
      </Row>
      <Row className="modal-body">
        <Col xs={12} className="mb-3">
          <Label>Representante</Label>
          <Select
            isMulti={false}
            placeholder={user}
            options={users}
            onChange={selected => {
              const _form = { ...form }
              ;(_form.isRepresentative = selected.zone), setForm(_form)
              setId(selected.id)
            }}
          />
        </Col>
        <Col xs={12}>
          <Button
            className="btn btn-success w-100 p-2"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => submitHandler()}
          >
            Guardar cambios
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}
Update.propTypes = {
  active: PropTypes.bool,
  toggle: PropTypes.func,
  zone: PropTypes.any,
  user: PropTypes.any,
}
