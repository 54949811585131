import React, { useState, useContext, useEffect } from "react"
import { placeholder } from "assets";
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom"
import { MainContext } from "contexts/Main.context";
import { Container, Row, Col, Card, CardBody, Form, Input, FormGroup, Label, Button } from "reactstrap"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditPost = () => {

  const defaultForm = {
    "name":"",
    "thumbnailUri": "",
    "content": ""
	};

  let { id } = useParams();
  const {backend, showLoader, formatter, changeHandler, toastAlert} = useContext(MainContext);
  const [form, setForm] = useState(defaultForm);
  //Get
  useEffect(() => {
      pullData();
    },[]
  );
  const pullData = async () => {
    const {data} = await backend.getNewsletter(id);
    if(data){
      const _form = {
        "name":data.name,
        "thumbnailUri": data.thumbnailUri,
        "content": data.content
      }
      setForm(_form);
    };
  }

  //Update
	const submitHandler = () => {
		showLoader(true);
		uploadData()
	};
	const uploadData = async () => {
		const resp = await backend.updateNew(id, form);
		showLoader(false);
		if (resp.status) {
			toastAlert({body: 'Actualizado correctamente', color: 'primary'});
      location.reload();
		} else {
			toastAlert({body: 'Error inesperado', color: 'warning'});
		}
	}

  const render = () => {
    return (
			<div className="content" dangerouslySetInnerHTML={{__html: form.content}}></div>
    );
	};
  return (
    <React.Fragment>
      <MetaTags> <title> Editar anuncio | Condominio la raya </title> </MetaTags>

      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12} className="d-flex justify-content-start mb-4">
              <Link to="/newsletter">
                <h3 className="text-primary">
                  <i className="bx bx-arrow-back" />{' '} Volver
                </h3>
              </Link>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Edición </h4> </Col>
            <Col xs={12} md={6}>
              <Row className="d-flex justify-content-end">
                <Col xs={12} md={4} className=""></Col>
                <Col xs={12} md={4} className="mb-4">
                  <Button
                    type="submit"
                    color="success"
                    className="w-100"
                    onClick={()=> submitHandler()}>
                    Actualizar anuncio
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form className="outer-repeater">
                    <Row className="mb-4">
                      <FormGroup>
                        <Label className=""> Título </Label>
                        <Input
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Inserte Nuevo título..."
                          value={form.name}
                          onChange={(ev)=>{changeHandler(ev,form, setForm)}}/>
                      </FormGroup>
                    </Row>
                    <Row className="mb-4">
                      <FormGroup>
                        <Label className=""> Descripción </Label>
                        <ReactQuill theme="snow" value={form.content} onChange={(ev) => {
                          const _form = {...form};
                          _form.content = ev;
                          setForm(_form);
                        }}/>
                      </FormGroup>
                    </Row>
                    <Row className="">
                      <Col xs={12} md={4}>
                        <FormGroup className="">
                          <Label className=""> Añadir una imagen </Label>
                          <Input
                            type="file"
                            name="thumbnailUri"
                            className="form-control"
                            placeholder="Inserte Nueva Imagen..."
                            onChange={(ev)=>{changeHandler(ev,form, setForm)}}/>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={4}>
                        <FormGroup className="">
                          <Label className=""></Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Previsualización: </h4> </Col>
                    <Col xs={12} md={6}>
                    </Col>
                  </Row>
                  <Row className="">
										<div className="row justify-content-center">
											<div className="col-xl-8">
												<div>
													<div className="text-center mt-4">
															<h1>{form.name}</h1>
													</div>
												 	<hr />
													<div className="text-center">
														<Row>
															<Col sm={12}>
																<div className="mt-4 mt-sm-0">
																	<p className="text-muted mb-2">Fecha:</p>
																	<h5 className="font-size-15">{formatter.date(Date())}</h5>
																</div>
															</Col>
														</Row>
													</div>
													<hr />
													<div className="my-5">
                            <img src={form.thumbnailUri != '' ? form.thumbnailUri : placeholder} alt="" className="img-thumbnail mx-auto d-block" />
													</div>
													<hr />
													<div className="mt-4">
														<div id="text-div" className="text-muted font-size-20" style={{textAlign: 'justify'}}>
															{render()}
														</div>
														<hr />
													</div>
												</div>
											</div>
										</div>
									</Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default EditPost;

