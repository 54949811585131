import PropTypes from "prop-types"
import React, {useContext, useEffect, useRef} from "react"

// // Import Scrollbar
import SimpleBar from "simplebar-react"

// // MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

// //i18n
import { withTranslation } from "react-i18next"
import {MainContext} from "../../contexts/Main.context";

const SidebarContent = props => {
  const {isAgent, userType, backend} = useContext(MainContext);
  const ref = useRef();
  // // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  const getMenuOptions = (userType) => {

     switch (userType){
      case 'SECURITY':
        return <>
          <li>
            <Link to="/users" className="py-4">
              <i className="bx bx-id-card"/>
              <span>Usuarios</span>
            </Link>
          </li>
          <li>
            <Link to="/lots" className="py-4">
              <i className="bx bx-landscape"/>
              <span>Lotes</span>
            </Link>
          </li>
          <li>
            <Link to="/comments" className="py-4">
              <span className="badge rounded-pill bg-success float-end"/>
              <i className="bx bxs-detail" />
              <span>Comentarios</span>
            </Link>
          </li>
        </>;
      case 'TREASURER':
        return <>
          <li>
            <Link to="/payments" className="py-4">
              <i className="bx bx-money" />
              <span>Pagos</span>
            </Link>
          </li>
          <li>
            <Link to="/transactions" className="py-4">
              <i className="bx bx-briefcase-alt-2" />
              <span>Transacciones</span>
            </Link>
          </li>
          <li>
            <Link to="/uv-files" className="py-4">
              <i className="bx bx-file-blank" />
              <span>Documentos</span>
            </Link>
          </li>
        </>;
       case "FIELD_MANAGER":
         return <>
           <li>
             <Link to="/newsletter" className="py-4">
               <i className="bx bx-news" />
               <span>Anuncios</span>
             </Link>
          </li>
          <li>
            <Link to="/users" className="py-4">
              <i className="bx bx-id-card"/>
              <span>Usuarios</span>
            </Link>
          </li>
          <li>
            <Link to="/representative" className="py-4">
              <i className="bx bx-id-card"/>
              <span>Representantes</span>
            </Link>
          </li>
          <li>
            <Link to="/lots" className="py-4">
              <i className="bx bx-landscape"/>
              <span>Lotes</span>
            </Link>
          </li>
          <li>
            <Link to="/payments" className="py-4">
              <i className="bx bx-money"/>
              <span>Pagos</span>
            </Link>
          </li>
        </>;
      case 'OWNER':
        return <>
          <li>
            <Link to="/uv-dashboard" className="py-4">
              <i className="bx bx-home-circle"/>
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/uv-payments" className="py-4">
              <i className="bx bx-money"/>
              <span>Pagos</span>
            </Link>
          </li>
          <li>
            <Link to="/uv-transactions" className="py-4">
              <i className="bx bx-briefcase-alt-2"/>
              <span>Transacciones</span>
            </Link>
          </li>
          <li>
            <Link to="/uv-newsletter" className="py-4">
              <i className="bx bxs-news"/>
              <span>Anuncios</span>
            </Link>
          </li>
          <li>
            <Link to="/uv-files" className="py-4">
              <i className="bx bx-file-blank"/>
              <span>Documentos</span>
            </Link>
          </li>
          <li>
            <Link to="/uv-meetings" className="py-4">
              <i className="bx bxs-phone-call"/>
              <span>Reuniones</span>
            </Link>
          </li>
          <li>
            <Link to="/uv-profile" className="py-4">
              <i className="bx bx-user"/>
              <span>Mi perfil</span>
            </Link>
          </li>
          {/*<li>*/}
          {/*  <Link to="/uv-users" className="py-4">*/}
          {/*    <i className="bx bxs-building-house"/>*/}
          {/*    <span>Vecinos</span>*/}
          {/*  </Link>*/}
          {/*</li>*/}
        </>;
      default:
        return <>
          <li>
            <Link to="/dashboard" className="py-4">
              <i className="bx bx-home-circle"/>
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/payments" className="py-4">
              <i className="bx bx-money"/>
              <span>Pagos</span>
            </Link>
          </li>
          <li>
            <Link to="/newsletter" className="py-4">
              <i className="bx bx-news"/>
              <span>Anuncios</span>
            </Link>
          </li>
          <li>
            <Link to="/meetings" className="py-4 {/*has-arrow*/}">
              <i className="bx bx-phone"/>
              <span>Reuniones</span>
            </Link>
          </li>
          <li>
            <Link to="/transactions" className="py-4">
              <i className="bx bx-briefcase-alt-2"/>
              <span>Transacciones</span>
            </Link>
          </li>
          <li>
            <Link to="/users" className="py-4">
              <i className="bx bx-id-card"/>
              <span>Usuarios</span>
            </Link>
          </li>
          <li>
            <Link to="/representative" className="py-4">
              <i className="bx bx-id-card"/>
              <span>Representantes</span>
            </Link>
          </li>
          <li>
            <Link to="/lots" className="py-4">
              <i className="bx bx-landscape"/>
              <span>Lotes</span>
            </Link>
          </li>
          <li>
            <Link to="/activities" className="py-4">
              <span className="badge rounded-pill bg-success float-end"/>
              <i className="bx bxs-detail" />
              <span>Registro actividad</span>
            </Link>
          </li>
          <li>
            <Link to="/comments" className="py-4">
              <span className="badge rounded-pill bg-success float-end"/>
              <i className="bx bxs-detail" />
              <span>Comentarios</span>
            </Link>
          </li>
          <li>
            <Link to="/files" className="py-4">
              <i className="bx bx-file"/>
              <span>Documentos</span>
            </Link>
          </li>
          <li>
            <Link to="/settings" className="py-4">
              <i className="bx bx-cog"/>
              <span>Configuracion</span>
            </Link>
          </li>
        </>;
    }
  };

  return (
    <>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {isAgent ? (<>
              {
                getMenuOptions(backend.getUserType())
              }
            </>) : (<>
              <li>
                <Link to="/uv-dashboard" className="py-4">
                  <i className="bx bx-home-circle"/>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li>
                  <Link to="/uv-payments" className="py-4">
                    <i className="bx bx-money"/>
                    <span>Pagos</span>
                  </Link>
              </li>
              <li>
                  <Link to="/uv-transactions" className="py-4">
                    <i className="bx bx-briefcase-alt-2"/>
                    <span>Transacciones</span>
                  </Link>
              </li>
              <li>
                <Link to="/uv-newsletter" className="py-4">
                  <i className="bx bxs-news"/>
                  <span>Anuncios</span>
                </Link>
              </li>
            <li>
                <Link to="/uv-files" className="py-4">
                    <i className="bx bx-file-blank"/>
                    <span>Documentos</span>
                </Link>
            </li>
            <li>
                <Link to="/uv-meetings" className="py-4">
                    <i className="bx bxs-phone-call"/>
                    <span>Reuniones</span>
                </Link>
            </li>
            <li>
              <Link to="/uv-profile" className="py-4">
                <i className="bx bx-user"/>
                <span>Mi perfil</span>
              </Link>
            </li>
            {/*<li>*/}
            {/*  <Link to="/uv-users" className="py-4">*/}
            {/*    <i className="bx bxs-building-house"/>*/}
            {/*    <span>Vecinos</span>*/}
            {/*  </Link>*/}
            {/*</li>*/}
            </>)}
          </ul>
        </div>
      </SimpleBar>
    </>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
