import React, {useContext, useEffect, useState} from 'react';
import Select from 'react-select'
import {MainContext} from '../../../contexts/Main.context';
import { FormGroup, Input, Label, Modal, Row, Form, InputGroup, InputGroupText, Button} from 'reactstrap';
import PropTypes from 'prop-types';
import {EnumService} from "../../../services/enum.service";

export const Create = ({active, toggle}) => {
    //Main context
    const {backend, showLoader, toastAlert, changeHandler} = useContext(MainContext);

    const defaultForm = {
        type:"",
        fees: 0,
        landId: "",
        comments: "",
        penalties: 0,
        baseAmount: 0,
        billImage: "",
        taxable : 0,
        pricePerUnit: 0,
        status: "OPENED",
        lastMeasurement: 0,
        currentMeasurement: 0,
        interestType: "MONTHLY",
        currentMeasurementImage: "",
        latestMeasurementImage :"",
    };

    const [services, setServices] = useState(false)
    const [form, setForm] = useState(defaultForm);

    //Asinar lotes y  lasts
    const [lasts, setLasts] = useState({}); 
    const [lots, setLots] = useState([]);
    const lotes = (withAll = true) => { 
        const options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
        lots.map((el) => options.push({id:el.id, value: el.id,  label: el.slug}));
        return options;
    };
    const lastsUpdate = ()=> {
        const _form = {...form};
        lasts.lastMeasurement ? _form.lastMeasurement = lasts.lastMeasurement :'';
        setForm(_form);    
    }


    //Suma de Baseamount
    const [total, setTotal] = useState(0);
    const getvalue = () => {
        const sum = (form.currentMeasurement - form.lastMeasurement) * form.pricePerUnit;
        const _form = {...form};
        _form.baseAmount = sum;
        setForm(_form);
    }

    //Get lots
    const attributes = ["slug", "id"];

    useEffect(() => {
        pullData();
    },[]);
    const pullData = async () => {
        const {data} = await backend.getLots({limit: 999, attributes: ["slug", "id"], order: ["slug", "asc"]});
        if(data){
            setLots(data);
        }
    };

    useEffect(() => {
        if(form.landId){
            pullLasts();
        }
    },[form.landId]);
    const pullLasts = async () => {
        const {data} = await backend.getServiceExpenses(parseInt(form.landId));
        if(data){
            setLasts(data);
            lastsUpdate();
        }
    };

    //Sent data
    const submitHandler = (ev) => {
        showLoader(true);
        ev.preventDefault();
        uploadData()
    };
    const uploadData = async () => {
        const resp = services == true ? await backend.newUserServicePaymentRequest(form) : await backend.newPaymentRequest(form);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'Subido correctamente', color: 'primary'});
            toggle('create');
            location.reload();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
        {toggle('create');}
    }
    return (
        <Modal size="l" isOpen={active}
            toggle={() => { toggle('create'); }}
            centered>
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                    Ingresa los Datos de la nueva Orden
                </h5>
                <button onClick={() => {toggle('create');}} type="button" className="close"
                        data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row className="mb-12">
                    <Form onSubmit={submitHandler}>
                        <div className="col-md-12 col-sm-12">
                            <FormGroup className="mt-6 mb-0">
                                <Label>Tipo de pago</Label>
                                <Input id="form-type" name="type" type="select" className="form-control select2-search-disable"
                                       onChange={(ev)=> {
                                        switch(ev.target.value){
                                            case 'LIGHT_SERVICE':
                                            case 'WATER_SERVICE':
                                            case 'OTHER_SERVICE':
                                                setServices(true);
                                                setForm(defaultForm);
                                                changeHandler(ev,form,setForm);
                                                break;
                                            case 'ORDINARY_FEE':
                                            case 'EXTRAORDINARY':
                                            case 'SANCTIONS':
                                            case 'ADMISSION_FEE':
                                            case 'OTHER':
                                                setServices(false);
                                                setForm(defaultForm);
                                                changeHandler(ev,form,setForm);
                                            break;
                                    }
                                }}>
                                    <option name="type" value="">Seleccionar</option>
                                    {EnumService.paymentRequestTypes.map(el => <option key={el.id} name="type" value={el.slug}>{el.label}</option>)}
                                </Input>
                                {services === true ? (
                                    <div className="mt-3">
                                        <Row className="mb-12">

                                            <div className="col-md-12 col-sm-12">
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label>Lote:</Label>
                                                    <Select 
                                                        isMulti={false}
                                                        options={lotes(false)}
                                                        required={true}
                                                        onChange={(selected)=>{
                                                            const _form = {...form};
                                                            _form.landId = selected.value;
                                                            setForm(_form); }}/>  
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6">
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label>Ultima medición</Label>
                                                    <Input 
                                                        className="form-control" 
                                                        name="lastMeasurement" 
                                                        required={true}
                                                        value={form.lastMeasurement}
                                                        onChange={(ev)=>{changeHandler(ev,form,setForm)}} />
                                                </FormGroup> 
                                            </div> 
                                            <div className="col-sm-6">
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label>Medición Actual</Label>
                                                    <Input className="form-control" name="currentMeasurement" onChange={(ev)=>{changeHandler(ev,form,setForm)}}
                                                           required={true}/>
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6">
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label>Precio de la Unidad</Label>
                                                    <Input className="form-control" name="pricePerUnit" onChange={(ev)=>{changeHandler(ev,form,setForm)}}
                                                           required={true}/>
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6">
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label>Día de la Medición</Label>
                                                    <Input type="date" className="form-control" name="measurementDate" onChange={(ev)=>{changeHandler(ev,form,setForm)}}
                                                           required={true}/>
                                                </FormGroup>
                                            </div>

                                            <div className="col-xl-12 col-sm-12">
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label  className="w-100 d-flex">Recibo PDF</Label>
                                                    <Input
                                                        className="form-control"
                                                        name="billImage"
                                                        type="file"
                                                        accept=".pdf"
                                                        onChange={(ev)=>{changeHandler(ev,form,setForm, true)}}
                                                        required={true}    
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-xl-12 col-sm-12">
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label>Imagen de la medición actual</Label>
                                                    <Input
                                                        className="form-control"
                                                        name="currentMeasurementImage"
                                                        type="file"
                                                        accept=".jpg, .jpeg, .png, .gif"
                                                        onChange={(ev)=>{changeHandler(ev,form,setForm, true)}}
                                                            required={true}/>
                                                </FormGroup>
                                            </div>

                                            
                                            <div className="col-sm-12">
                                                {form.baseAmount < 0 ?
                                                <div className="col-sm-12  mt-3">
                                                    <p className='alert-danger p-3'>El monto a pagar debe ser positivo para continuar</p>
                                                </div> :<></>}
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label>Monto a Pagar</Label>
                                                    <InputGroup>
                                                        <Input className="form-control " name="baseAmount"  value={form.baseAmount} onChange={(ev)=>{changeHandler(ev,form,setForm)}} disabled={true}/>
                                                        <InputGroupText className="bg-info">
                                                        {services === true ?
                                                            <>
                                                                <Button
                                                                    type="button" 
                                                                    onClick={() => getvalue()}
                                                                    className="btn w-100 text-white"
                                                                    color="info"
                                                                    outline={true}>
                                                                    Calcular
                                                                </Button>
                                                            </> : <></>}
                                                        </InputGroupText>
                                                    </InputGroup>
                                                </FormGroup>
                                            </div>
                                        </Row>
                                    </div>

                                ) : (

                                    <div className="mt-3">
                                        <Row className="mb-12">
                                            <div className="col-md-12 col-sm-12">
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label>Lote :</Label>
                                                    <Select 
                                                        required={true} 
                                                        isMulti={true}
                                                        options={lotes()} 
                                                        onChange={(selected)=>{
                                                            const result = selected.filter(el => el.value == 'All')
                                                            const _form = {...form};
                                                            result[0] ? _form.landId = lotes(false).map(el=> el.id) : _form.landId = selected.map(el => el.id);
                                                            setForm(_form);    
                                                    }} />                                                
                                                </FormGroup>
                                            </div>

                                            <div className="col-sm-6">
                                            <FormGroup className="mt-6 mb-0">
                                                <Label>Monto Base</Label>
                                                <Input className="form-control" name="baseAmount" onChange={(ev)=>{changeHandler(ev,form,setForm)}} required={true}/>
                                            </FormGroup>
                                            </div>

                                            <div className="col-sm-6">
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label>Día de pago</Label>
                                                    <Input type="date" className="form-control" name="payday" onChange={(ev)=>{changeHandler(ev,form,setForm)}} required={true}/>
                                                </FormGroup>
                                            </div>

                                            {/*<div className="col-sm-6">
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label>Comisiones</Label>
                                                    <Input className="form-control" name="fees" onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                                                </FormGroup>
                                            </div>*/}

                                            <div className="col-sm-12">
                                                <FormGroup className="mt-6 mb-0">
                                                    <Label>Comentarios</Label>
                                                    <Input className="form-control" type="textarea" name="comments" onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                                                </FormGroup>
                                            </div>
                                        </Row>
                                    </div>
                                )}

                            </FormGroup>

                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                data-dismiss="modal"
                                className="btn btn-danger " 
                                onClick={() => {toggle('create');}} >
                                Cancelar
                            </button>
                            {form.baseAmount > 0 ? 
                                <button 
                                    type="submit"
                                    aria-label="Close"
                                    data-dismiss="modal"
                                    className="btn btn-success"> 
                                        Añadir nueva orden
                                </button> 
                                :
                                <button 
                                    disabled
                                    type="submit" 
                                    aria-label="Close"
                                    data-dismiss="modal"
                                    className="btn btn-success"> 
                                        Añadir nueva orden
                                </button>}
                        </div>
                    </Form>
                </Row>
            </div>
        </Modal>
    );
}

Create.propTypes = {
    active: PropTypes.bool,
    toggle: PropTypes.func
}

