import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {FormGroup, Input, Label, Modal, ModalBody, Row, Form, Col, Button} from "reactstrap";
import {MainContext} from "../../../contexts/Main.context";
import { EnumService } from "services/enum.service";


export const ModalPaymentProof = ({paymentRequest, toggle, isOpen}) => {
    
    const defaultForm = {
        amount: paymentRequest.total,
        method: '',
        paymentRequestId: paymentRequest.id,
        date: '',
        nop: '',
        bank: '',
        image: '',
        secondImage: '',
        payerNote: '',
    };

    const {backend, formatter, showLoader, toastAlert, changeHandler} = useContext(MainContext);
    const [form, setForm] = useState(defaultForm);

    const submitHandler = (ev) => {
        showLoader(true);
        ev.preventDefault();
        uploadData()
        //sed to the backend
    };

    const uploadData = async () => {
        const resp = await backend.newPayment(form);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'Subido correctamente', color: 'primary'});
            toggle();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
    }
    return(
        <>
        <Modal size="md" isOpen={isOpen} toggle={() => { toggle(); }} centered id="invoice-modal">
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Comprobante de Pago
                </h5>
                <button type="button" onClick={() => {toggle('modalproof');}} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <ModalBody>
                <Form onSubmit={submitHandler}>
                    <Row>
                        <div className="col-xl col-sm-6">
                            <FormGroup className="mt-6 mb-0">
                                <Label>Fecha del Pago*</Label>
                                <Input onChange={(ev)=>{changeHandler(ev,form, setForm)}}
                                    className="form-control" type="date" name="date">
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <FormGroup className="mt-6 mb-0">
                                <Label>Metodo de Pago*</Label>
                                <Input type="select" className="form-control select2-search-disable" name="method"
                                    onChange={(ev)=>{changeHandler(ev,form, setForm)}}
                                    required={true}>
                                    <option value="">Elige</option>
                                    {EnumService.paymentMethods.filter(pm => pm.id > 1).map(pm =>
                                        (<option key={pm.id} value={pm.slug}>{pm.label}</option>)
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                    </Row>
                    {form.method === 'BANK-TRANSFER' || form.method === 'DEPOSIT' || form.method === 'OTHER' ?
                        (<>
                            <Row className="mb-12">
                                <div className="col-xl col-sm-6">
                                    <FormGroup className="mt-6 mb-0">
                                        <Label>NOP*</Label>
                                        <Input onChange={(ev)=>{changeHandler(ev,form, setForm)}}
                                            className="form-control" type="text" name="nop" required={true}/>
                                    </FormGroup>
                                </div>
                                <div className="col-xl col-sm-6">
                                    <FormGroup className="mt-6 mb-0">
                                        <Label>Banco*</Label>
                                        <Input type="select" className="form-control select2-search-disable" name="bank"
                                            onChange={(ev)=>{changeHandler(ev,form, setForm)}}
                                            required={true}>
                                            <option value="">Elige</option>
                                            {EnumService.banks.map(pm =>
                                            (<option key={pm.id} value={pm.slug}>{pm.label}</option>)
                                            )}
                                        </Input>
                                    </FormGroup>
                                </div>
                            </Row>
                        </>) : (<></>)}
                            <Row className="mb-12">
                                <div className="col-xl col-sm-6">
                                    <FormGroup className="mt-6 mb-0">
                                        <Label>Imagen del Comprobante*</Label>
                                        <Input onChange={(ev)=>{changeHandler(ev,form, setForm, true)}}
                                            className="form-control" type="file" name="image"/>
                                    </FormGroup>
                                </div>
                                <div className="col-xl col-sm-6">
                                    <FormGroup className="mt-6 mb-0">
                                        <Label>Imagen alternativa</Label>
                                        <Input className="form-control" type="file" name="secondImage"
                                            onChange={(ev)=>{changeHandler(ev,form, setForm, true)}}/>
                                    </FormGroup>
                                </div>
                            </Row>
                    <Row>
                        <FormGroup>
                            <Label>Comentario</Label>
                            <Input className="" type="textarea" name="payerNote"
                                onChange={(ev)=>{changeHandler(ev,form,setForm)}} height={300}/>
                        </FormGroup>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Button color="primary mb-3">
                                Enviar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
        </>
    )
}

ModalPaymentProof.propTypes = {
    paymentRequest: PropTypes.object,
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool
}

