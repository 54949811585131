import React, { useContext, useEffect, useState } from "react"
import PropTypes, { bool } from "prop-types"
import Select from "react-select"
import { MainContext } from "../../contexts/Main.context"
import { Input, Label, Modal, Row, CardTitle, Button, Col } from "reactstrap"

export const Create = ({ active, toggle, lot }) => {
  const attributes = ["slug", "id"]

  const defaultForm = {
    landId: "",
    content: "",
  }

  const { backend, showLoader, changeHandler, toastAlert } =
    useContext(MainContext)
  const [form, setForm] = useState(defaultForm); console.log(form)
  const [users, setUsers] = useState([]);

  //get
  useEffect(() => {
    pullUsers()
  }, [])
  const pullUsers = async () => {
    const { data } = await backend.getLots({ limit: 999, attributes })
    console.log(data)
    if (data) {
      const dataCopy = []
      data.map(x => dataCopy.push({ id: x.id, value: x.id, label: x.slug }))
      setUsers(dataCopy)
    }
  }

  const submitHandler = () => {
    showLoader(true)
    uploadData()
  }
  const uploadData = async () => {
    const resp = await backend.createComment(form)
    showLoader(false)
    if (resp.status) {
      toastAlert({
        body: "Creado correctamente correctamente",
        color: "primary",
      })
      toggle("create")
      location.reload()
    } else {
      toastAlert({ body: "Error inesperado", color: "warning" })
    }
  }

  return (
    <Modal
      size="l"
      centered
      isOpen={active}
      toggle={() => {
        toggle("create")
      }}
    >
      <Row>
        <CardTitle className="mt-4 mx-3">
          Nuevo comentario
        </CardTitle>
        <Button
          onClick={() => {
            toggle("create")
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        ></Button>
      </Row>
      <form>
        <Row className="modal-body">
          <Col xs={12} className="mb-3">
            <Label>Lote:</Label>
            <Select
              required
              isMulti={false}
              name="landId"
              placeholder="Ingresar..."
              options={users}
              onChange={selected => {
                const _form = { ...form }
                _form.landId = selected.value
                setForm(_form)
              }}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <Label>Comentario:</Label>
            <Input
              name="content"
              type="textarea"
              value={form.content}
              placeholder="Escribe aquí tu comentario..."
              className="form-control"
              onChange={ev => {
                changeHandler(ev, form, setForm)
              }}
            />
          </Col>
          <Col xs={12}>
            <Button
              className="btn btn-success w-100 p-2"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => submitHandler()}
            >
              Guardar cambios
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}
Create.propTypes = {
  active: PropTypes.bool,
  toggle: PropTypes.func,
  lot: PropTypes.any,
}
