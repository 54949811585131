const yearUVData = [
    {
        name: "Monto Base",
        data: [15000, 15000, 15000, 15000, 15000]
    },
    {
        name: "Servicios",
        data: [2130, 3230, 4200, 3180, 2830]
    },
    {
        name: "Otros",
        data: [1511, 1317, 1815, 3115, 2821]
    },
];
export { yearUVData }

const yearData = [
    {
        name: "Cuota Ordinaria",
        data: [120, 0]
    },

    {
        name: "Cuota Extraordinario",
        data: [120, 0]
    },
    {
        name: "Servicios",
        data: [120, 0]

    },
    {
        name: "Cuota de Admisión",
        data: [120, 0]

    },
    {
        name: "Sanciones",
        data: [120, 0]

    },
    {
        name: "Otros",
        data: [110, 0]

    },
];
export { yearData }