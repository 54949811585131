import PropTypes from "prop-types";
import Select from "react-select";
import { MainContext } from "contexts/Main.context";
import { EnumService } from "services/enum.service";
import React, {useContext, useState, useEffect} from "react";
import {Input, Label, Modal, Row, Button, Col, CardTitle} from 'reactstrap';


export const Update = ({active, toggle, transaction}) => {

    const defaultForm = {
        description:'',
        creatorId:'',
        comment:'',
        method:'',
        amount:0,
        date:''
    }
	const {backend, showLoader, toastAlert, changeHandler} = useContext(MainContext);
	const [form, setForm] = useState(defaultForm);
    const options = (withAll = true, array) => {
        const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
        array.map((el) => _options.push({id:el.id, value: el.value ? el.value : el.id,  label: el.label ? el.label : el.id}));
        return _options;
    };
	
	//get
	useEffect(() => {
		pullData();
	},[])
	const pullData = async () => {
		const resp = await backend.getTransaction(transaction);
		if(resp && resp.status){
			const vals = {
				"description":resp.data.description,
                "creatorId":resp.data.creatorId,
                "comment":resp.data.comment,
                "method":resp.data.method,
                "amount":resp.data.amount,
                "date":resp.data.date
			};
			setForm(vals);
		}
	};
	//update
	const submitHandler = () => {
		showLoader(true);
		uploadData()
	};
	const uploadData = async () => {
        const resp = await backend.updateTransaction(transaction, form);
        showLoader(false);
        if (resp.status) {
                toastAlert({body: 'Actualizado correctamente', color: 'primary'});
                toggle('update');
                location.reload();
        } else {
                toastAlert({body: 'Error inesperado', color: 'warning'});
        }
	};

	return(
        <Modal 
            size="l" 
            centered
            isOpen={active}
            toggle={() => { toggle('update'); }}>
            <Row>
                <CardTitle className="mt-4 mx-3">
                    Actualizar Transacción
                </CardTitle>
                <Button 
                    onClick={() => {toggle('update');}} 
                    type="button" 
                    className="close"
                    data-dismiss="modal" 
                    aria-label="Close">
                </Button>
            </Row>
            <Row className="modal-body">
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Fecha:</Label>
                    <Input 
                        type="date" 
                        name="date"
                        value={form.date.slice(0, 10)}
                        className="form-control" 
                        placeholder="Seleccionar fecha"
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Metodo de Pago</Label>
                    <Select 
                        isMulti={false}
                        placeholder="Ingresar..."
                        options={options(true, EnumService.paymentMethods)} 
                        onChange={(selected)=> {
                            const _form = {...form};
                            _form.method = selected.value,
                            setForm(_form);}}
                    />
                </Col>
                <Col xs={12} className="mb-3">
                    <Label>Monto:</Label>
                    <Input 
                        name="amount"
                        type='number'
                        value={form.amount}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} className="mb-3">
                    <Label>Descripción:</Label>
                    <Input 
                        name="description"
                        value={form.description}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} className="mb-3">
                    <Label>Comentarios:</Label>
                    <Input 
                        name="comment"
                        value={form.comment}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12}>
                    <Button
                        className="btn btn-success w-100 p-2"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={()=> submitHandler()}>
                            Guardar cambios
                    </Button>
                </Col>
            </Row>
        </Modal>
	);
};
Update.propTypes = {
	active: PropTypes.bool,
	toggle: PropTypes.func,
	transaction: PropTypes.any
}