import React, {useState, useRef, useContext} from 'react';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import {Card, CardBody, Button, Row, Col} from "reactstrap";
import {logo} from '../../assets';
import {PaymentRequestStatusBadge} from "./PaymentRequestStatusBadge";
import {MainContext} from "../../contexts/Main.context";
import PropTypes from "prop-types";
import './Invoice.scss';

export const Invoice = ({toggle, payment, paymentRequest}) => {
    const {formatter} = useContext(MainContext);
    const container = useRef(null);
    const pdfExportComponent = useRef(null);

    const units = {
        light: <>KWh</>,
        water: <>M<sup>3</sup></>,
    };

    const [unit, setUnit] = useState(units.water)

    const exportPDF = () => {
        let element = container.current || document.body;
        savePDF(element, {
            paperSize: "auto",
            margin: 40,
            fileName: `RECIBO #`,
        });
    };

    const nameFormatter = (name, surname, company) => {
        if( name || surname) {
            return name +' '+ surname;
        }else {
            return company
        }
    }

    return( <>
                <div className="buttons">
                    <div className="d-flex justify-content-center">
                        <Row className="">
                            <Col>
                                <Button onClick={exportPDF} color="primary">
                                    Descargar
                                </Button>
                            </Col>
                            <Col>
                                <Button onClick={()=>{toggle()}}>
                                    Cerrar
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <hr/>
                <PDFExport ref={pdfExportComponent} paperSize="auto" margin={40}
                       fileName={`Recibo de ${new Date().getFullYear()}`} author="KendoReact Team">
                        <div className="invoice" ref={container}>
                            <div>
                                <Row className="header-row">
                                    <Col sm={3}>
                                        <img src={logo} alt="Logo del condominio" className="invoice-logo"/>
                                    </Col>
                                    <Col sm={9}>
                                        <p className="small font-weight-semibold">
                                            ASOCIACION ECOLOGICA LA RAYA Y LA SASHAVACA
                                        </p>
                                        <p className="m-0 small">
                                            RUC 20512844708
                                        </p>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col className="d-flex justify-content-between">
                                        <h4 className="my-1">RECIBO</h4>
                                        <h4 className="my-1">#{payment.id}</h4>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row className="lot-data">
                                    <Col className="table-item d-flex justify-content-between">
                                        <b className="font-weight-semibold">Propietario</b>
                                        <p>
                                            {nameFormatter(payment.UsersModel.name, payment.UsersModel.surname, payment.UsersModel.companyName)}
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="lot-data">
                                    <Col className="table-item d-flex justify-content-between">
                                        <b className="font-weight-semibold">Código:</b>
                                        <p>{formatter.addLeftZeros(paymentRequest.land.code)}</p>
                                    </Col>
                                    <Col className="table-item d-flex justify-content-between">
                                        <b className="font-weight-semibold">Lote:</b>
                                        <p>MZ {paymentRequest.land.zone} LT {paymentRequest.land.lot}</p>
                                    </Col>
                                </Row>
                                <Row className="lot-data">
                                    <Col className="table-item d-flex justify-content-between">
                                        <b className="font-weight-semibold">Emisión</b>
                                        <p>{formatter.date(paymentRequest.createdAt)}</p>
                                    </Col>
                                    <Col className="table-item d-flex justify-content-between">
                                        <b className="font-weight-semibold">Vence:</b>
                                        <p>{formatter.date(paymentRequest.payday)}</p>
                                    </Col>
                                </Row>
                                <Row className="lot-data">
                                    <Col className="table-item d-flex justify-content-between align-items-center status">
                                        <b className="font-weight-semibold align-items-center">Estado:</b>
                                        <PaymentRequestStatusBadge status={paymentRequest.status}/>
                                    </Col>
                                    <Col className="table-item d-flex justify-content-between align-items-center">
                                        <b className="font-weight-semibold align-items-center">Tipo</b>
                                        <p>{formatter.typeEnum(paymentRequest.type)}</p>
                                    </Col>
                                </Row>
                                <hr/>
                                { paymentRequest.type === "LIGHT_SERVICE" || paymentRequest.type === 'WATER_SERVICE' || paymentRequest.type === 'OTHER_SERVICE' ?
                                <>
                                    <Row className="service" >
                                        <Col className="table-item d-flex justify-content-between">
                                            <b className="font-weight-semibold">Servicio:</b>
                                            <p>{formatter.typeEnum(paymentRequest.service.service) ?? 'Sin especificar'}</p>
                                        </Col>
                                        <Col className="table-item d-flex justify-content-between">
                                            <b className="font-weight-semibold">Tarifa 1{unit}:</b>
                                            <p>{formatter.amount(paymentRequest.fees)}</p>
                                        </Col>
                                    </Row>
                                    <Row className="service" >
                                        <Col className="table-item d-flex justify-content-between">
                                            <b className="font-weight-semibold">Lectura anterior</b>
                                            <p className="small">{formatter.amount(paymentRequest.service.lastMeasurement) ?? 'Sin especificar'}{' '}{unit}</p>
                                        </Col>
                                        <Col className="table-item d-flex justify-content-between">
                                            <b className="font-weight-semibold">Lectura actual</b>
                                            <p className="small">{formatter.amount(paymentRequest.service.currentMeasurement) ?? 'Sin especificar'}{' '}{unit}</p>
                                        </Col>
                                    </Row>
                                    <Row className="service" >
                                        <Col className="table-item d-flex justify-content-between">
                                            <b className="font-weight-semibold">Consumo del mes</b>
                                            <p className="small">{formatter.amount(paymentRequest.service.lastMeasurement - paymentRequest.service.currentMeasurement) ?? 'Sin especificar'}{' '}{unit}</p>
                                        </Col>
                                        <Col className="table-item d-flex justify-content-between">
                                            <b className="font-weight-semibold">Sub total</b>
                                            <p>{formatter.amount(paymentRequest.payment.amount)}</p>
                                        </Col>
                                    </Row>
                                    <Row className="service" >
                                        <Col className="table-item d-flex justify-content-between">
                                            <b className="font-weight-semibold">Tarifa 1{unit}:</b>
                                            <p>{formatter.amount(paymentRequest.fees)}</p>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    <Row className="service" >
                                        {/* <Col className="table-item d-flex justify-content-between">
                                            <b className="font-weight-semibold">Consumo del mes</b>
                                            <p className="small">{formatter.amount(paymentRequest.service.lastMeasurement - paymentRequest.service.currentMeasurement) ?? 'Sin especificar'}{' '}{unit}</p>
                                        </Col> */}
                                        <Col className="table-item d-flex justify-content-between">
                                            <b className="font-weight-semibold">Sub total</b>
                                            <p>{formatter.amount(paymentRequest.payment.amount)}</p>
                                        </Col>
                                    </Row>
                                </>}


                                { paymentRequest.type === "ORDINARY_FEE" ?
                                <>
                                    <Row className="service" >
                                        <Col className="table-item d-flex justify-content-between">
                                            <b className="font-weight-semibold">Cuota Ordinaria:</b>
                                            <p>{formatter.amount(paymentRequest.payment.amount + paymentRequest.fees)}</p>
                                        </Col>
                                    </Row>
                                </> : <></>}


                                <hr/>
                                <Row>
                                    <Col className="table-item d-flex justify-content-between">
                                        <p>Sub total:</p>
                                        <p>{formatter.amount(paymentRequest.payment.amount + paymentRequest.fees)}</p>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col className="table-item d-flex justify-content-between">
                                        <p>Redondeo:</p>
                                        <p>{Math.round(parseInt(paymentRequest.payment.amount - paymentRequest.fees))}</p>
                                    </Col>
                                </Row> */}
                                <Row className="resume" >
                                    <Col className="table-item d-flex justify-content-between">
                                        <h5>TOTAL:</h5>
                                        <h5>{formatter.amount(paymentRequest.payment.amount + paymentRequest.fees)}</h5>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                </PDFExport>
                </>);
};

Invoice.propTypes = {
    toggle: PropTypes.func,
    payment: PropTypes.object,
    paymentRequest: PropTypes.object
};
