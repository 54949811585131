import React, {useContext, useEffect, useState} from "react"
import Select from 'react-select'
import MetaTags from 'react-meta-tags';
import {Button, Card, CardBody, Col, Container, Label, Row, Badge, Input } from "reactstrap"
import {Create} from "../../components/Payments/Modals/Create";
import {Update} from "../../components/Payments/Modals/Update";
import {Delete} from "../../components/Payments/Modals/Delete";
import changeHandler, {MainContext} from '../../contexts/Main.context';
import {EnumService} from "../../services/enum.service";
import {TableWrapper} from "../../components/Helpers/TableWrapper";
import {CustomPagination} from "../../components/Common/CustomPagination";
import {ModalPaymentProof} from "../../components/Payments/Modals/modalPaymetProof"
import {BulkInsert} from "../../components/Payments/Modals/MultiService";

const Payments = () => {

  const defaultFilter = {
    type:'',
    paymentMethod: '',
    date_from: '',
    date_to: '',
    land_id: '',
    dateTo: '',
    status: '',
    id: '',
  };

  const columns = [
    {name: '#'},
    {name: 'Fecha de creacion'},
    {name: 'Fecha de vencimiento'},
    {name: 'Lote'},
    {name: 'Tipo de pago'},
    {name: 'Estatus'},
    {name: ''},
  ];

  const {backend, formatter, showLoader, changeHandler} = useContext(MainContext);
  const [rows, setRows] = useState([]);
  const [lot, setLots] = useState([]);
  const [id, setId] = useState([]);
  const [page, setPage] = useState(1);
  const [modals, setModals] = useState({ create: false, update: false, delete: false, modalproof: false, bulk: false });
  const [filter, setFilter] = useState(defaultFilter);
  const [totalPages, setTotalPages] = useState(1);
  const [openedPaymentRequest, setOpenedPaymentRequest] = useState({});

  useEffect(() => {
    pullId();
    pullData();
    pullLots();
  }, [page])

  const pullData = async () => {
    const {data, meta} = await backend.getPaymentRequests({page, where: filter});
    if(data){
      setTotalPages(meta.lastPage);
      setRows(data);
    }
  };
  const pullId = async () => {
    const {data} = await backend.getPaymentRequests({page, limit: 999, attributes:["id"]}); 
    if(data){
      setId(data);
    }
  }; 
  const pullLots = async () => {
    const {data} = await backend.getLots({page, limit: 999, attributes:["lot","slug","id"]}); 
    if(data){
      setLots(data);
    }
  }; 
  
  const options = (array) => { 
    const options = [{id: "All", value: "",  label: "todos"}]
    array.map((el) => options.push({id: el.id, value: el.slug,  label: el.label}));
    return options
  };
  const _options = (withAll = true, array) => { 
    const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
    array.map((el) => _options.push({id:el.id, value: el.id,  label: el.id}));
    return _options;
  };
  const lotsOptions = (withAll = true, array) => { 
    const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
    array.map((el) => _options.push({id:el.id, value: el.id,  label: el.slug}));
    return _options;
  };

  const toggleModal = (modal) => {
    const mdl = {...modals};
    mdl[modal] = !mdl[modal];
    setModals(mdl);
    pullData();
  };

  const getBadgeStatus = (status) => {
      const colorList = {
          'OPENED': 'info',
          'ERROR': 'danger',
          'DEFAULTED': 'dark',
          'EXPIRED': 'warning',
          'PROCESSING': 'secondary',
          'PAID': 'success',
      };
      const color = colorList[status] ?? 'warning';
      return(
        <Badge pill color={color} style={{backgroundColor: "transparent !important"}}>
            <p className={`font-size-14 p-1 m-0 ${color === 'warning' ? 'text-black' : ''}`}>
                {formatter.typeEnum(status)}
            </p>
        </Badge>
      );
  };

  const downloadCsv = async () => {
    showLoader(true);
    await backend.getCsvFromPath('/payment-requests/csv/get', filter);
    showLoader(false);
  };

  const uploadServices = async () => {
      toggleModal('bulk');
  };

  return (
    <React.Fragment>
      <MetaTags> <title> Pagos | Condominio la raya </title> </MetaTags>
      {/*TODO: Add the correct toggle function, just like the ModalPaymentProof does*/}
      {modals.bulk ? (<BulkInsert active={modals.bulk} toggle={toggleModal} />) : (<></>)}
      {modals.create ? (<Create active={modals.create} toggle={toggleModal} paymentRequest={openedPaymentRequest} />) : (<></>)}
      {modals.update ? (<Update isOpen={modals.update} toggle={toggleModal} paymentRequest={openedPaymentRequest} />) : (<></>)}
      {modals.delete ? (<Delete active={modals.delete} toggle={toggleModal} paymentRequest={openedPaymentRequest} />) : (<></>)}
      {modals.modalproof ? (<ModalPaymentProof paymentRequest={openedPaymentRequest} isOpen={modals.modalproof} toggle={()=>{toggleModal('modalproof')}} />) : (<></>)}

      <div id="payments">
        <div className="page-content">

          <Container fluid>
            <Row className="align-items-center">
              <Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Pagos </h4> </Col>
              <Col xs={12} md={6}>
                <Row className="d-flex justify-content-end">
                    <Col xs={12} md={4} className="d-flex justify-content-end mb-4">
                        <Button
                          type="button"
                          color="primary"
                          className="w-100"
                          onClick={uploadServices}>
                            Subir servicios
                        </Button>
                    </Col>
                  <Col xs={12} md={4} className="d-flex justify-content-end mb-4">
                    <Button 
                      type="button" 
                      color="primary" 
                      className="w-100"
                      onClick={downloadCsv}>
                        Descargar CSV
                    </Button>
                  </Col>
                  <Col xs={12} md={4} className="mb-4">
                    <Button 
                      type="button" 
                      color="success"
                      className="w-100"
                      onClick={() => {toggleModal('create');}}>
                        Crear
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row >
              <Col xs={12} md={2} className="mt-3 mb-0">
                <Label className=""> Desde: </Label>
                <Input
                  name="date_from"
                  onChange={(ev)=> {
                      const _form = {...filter};
                      _form.date_from = ev.target.value;
                      setFilter(_form);
                  }}
                  type="date"
                  className="form-control"
                  />
              </Col>
              <Col xs={12} md={2} className="mt-3 mb-0">
                <Label className=""> Hasta: </Label>
                <Input
                  type="date"
                  name="date_to"
                  onChange={(ev)=> {
                      const _form = {...filter};
                      _form.date_to = ev.target.value;
                      setFilter(_form);
                  }}
                  className="form-control"
                  />
              </Col>
            </Row>
            <Row className="mb-4 d-flex align-items-center">
              <Col xs={12} md={2} className="mt-3 mb-0">
                <Label>Tipo de pago:</Label>
                <Select 
                  isMulti={true}
                  options={options(EnumService.paymentRequestTypes)} 
                  placeholder="Ingresar..."
                  onChange={(selected)=>{
                    const result = selected.filter(el => el.id == 'All')
                    const _form = {...filter};
                    result[0] ? _form.type = "" : _form.type = selected.map(el => el.value).join();
                    setFilter(_form);}}
                /> 
              </Col>
              <Col xs={12} md={2} className="mt-3 mb-0">
                <Label>Estatus:</Label>
                <Select 
                  isMulti={true}
                  options={options(EnumService.paymentRequestStatus)} 
                  placeholder="Ingresar..."
                  onChange={(selected)=>{
                    const result = selected.filter(el => el.id == 'All')
                    const _form = {...filter};
                    result[0] ? _form.status = "" : _form.status = selected.map(el => el.value).join();
                    setFilter(_form);}}
                /> 
              </Col>
              <Col xs={12} md={2} className="mt-3 mb-0">
                <Label>Buscar por #:</Label>
                <Select 
                  isMulti={true}
                  placeholder="Ingresar..."
                  options={_options(true, id)} 
                  onChange={(selected)=> {
                    const result = selected.filter(el => el.value == 'All');
                    const _form = {...filter};
                    result[0] ? _form.id = "" : _form.id = selected.map(el => el.value).join();
                    setFilter(_form);}}
                  />
              </Col>
              <Col xs={12} md={2} className="mt-3 mb-0">
                <Label>Lote:</Label>
                <Select 
                  isMulti={true}
                  placeholder="Ingresar..."
                  options={lotsOptions(true, lot)}
                  onChange={(selected)=>{
                    const result = selected.filter(el => el.value == 'All')
                    const _form = {...filter};
                    result[0] ? _form.land_id = "" : _form.land_id = selected.map(el => el.value).join();
                    setFilter(_form);}} 
                /> 
              </Col>
              <Col xs={12} md={2}> <br/> <br/>
                <Button type="button" color="primary" className="w-100" onClick={()=> pullData()}>
                  Filtrar
                </Button>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive text-nowrap">
                      <TableWrapper headers={columns}>
                        {rows ? rows.map((paymentRequest =><tr key={paymentRequest.id} style={{verticalAlign: 'middle'}} className={`
                            ${paymentRequest.status === 'DEFAULTED' ? 'bg-danger bg-soft' : ''}
                            ${paymentRequest.status === 'EXPIRED' ? 'bg-warning bg-soft' : ''}
                            ${paymentRequest.status === 'ERROR' ? 'bg-error bg-soft' : ''}

                            `}>
                          <td className="text-nowrap font-size-14">{paymentRequest.id}</td>
                          <td className="text-nowrap font-size-14">{formatter.date(paymentRequest.cratedAt)}</td>
                          <td className="text-nowrap font-size-14">{formatter.date(paymentRequest.payday)}</td>
                          <td className="text-nowrap font-size-14">{paymentRequest.land ? paymentRequest.land.slug : ''}</td>
                          <td className="text-nowrap font-size-14">{formatter.typeEnum(paymentRequest.type)}</td>
                          <td className="text-nowrap font-size-18">{getBadgeStatus(paymentRequest.status)}</td>
                          <td className="text-nowrap font-size-14">
                            <div className="row-buttons-container flex-nowrap">
                              <Button type="button" color={'info'} outline={true} onClick={() => { setOpenedPaymentRequest(paymentRequest); toggleModal('update'); }} data-toggle="modal" data-target="#myModal">
                                <p className="m-0 font-size-15">
                                  Detalles
                                </p>
                              </Button>
                              { paymentRequest.status == 'PAID' ? <></> :
                                <>
                                  { paymentRequest.status == 'PROCESSING' ? <></> :
                                    <>
                                        <Button className={'m-2'} type="button" color="success" outline={true} onClick={() => {setOpenedPaymentRequest(paymentRequest); toggleModal('modalproof');}} data-toggle="modal" data-target="#myModal">
                                          <p className="m-0 font-size-15">
                                            Comprobante de Pago
                                          </p>
                                        </Button>
                                        <Button type="button" color="danger" outline={true} size="sm" onClick={() => { setOpenedPaymentRequest(paymentRequest.id); toggleModal('delete'); }} data-toggle="modal">
                                            <p className="m-0 font-size-15">
                                            Eliminar
                                            </p>
                                        </Button>
                                    </>
                                  }
                                </>
                              }
                            </div>
                          </td>
                        </tr>)) : (<></>)}
                      </TableWrapper>
                    </div>
										<Row className="mt-4">
                      <CustomPagination currentPage={page} setPage={setPage} totalPages={totalPages}/>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Payments;