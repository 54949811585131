import React, {useContext} from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import {MainContext} from "../contexts/Main.context";

const Authmiddleware = ({ component: Component, layout: Layout, isAuthProtected, agentProtected, ...rest }) => {
    const {isLoggedIn, isAgent} = useContext(MainContext);
    return (
    <>
      <Route
        {...rest}
        render={props => {
          if (isAuthProtected && !isLoggedIn) {
            return (
              <Redirect to={{ pathname: "/login", state: { from: props.location } }}/>
            )
          }
          if(agentProtected && !isAgent){
            return (
              <Redirect to={{ pathname: "/uv-dashboard", state: { from: props.location } }}/>
            )
          }
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        }}
      />
    </>
)}

Authmiddleware.propTypes = {
    isAuthProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any,
    agentProtected: PropTypes.bool
}

export default Authmiddleware;