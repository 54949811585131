export class EnumService{
    static paymentRequestTypes = [
        {slug: 'ORDINARY_FEE',      value: 'ORDINARY_FEE',      label: 'Cuota Ordinaria',       id: 2},
        {slug: 'LIGHT_SERVICE',     value: 'LIGHT_SERVICE',     label: 'Luz',                   id: 3},
        {slug: 'WATER_SERVICE',     value: 'WATER_SERVICE',     label: 'Agua',                  id: 4},
        {slug: 'EXTRAORDINARY',     value: 'EXTRAORDINARY',     label: 'Gasto extraordinario',  id: 6},
        {slug: 'SANCTIONS',         value: 'SANCTIONS',         label: 'Sanciones',             id: 7},
        {slug: 'ADMISSION_FEE',     value: 'ADMISSION_FEE',     label: 'Cuota de admisión',     id: 8},
        {slug: 'OTHER',             value: 'OTHER',             label: 'Otro',                  id: 9},
    ];

    static paymentRequestStatus = [
        {slug: 'OPENED',      value: 'OPENED',      label: 'Abierto',       id: 1},
        {slug: 'PAID',        value: 'PAID',        label: 'Pagado',        id: 2},
        {slug: 'EXPIRED',     value: 'EXPIRED',     label: 'Vencido',       id: 3},
        {slug: 'DEFAULTED',   value: 'DEFAULTED',   label: 'Moroso',        id: 4},
        {slug: 'PROCESSING',  value: 'PROCESSING',  label: 'Procesando',    id: 5},
    ];  

    static paymentMethods = [
        {slug: 'CASH',            value: 'CASH',                label: 'Efectivo',                  id: 2},
        {slug: 'BANK-TRANSFER',   value: 'BANK-TRANSFER',       label: 'Transferencia Bancaria',    id: 3},
        {slug: 'PAYMENT-GATEWAY', value: 'PAYMENT-GATEWAY',     label: 'Pasarela de pagos',         id: 1},
        {slug: 'DEPOSIT',         value: 'DEPOSIT',             label: 'Deposito',                  id: 4},
        // {slug: 'WESTERN-UNION',   value: 'WESTERN-UNION',       label: 'Western Union',             id: 5},
        {slug: 'PRE-PAID',        value: 'PRE-PAID',            label: 'Pre pagado',                id: 6},
        {slug: 'OTHER',           value: 'OTHER',               label: 'Otro',                      id: 7},
    ];

    static paymentStatus = [
        {slug:'REVISION',   value:'REVISION',    label: 'En revision',  id: 1},
        {slug:'USER_ERROR', value:'USER_ERROR',  label: 'Error',        id: 2},
        {slug:'CANCELED',   value:'CANCELED',    label: 'Cancelado',    id: 3},
        {slug:'VERIFIED',   value:'VERIFIED',    label: 'Verificado',   id: 4},
    ];

    static servicesType = [
        {slug:'LIGHT', value:'LIGHT', label:'Luz',   id: 1},
        {slug:'WATER', value:'WATER', label:'Agua',  id: 2},
        {slug:'OTHER', value:'OTHER', label:'Otro',  id: 3},
    ];

    static banks = [
        {slug:'BCP',                value:'BCP',               label:'BCP',                id: 1},
        {slug:'BBVA',               value:'BBVA',              label:'BBVA',               id: 2},
        {slug:'INTERBANK',          value:'INTERBANK',         label:'INTERBANK',          id: 3},
        {slug:'SCOTIABANK',         value:'SCOTIABANK',        label:'SCOTIABANK',         id: 4},
        {slug:'BANCO PICHINCHA',    value:'BANCO PICHINCHA',   label:'BANCO PICHINCHA',    id: 5},
        {slug:'BANCO FALABELLA',    value:'BANCO FALABELLA',   label:'BANCO FALABELLA',    id: 6},
        {slug:'WESTERN UNION',      value:'WESTERN UNION',     label:'WESTERN UNION',      id: 7},
        {slug:'OTRO',               value:'OTRO',              label:'OTRO',               id: 8},
    ];

    static UserTypes = [
        {slug:'ADMIN',          value:'ADMIN',          label:'Administrador', id: 1},
        {slug:'MANAGER',        value:'MANAGER',        label:'Manager', id: 2},
        {slug:'ASSISTANT',      value:'ASSISTANT',      label:'Asistente', id: 3},
        {slug:'OWNER',          value:'OWNER',          label:'Propietario', id: 4},
        {slug:'BENEFICIARY',    value:'BENEFICIARY',    label:'Beneficiario', id: 5},
        {slug:'SECURITY',       value:'SECURITY',       label:'Seguridad', id: 6},
        {slug:'TREASURER',       value:'TREASURER',       label:'Tesoreria', id: 7},
        {slug:'FIELD_MANAGER',       value:'FIELD_MANAGER',       label:'Administrador de campo', id: 8},
    ];

    static UserTypesToOwner = [
        {slug:'VISITOR',    value:'VISITOR',    label:'Visitante', id: 5},
        {slug:'EMPLOYEE',       value:'EMPLOYEE',       label:'Empleado', id: 6},
    ];

    static personaType = [
        {slug:'NATURAL',  value:'NATURAL',    label:'Natural',  id: 1},
        {slug:'LEGAL',    value:'LEGAL',      label:'Legal',    id: 2},
        {slug:'OTHER',    value:'OTHER',      label:'Otro',     id: 3},
    ];

    static Activities = [
        {slug: 'ADMIN',     label: 'Crear',         id: 1}, //??
        {slug: 'MANAGER',   label: 'Actualizar',    id: 2}, //??
        {slug: 'ASSISTANT', label: 'Eliminar',      id: 3}, //?? 
    ];

    static ActiveValues = [
        {slug: 'ACTIVE',     value:true,    label: 'Activo',    id: 1}, //??
        {slug: 'INACTIVE',   value:false,    label: 'Inactivo',  id: 2}, //??
    ];

    static typeDocument = [
        {slug:'DNI',        value:'DNI',        label:'DNI',                    id: 2},
        {slug:'CE',         value:'CE',         label:'Carnét de Extranjería',   id: 1},
        {slug:'PASAPORTE', value:'PASAPORTE', label:'Pasaporte', id: 3},
        {slug:'OTHER', value:'OTHER', label:'Otro', id: 3},
    ];

    static typePhones = [
        {slug:'Casa',           value:'HOME',           label:'Casa',       id: 1},
        {slug:'Oficina',        value:'OFFICE',         label:'Oficina',    id: 2},
        {slug:'Móvil',          value:'MOBILE',         label:'Móvil',      id: 3},
        {slug:'Otro',           value:'OTHER',          label:'Otro',       id: 4},
    ];

    static relationship = [
        {slug:'Padre',      value:'Padre',   label:'Padre',   id: 1},
        {slug:'Hijo',       value:'Hijo',    label:'Hijo',    id: 2},
        {slug:'Esposa / Esposo',       value:'Esposa / Esposo',    label:'Esposa / Esposo',    id: 4},
        {slug:'Conviviente',       value:'Conviviente',    label:'Conviviente',    id: 5},
        {slug:'Otro',       value:'Otro',    label:'Otro',    id: 3},
    ];

    static paymentRequestStatus = [    
        {slug:'OPENED', value:'OPENED',  label:'Nuevo',      id: 1},
        {slug:'PAID',       value:'PAID',    label:'Pagado',     id: 2},
        {slug:'EXPIRED', value:'EXPIRED', label:'Vencido', id: 3},
        {slug:'DEFAULTED', value:'DEFAULTED', label:'Moroso', id: 4},
        {slug:'PROCESSING', value:'PROCESSING', label:'Procesando', id: 5},
        {slug:'ERROR', value:'ERROR', label:'Error', id: 6},
    ];
}