import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col, Card, CardBody, CardTitle} from "reactstrap";
import {MainContext} from '../../contexts/Main.context';
import { StackedColumnChart } from "components/Helpers/StackedColumnChart";
import ActivityComp from "../../components/Helpers/ActivityComp";
import { Lastest } from "components/Dashboard/Lastest";
import { withTranslation } from "react-i18next";
import Posts from "../../components/UV/Dashboard/Posts";
import {Redirect} from "react-router-dom";

const Dashboard = props => {

  const { backend, formatter, showLoader, isAgent } = useContext(MainContext);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [userType, setUserType] = useState([]);

  const yearData = [{
    name: "Total",
    data: [
      rows.ordinaryFeeIncome ?? 0,
      rows.extraordinaryIncome ?? 0, 
      rows.otherServiceIncome ?? 0, 
      rows.admissionIncome ?? 0, 
      rows.sanctions ?? 0, 
      rows.otherIncome ?? 0
  ]}];

  useEffect(
    () => {pullData();},
    
    [page]
  );
  const pullData = async () => {
    showLoader(true);
      const userData  = backend.getSession();
      const {data, meta} = await backend.getDashboardAdmin({page});
    if(data){
      setUserType(userData.data.type);
      setRows(data);
    }
    showLoader(false);
  };
  
  return (
    <React.Fragment>
        {backend.getUserType() === 'SECURITY'  ? <>
            <Redirect to={{ pathname: "/users"}}/>
        </> : <></>}
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Condominio la raya</title>
        </MetaTags>
        <Container fluid>
          <Row className="align-items-center">
						<Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Dashboard </h4> </Col>
						<Col xs={12} md={6}>
							<Row className="d-flex justify-content-end">
								<Col xs={12} md={4} className=""></Col>
								<Col xs={12} md={4} className=""></Col>
							</Row>
						</Col>
					</Row>          
          <Row>
            <Col xl="12">
              {/* Cartas de reporte  */}
              <div className="report-cards-container">
                {/*<ReportCards />*/}
                <Row>
                  <Col xxl={3} md={6} xs={12}>
                    <Card className="hover" color="">
                      <CardBody>
                        <CardTitle className="mb-4">
                          <i className="bx bx-trending-up me-3 mt-2" />
                          Ingresos del Mes
                        </CardTitle>
                        <div>
                          <h5 className="mb-4">
                            {formatter.amount(rows.ingresos)}
                          </h5>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xxl={3} md={6} xs={12}>
                    <Card className="hover" color="">
                      <CardBody>
                        <CardTitle className="mb-4">
                          <i className="bx bx-trending-down me-3 mt-2" />
                          Egresos del Mes
                        </CardTitle>
                        <div>
                          <h5 className="mb-4">
                            {formatter.amount(rows.egresos)}
                          </h5>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xxl={3} md={6} xs={12}>
                    <Card className="hover" color="" style={{backgroundColor: '#FDDC3F'}}>
                      <CardBody>
                        <CardTitle className="mb-4">
                          <i className="mdi mdi-alert-outline m-3 " />
                          Deudas Vencidas:
                        </CardTitle>
                        <div>
                          <h5 className="mb-1">Propietarios: {rows.propDeudasVencidas}</h5>
                          <h5 className="mb-1">Total en Deudas: {formatter.amount(rows.montoDeudasVencidas)}</h5>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xxl={3} md={6} xs={12}>
                    <Card className="hover" color="" style={{backgroundColor: '#F46A6A'}}>
                      <CardBody>
                        <CardTitle className="mb-4">
                          <i className="bx bx-alarm-off me-3" />
                          Deudas Morosas:
                        </CardTitle>
                        <div>
                          <h5 className="mb-1">Propietarios: {rows.propDeudasMorosas}</h5>
                          <h5 className="mb-1">Total en Deudas: {formatter.amount(rows.montoDeudasMorosas)}</h5>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <Col className="d-flex justify-content-between flex-wrap row">
                <Col xxl="3" md="6" xs="12">
                  {/*<MonthlyEarning />*/}
                  <Col>
                    <Posts data={rows.ultBoletines}/>
                  </Col>
                </Col> 
                <Col xxl="3" md="6" xs="12" >
                  <ActivityComp activity={rows.actividad}/>
                </Col>
                <Col xxl="6" xs="12">          
                  <Card className="flex-column d-flex">
                    <CardBody>
                      <CardTitle className=" pb-3 bg-transparent border-bottom">Ingresos por tipo de pago del mes</CardTitle>
                      <StackedColumnChart periodData={yearData} admin={ userType === "ADMIN" ? true : false }/>
                    </CardBody>
                  </Card>
                </Col>
              </Col>
              <Lastest data={rows.ultimosPagos} admin={ userType === "ADMIN" ? true : false }/>
            </Col>
          </Row>
        </Container> 
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

  export default withTranslation()(Dashboard);