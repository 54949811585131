import React, {createContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {FormatterService} from '../services/formatter.service';
import {BackendService} from '../services/backend.service';
import {Spinner, Toast, ToastBody, ToastHeader} from 'reactstrap';

import "react-datepicker/dist/react-datepicker.css"

export const MainContext = createContext(null);

export const MainProvider = ({children}) => {
    const formatter = new FormatterService();
    const backend = new BackendService();
    const [isLoggedIn, setLoggedIn] = useState(backend.isLoggedIn());
    const [isAgent, setIsAdmin] = useState(backend.isAdmin());
    const [userType, setUserType] = useState(backend.getUserType());
    const [loader, showLoader] = useState(false);
    const [toast, setToast] = useState({});

    const changeHandler = async ({target, ...ev}, form, setter, secure = false) => {
        if(!target.name) return;
        const _form = { ...form };
        switch (target.type){
            case 'date':
                _form[target.name] = formatter.dateToTimestamp(target.value);
                break;
            case 'file':
                showLoader(true);
                _form[target.name] = secure ? await backend.uploadProtectedFile(target.files[0]) : await backend.uploadFile(target.files[0]);
                showLoader(false);
                break;
            default:
            _form[target.name] = target.value;
        }
        setter(_form);
    };

    const toastAlert = ({header, body, color = 'primary'}) => {
        setToast({header, body, color});
        setTimeout(()=>{
            setToast({});
        },1000)
    };

    const exports = {
        backend, 
        formatter,
        isLoggedIn,
        setLoggedIn,
        isAgent,
        setIsAdmin,
        loader,
        showLoader,
        userType,
        setUserType,
        changeHandler,
        toastAlert
    };

    return (
        <MainContext.Provider value={exports}>
            <div id="main-loader" className={`main-loader ${loader ? 'active' : ''}`}>
                {children}
                <div className="main-loader-spinner-cont">
                    <Spinner color="primary" size="50">
                        Cargando...
                    </Spinner>
                </div>
                {toast ? (<>
                    <Toast className={`main-toast ${toast.color ? `bg-${toast.color}` : ''}`} >
                        {toast.header ? (
                          <ToastHeader>
                              {toast.header}
                          </ToastHeader>
                        ) : (<></>)}
                        {toast.body ? (
                          <ToastBody>
                              {toast.body}
                          </ToastBody>
                        ) : (<></>)}
                    </Toast>
                </>):(<></>)}
            </div>
        </MainContext.Provider>
    );
};

MainProvider.propTypes = {
    children: PropTypes.element.isRequired
};