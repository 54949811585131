import React from 'react';
import Select from "react-select";
import {Pagination, PaginationItem, PaginationLink, Row, Col} from "reactstrap";
import PropTypes, { array } from "prop-types";

export const CustomPagination = ({currentPage, setPage, totalPages}) => {

    const options = (cuant) => {
        const arr = [];
        [...Array(cuant)].map((el, i) => arr.push({id: i+1, value: i+1, label: i+1}) && i+1);
        return arr;
     }

    const changeThePage = (page) => {
        setPage(page);
    };
    return (
      <>
      {totalPages === 1 || !totalPages ? <></> : <>
            {/* <Col xs={12}> */}
                <Pagination className='w-100'>
                    <Col className='d-flex justify-content-center' xs={2} md={1}>
                        <PaginationItem disabled={currentPage <= 1} >
                            <PaginationLink onClick={()=>{changeThePage(currentPage - 1)}}
                                previous
                            />
                        </PaginationItem>
                    </Col>
                    <Col className='d-flex justify-content-center' xs={8} md={3} lg={2}>
                        <Select
                            className='w-100' 
                            isMulti={false}
                            placeholder={currentPage}
                            options={ options(totalPages) } 
                            onChange={(selected)=> {changeThePage(selected.value)}}
                        />
                    </Col>
                    <Col className='d-flex justify-content-center' xs={2} md={1}>
                        <PaginationItem disabled={currentPage >= totalPages}>
                            <PaginationLink
                                onClick={()=>{changeThePage(currentPage + 1)}}
                                next
                                />
                        </PaginationItem>
                    </Col>
                </Pagination>
            {/* </Col> */}
          </>}
      </>
    );
};

CustomPagination.propTypes = {
    currentPage: PropTypes.number,
    setPage: PropTypes.func,
    totalPages: PropTypes.number,
}
