import React            from "react"
import { Redirect }     from "react-router-dom"

// Profile
import UserProfile      from "../pages/Authentication/user-profile"

// Import
import  Users           from "../pages/Users/index"
import  Login           from "../pages/Authentication/Login"
import  Logout          from "../pages/Authentication/Logout"
import  Meetings        from "../pages/Meetings/index"
import  Payments        from "../pages/Payments/index"
import  Newsletter      from "../pages/Newsletter/index"
import  Activities      from "../pages/Activities/index"
import  Representative  from "../pages/Representative/index"
import  Transactions    from "../pages/Transactions/index"
import  Settings        from "../pages/Configuration/index"
import  ForgetPwd       from "../pages/Authentication/ForgetPassword"
import  CreatePost      from "../pages/Newsletter/CreatePost"
import  EditPost        from "../pages/Newsletter/EditPost"
import {Files}          from "../pages/Files"
import { Lots }         from "../pages/Lots"
import { Comments }     from "../pages/comments"

// Import User View Pages
import  UvActivities    from "../pages/Activities/uv-activities"
import  { UvMeetings }      from "../pages/Meetings/uv-meetings"
import  UvNewsletter    from "../pages/Newsletter/uv-newsletter"
import  UvPayments      from "../pages/Payments/uv-payments"
import  UvUsers         from "../pages/Users/uv-users/uv-users"
import  NewsDetails     from "../pages/Newsletter/uv-newsDetails"
import  UvProfile       from "../pages/Authentication/uv-profile"
import  UvTransactions  from "../pages/Transactions/uv-transactions"

// pages
import Dashboard        from "../pages/Dashboard/index"
import UvDashboard      from "../pages/Dashboard/uv-dasboard"
import {UvFiles}        from "../pages/Files/uv-Files";

export const ownerRoute = [
    { path: "/uv-dashboard",           component: UvDashboard },
    { path: "/uv-activities",          component: UvActivities },
    { path: "/uv-meetings",            component: UvMeetings },
    { path: "/uv-newsletter",          component: UvNewsletter },
    { path: "/uv-newsletter/:id",      component: NewsDetails },
    { path: "/uv-payments",            component: UvPayments },
    { path: "/uv-users",               component: UvUsers },
    { path: "/uv-files",               component: UvFiles },
    { path: "/uv-transactions",        component: UvTransactions },
    { path: "/uv-profile",             component: UvProfile },
];

export const agentRoute = [
    { path: "/representative",              component: Representative },
    { path: "/dashboard",                   component: Dashboard },
    { path: "/activities",                  component: Activities },
    { path: "/profile",                     component: UserProfile },
    { path: "/settings",                    component: Settings },
    { path: "/lots",                        component: Lots },
    { path: "/comments",                    component: Comments },
    { path: "/meetings",                    component: Meetings },
    { path: "/newsletter",                  component: Newsletter },
    { path: "/newsletter/create-post",      component: CreatePost },
    { path: "/newsletter/edit-post/:id",    component: EditPost },
    { path: "/files",                       component: Files },
    { path: "/payments",                    component: Payments },
    { path: "/transactions",                component: Transactions },
    { path: "/users",                       component: Users },
    { path: "/", exact: true,               component: () => <Redirect to="/dashboard" /> },
];

export const publicRoutes = [
    { path: "/logout",           component: Logout },
    { path: "/login",            component: Login },
    { path: "/forgot-password",  component: ForgetPwd },
]

