import React from "react"
import PropTypes from "prop-types";

//Utils
import { Link } from "react-router-dom";
//Components
import { Row, Col, Card, CardBody } from "reactstrap"
//Images
import noProfileImg from '../../../assets/images/users/user.jpg';

function CardWelcome({lote, ProfileImg}) {
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary px-2">
                <h3 className="my-4 p-1">Bienvenido</h3>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="7">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={ProfileImg ? ProfileImg : noProfileImg}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">Dueño del lote: {lote ? lote : ''}</h5>
              <p className="text-muted mb-0 text-truncate"></p>
              <div className="mt-4">
              </div>
            </Col>
            <Col sm="5" className="d-flex justify-content-end">
              <div className="pt-4 align-self-center">
                <Link to="/uv-profile" className="btn btn-primary  btn">
                  Ver Perfil <i className="mdi mdi-arrow-right ms-1"/>
                </Link>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
CardWelcome.propTypes = {
  name: PropTypes.any,
  lote: PropTypes.any,
  ProfileImg: PropTypes.any,
};

export default CardWelcome;