export class BackendService {

  token;
  apiUrl;
  isOwner;
  lsItemName;
  backendUrl;
  singleapiUrl;
  defaultValues;

  constructor() {
    this.backendUrl = process.env.REACT_APP_API_URL;
    this.apiUrl = `${process.env.REACT_APP_API_URL}/api`;
    this.singleapiUrl = `${process.env.REACT_APP_API_URL}`;
    this.lsItemName = 'cld_session';
    this.loginVerfTimeOut = false;
    this.defaultValues = {
      limit: 40,
      where: {},
      page: 0,
    };
  }

  logOut(){
    this.deleteSession();
    if(this.loginVerfTimeOut){
      clearTimeout(this.loginVerfTimeOut);
    }
    window.location.href = '/login';
  }

  runVerification(){
    this.loginVerfTimeOut = setInterval(()=>{
      this.verifyJWT();
    },60000);
  }

  async verifyJWT(){
    try{
      const resp = await fetch(`${this.backendUrl}/auth/jwt`, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`
        }
      });
      if(resp){
        const json = await resp.json();
        if(json.status){
          return true;
        }
      }
      this.logOut();
    }catch (e){
      this.logOut();
      console.warn('Auto logout');
    }
  }

  getQueryParams(where, limit, order, page, attributes, orderPr){
    let finalWhere = '';
    limit = limit ?? this.defaultValues.limit;
    order = order ? JSON.stringify(order) : `["status", "desc"]`;
    where = where ?? this.defaultValues.where;
    orderPr = orderPr ? JSON.stringify(orderPr) : `["status", "desc"]`;
    if(where){
      for (const whereKey in where) {
        if(where[whereKey] !== '') finalWhere += `&where[${whereKey}]=${where[whereKey]}`
      }
    }
    page = page ? page - 1 : this.defaultValues.page;
    attributes = attributes ? `&attributes=${JSON.stringify(attributes)}` : '';
    return `?limit=${limit}&page=${page}${finalWhere}&order=${order}${attributes}`;
  }


  async login({email, password}){
    try{
      const resp = await fetch(`${this.backendUrl}/auth/login`,{
        method: 'POST',
        body: JSON.stringify({email, password}),
        headers: {'Content-type':'application/json'}
      });
      const {status, token, sessionData} = await resp.json();
      if(status){
        this.saveSession({token, data: sessionData});
        return true;
      }else{
        return false;
      }
    }catch (e){console.warn(e); return false;}
  }

  getUserType(){
    const session = this.getSession();
    if(session.data){
      return session.data.type;
    }
    return ''
  }

  isAdmin(){
    const session = this.getSession();
    return session ? session.data.type !== 'OWNER' : false;
  }

  isLoggedIn(){
    const session = this.getSession();
    if(session){this.runVerification();}
    return !!session;
  }

  getToken(){
    const session = this.getSession();
    if(session){
      return session.token;
    }
  }

  saveSession(session){
    window.localStorage.setItem(this.lsItemName, JSON.stringify(session))
  }

  getSession(){
    const session = window.localStorage.getItem(this.lsItemName);
    if(session)return JSON.parse(session);
    return false;
  }

  deleteSession(){
    window.localStorage.removeItem(this.lsItemName);
  }


  //options
  async getOptions(){
    const requestOptions = {
      method: 'GET',
      headers: {Authorization: `Bearer ${this.getToken()}`},
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/options/`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };
  async updateOptions(_data){
    const requestOptions = {
      method: 'POST', headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
      redirect: 'follow', body: JSON.stringify(_data),
    };
    console.log(JSON.stringify(_data));
    const resp = await fetch(`${this.apiUrl}/options/update-many/`, requestOptions);
    const data = await resp.json();
    if(data.status) return data;
    return false;
  };

  //fetchs
  async getLots({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/lands${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  async getComments({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/comments${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };


  async createComment(body){
    const resp = await fetch(`${this.apiUrl}/comments`,{
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  };

  async getLot(id){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/lands/${id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  async updateLot(id, body){
    const resp = await fetch(`${this.apiUrl}/lands/${id}`, {
      method: 'PUT', mode: 'cors', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  };

  async getDashboardAdmin({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/dashboard/admin${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  async getDashboardOwner({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/dashboard/owner${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  async getUsers({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/users${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  async getUserOfLands(id){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/users/land/${id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  async getUser(id){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/users/${id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  async newUser(body){
    const resp = await fetch(`${this.apiUrl}/users`,{
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  };

  async newUserBenef(body){
    const resp = await fetch(`${this.apiUrl}/users/beneficiary`,{
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  };

  async updateUser(id, body){
    const resp = await fetch(`${this.apiUrl}/users/${id}`, {
      method: 'PUT', mode: 'cors', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async deleteUser(id){
    const resp = await fetch(`${this.apiUrl}/users/${id}`, {
      method: 'DELETE', redirect: 'follow',
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async getTransactions({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.singleapiUrl}/transactions/include-payments${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async newTransaction(body){
    const resp = await fetch(`${this.apiUrl}/transactions`,{
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async getTransaction(id){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/transactions/${id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  async updateTransaction(id, body){
    const resp = await fetch(`${this.apiUrl}/transactions/${id}`, {
      method: 'PUT', mode: 'cors', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async deleteTransaction(id){
    const resp = await fetch(`${this.apiUrl}/transactions/${id}`, {
      method: 'DELETE', redirect: 'follow',
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async getGuests({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/users/visitor${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async newGuest(body){
    const resp = await fetch(`${this.apiUrl}/users/visitor`,{
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async getGuest(id){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/guests/${id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  async getPhoneData(id){
    const requestOptions = {method: 'GET', headers: {Authorization: `Bearer ${this.getToken()}`}, redirect: 'follow'};
    const resp = await fetch(`${this.apiUrl}/phones/?where{id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async newPhone(body){
    const resp = await fetch(`${this.apiUrl}/phones`,{
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async updatePhone(id, body){
    const resp = await fetch(`${this.apiUrl}/phones/${id}`, {
      method: 'PUT', mode: 'cors', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async deletePhone(id){
    const resp = await fetch(`${this.apiUrl}/phones/${id}`, {
      method: 'DELETE', redirect: 'follow',
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async getRepresent({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/representative/${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async getStatus({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/account-status/1`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async updateStatus(body){
    const resp = await fetch(`${this.apiUrl}/account-status/1`, {
      method: 'PUT', mode: 'cors', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async getMeetings({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/meetings${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;

  }

  //Crear meeting
  async newMeeting(body){
    const resp = await fetch(`${this.apiUrl}/meetings`,{
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  //Obtener meeting
  async getMeeting(id){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/meetings/${id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  //Actualizar meeting
  async updateMeeting(id, body){
    const resp = await fetch(`${this.apiUrl}/meetings/${id}`, {
      method: 'PUT', mode: 'cors', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  //Eliminar meeting
  async deleteMeeting(id){
    const resp = await fetch(`${this.apiUrl}/meetings/${id}`, {
      method: 'DELETE', redirect: 'follow',
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async getPaymentRequests({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/payment-requests${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async updatePaymentRequests(id, body){
    const resp = await fetch(`${this.apiUrl}/payment-requests/${id}`, {
      method: 'PUT', mode: 'cors', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async getFiles({where, limit, order, page, attributes} = {}){
    console.log({where, limit, order, page, attributes})
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/documents${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async getNotif({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/notification${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async getNews({where, limit, order, page, attributes} = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/news${this.getQueryParams(where, limit, order, page, attributes)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async getNewsletter( id = {}){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/news/${id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  //new
  async newNewsletter(body){
    const resp = await fetch(`${this.apiUrl}/news/`, {
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false
  };

  //Eliminar new
  async deleteNew(id){
    const resp = await fetch(`${this.apiUrl}/news/${id}`, {
      method: 'DELETE', redirect: 'follow',
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async updateNew(id, body){
    const resp = await fetch(`${this.apiUrl}/news/${id}`, {
      method: 'PUT', mode: 'cors', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async getServiceExpenses(id){
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.getToken()}`
      },
      redirect: 'follow'
    };
    const resp = await fetch(`${this.apiUrl}/service-expenses/last/${id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  };

  //Pago
  async newPayment(body){
    const resp = await fetch(`${this.apiUrl}/payments/external`, {
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  //
  async paymentUpdate(id, body){
    console.log(id)
    console.log(body)
    const resp = await fetch(`${this.apiUrl}/payments/external/${id}`, {
      method: 'PUT', mode: 'cors', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  //
  async serviceExpensesUpdate(id, body){
    console.log(id)
    console.log(body)
    const resp = await fetch(`${this.apiUrl}/service-expenses/${id}`, {
      method: 'PUT', mode: 'cors', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  //Crear pago
  async newPaymentRequest(body){
    const resp = await fetch(`${this.apiUrl}/payment-requests`, {
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  //
  async newUserServicePaymentRequest(body){
    const resp = await fetch(`${this.apiUrl}/payment-requests/service`, {
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  //
  async newUserPaymentRequestDelete(id){
    const resp = await fetch(`${this.apiUrl}/payment-requests/${id}`, {
      method: 'DELETE', redirect: 'follow',
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  //
  async newUserPaymentRequestUpdate(id, body){
    const resp = await fetch(`${this.apiUrl}/payment-requests/${id}`, {
      method: 'PUT', mode: 'cors', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  //
  async newtransaction(body){
    const resp = await fetch(`${this.apiUrl}/transactions`, {
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async uploadFile(file){
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await fetch(`${this.backendUrl}/upload`, {
      method: 'POST',
      body: formData,
    });
    const {data, status} = await response.json();
    if(status){
      return data.fileUrl;
    }
    return false;
  }

  async uploadProtectedFile(file){
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await fetch(`${this.backendUrl}/upload/secure`, {
      method: 'POST',
      body: formData,
    });
    const {data, status} = await response.json();
    if(status){
      return data.filename;
    }
    return false;
  }

  async getProtectedImageUrl(filename){
    try{
      const resp = await fetch(`${this.apiUrl}/protected-files?filename=${filename}`,{
        headers: {Authorization: `Bearer ${this.getToken()}`}, method: 'GET'
      });
      return await resp.json();
    }catch (e) {return {status: false, message: 'Unexpected Error'};}
  }

  //obtener solo un payment
  async getPayment(id){
    const requestOptions = {method: 'GET', headers: {Authorization: `Bearer ${this.getToken()}`}, redirect: 'follow'};
    const resp = await fetch(`${this.apiUrl}/payment-requests/${id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async getUserData(id){
    const requestOptions = {method: 'GET', headers: {Authorization: `Bearer ${this.getToken()}`}, redirect: 'follow'};
    const resp = await fetch(`${this.apiUrl}/users/?where{id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async getProfileData(id){
    const requestOptions = {method: 'GET', headers: {Authorization: `Bearer ${this.getToken()}`}, redirect: 'follow'};
    const resp = await fetch(`${this.apiUrl}/users/land/?where{id}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return false;
  }

  async getCsvFromPath(path, {where, order, page, attributes}){
    try{
      const resp = await fetch(`${this.apiUrl}${path}${this.getQueryParams(where, -1, order, page, attributes)}`,{
        headers: {Authorization: `Bearer ${this.getToken()}`}, method: 'GET'
      });
      const blob = await resp.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }catch (e) {return {status: false, message: 'Unexpected Error'};}
  };

  async uploadDocument(body){
    console.log('body-front',body)
    const resp = await fetch(`${this.apiUrl}/documents/`, {
      method: 'POST', redirect: 'follow', body: JSON.stringify(body),
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async getActivities({page, order, where}){
    const requestOptions = {method: 'GET', headers: {Authorization: `Bearer ${this.getToken()}`}, redirect: 'follow'};
    const resp = await fetch(`${this.apiUrl}/blame/${this.getQueryParams(where, 60, order, page)}`, requestOptions);
    const data = await resp.json();
    if(data.status){
      return data;
    }
    return {status: false, };
  }

  async deleteDocument(id = {}){
    const resp = await fetch(`${this.apiUrl}/documents/${id}`, {
      method: 'DELETE', redirect: 'follow',
      headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
    });
    const {data, status} = await resp.json();
    if(status){
      return {data, status};
    }
    return false;
  }

  async bulkInsertServices(form){
    try{
      const resp = await fetch(`${this.apiUrl}/payment-requests/services`, {
        method: 'POST', redirect: 'follow', body: JSON.stringify(form),
        headers: {Authorization: `Bearer ${this.getToken()}`, 'Content-Type': 'application/json'},
      });
      const {data, status} = await resp.json();
      if(status){
        return {data, status};
      }
      return false;
    }catch (e){return false;}
  }
}
