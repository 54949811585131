import Slider from "react-rangeslider"
import MetaTags from 'react-meta-tags';
import React, {useContext, useEffect, useState} from "react"
import "react-rangeslider/lib/index.css"
import { TableWrapper } from "components/Helpers/TableWrapper";
import {Container, Card, CardBody, Label, Col, Row, Input} from "reactstrap"
import "pages/Configuration/configuration.scss";
import {MainContext} from "../../contexts/Main.context";

const Settings = () => {
    const [slide, setSlide] = useState(10)
    const defaultForm = {
        MONTHLY_FEES: '',
        DAYS_TO_PAY: '',
        BASE_FEE: '',
        INTEREST_PER_DEFAULT: '',
        IGV: '',
        MONTHLY_QUOTA: '',
        PAYMENT_PERIOD: '',
    };
    const {backend, showLoader, toastAlert, changeHandler} = useContext(MainContext);
    const [form, setForm] = useState(defaultForm);

    useEffect(()=>{
        getData();
    },[])

    const getData = async () => {
        const resp = await backend.getOptions();
        if(resp.data){
            const finalOptions = {
                MONTHLY_FEES: '',
                DAYS_TO_PAY: '',
                BASE_FEE: '',
                INTEREST_PER_DEFAULT: '',
                IGV: '',
                MONTHLY_QUOTA: '',
                PAYMENT_PERIOD: '',
            };
            resp.data.forEach((opt)=>{
                finalOptions[opt.slug] = opt.type === "NUMBER" ? parseFloat(opt.value) : opt.value;
            });
            setForm(finalOptions);
        }
    };

    const updateData = async () => {
        showLoader(true);
        const list = []
        Object.keys(form).forEach(el => {
            list.push( {slug: el, value: form[el]});
        });
        await backend.updateOptions({list});
        toastAlert({
            body: 'Actualizado con exito',
        })
        await getData();
        showLoader(false);
    };

    const onFieldChange = ({target, ...ev}) => {
        const _form = {...form};
        _form[target.name] = target.value;
        setForm(_form);
    };

    return (
        <>
            <div id="payments">
                <div className="page-content">
                    <MetaTags>
                        <title>Configuración | Condominio la raya</title>
                    </MetaTags>
                    <Container fluid>
                        <div className="d-flex mb-4 d-wrap">
                            <Row className="col-xs-12 col-sm-6 col-lg-7 col-xl-7">
                                <div className="justify-content-center">
                                    <h4>Configuración</h4>
                                </div>
                            </Row>
                        </div>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <form>
                                        <TableWrapper headers={[]}>
                                            <tr>
                                                <td>Cuota ordinaria</td>
                                                <td>
                                                    <div className="checkbox-style">
                                                        <Input name="MONTHLY_QUOTA" onChange={onFieldChange} value={form.MONTHLY_QUOTA}
                                                            type="text" className="w-50" id="" autoComplete="off"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Tiempo de Gracia</td>
                                                <td>
                                                    <div className="checkbox-style">
                                                        <Input name="PAYMENT_PERIOD"
                                                               defaultValue={form.PAYMENT_PERIOD}
                                                               type="select" className="w-50" id="" autoComplete="off"
                                                               onChange={onFieldChange}>
                                                            <option value="MONTHLY">Al mes siguiente</option>
                                                            <option value="DAILY">Despúes de algunos dias</option>
                                                        </Input>
                                                    </div>
                                                </td>
                                            </tr>
                                            {form.PAYMENT_PERIOD === 'DAILY' ?
                                                <tr>
                                                    <td>Días de Gracia</td>
                                                    <td>
                                                        <div className="checkbox-style">
                                                            <Input name="DAYS_TO_PAY" value={form.DAYS_TO_PAY}
                                                                   onChange={onFieldChange}
                                                                   type="text" className="w-50" id="" autoComplete="off"/>
                                                        </div>

                                                    </td>
                                                </tr> : "" }
                                        {/*    <tr>
                                                <td>Tarifa Base</td>
                                                <td>
                                                    <div className="checkbox-style">
                                                        <Input name="BASE_FEE"
                                                            type="text" className="w-50" id="" autoComplete="off"/>
                                                    </div>

                                                </td>
                                            </tr>*/}
                                            <tr>
                                                <td>Intereses (%)</td>
                                                <td>
                                                    <div className="checkbox-style">
                                                        <Input name="INTEREST_PER_DEFAULT" value={form.INTEREST_PER_DEFAULT}
                                                               onChange={onFieldChange} type="text" className="w-50" id="" autoComplete="off"/>
                                                    </div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>IGV (%)</td>
                                                <td>
                                                    <div className="checkbox-style">
                                                        <Input name="IGV" value={form.IGV} onChange={onFieldChange}
                                                            type="text" className="w-50" id="" autoComplete="off"/>
                                                    </div>

                                                </td>
                                            </tr>
                                        </TableWrapper>
                                    </form>
                                </div>

                                <Row className="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
                                    <div className="nav nav-tabs-custom  ms-auto justify-content-end">
                                        <button type="button" className="btn btn-outline-danger" onClick={()=>{getData();}}>
                                            Restaurar Valores
                                        </button>
                                        <button type="button2" className="btn btn-success mx-2" onClick={updateData}>
                                            Guardar cambios
                                        </button>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default Settings;