import React, { useContext, useState, useEffect } from "react"
import Select from "react-select"
import { Create } from "components/Users/create"
import { Delete } from "components/Users/delete"
import { Update } from "components/Users/update"
import { EnumService } from "services/enum.service"
import { PhoneViews } from "components/Users/uv-phone"
import { MainContext } from "../../contexts/Main.context"
import { TableWrapper } from "components/Helpers/TableWrapper"
import { CustomPagination } from "components/Common/CustomPagination"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

const Users = () => {

  const defaultFilter = {
    id: "",
    name: "",
    type: "",
    land_id: "",
    persona_type: "",
  }

  const [id, setId] = useState([{}])
  const [lot, setLots] = useState([])
  const [page, setPage] = useState(1)
  const [rows, setRows] = useState([])
  const [user, setUser] = useState({})
  const [_phones, setPhones] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [filter, setFilter] = useState(defaultFilter);
  const { backend, formatter } = useContext(MainContext)
  const [modals, setModals] = useState({ create: false, update: false, delete: false, phones: false })
  
  //get
  useEffect(() => {
    pullId()
    pullLots()
    pullData()
  }, [page])

  const pullData = async () => {
    const userType = await backend.getUserType();
    if ( userType === "SECURITY") { filter.type = ["BENEFICIARY", "OWNER", "VISITOR", "EMPLOYEE"]; filter.active = 1 }
    const { data, meta } = await backend.getUsers({ page, where: filter })
    if (data) { setRows(data); setTotalPages(meta.lastPage)}
  }
  const pullLots = async () => {
    const { data } = await backend.getLots({ page, limit: 999, attributes: ["lot", "slug", "id"] })
    if (data) {
      setLots(data)
    }
  }
  const pullId = async () => {
    const { data } = await backend.getUsers({ page, limit: 999, attributes: ["id", "completeName", "name", "surname"] })
    if (data) {
      setId(data)
    }
  }

  const lotsOptions = (withAll = true, array) => {
    const _options = withAll ? [{ id: "All", value: "All", label: "Todos" }] : []
    array.map(el => _options.push({ id: el.id, value: el.id, label: el.slug }))
    return _options
  }

  const options = (array, id) => {
    const options = [{ id: "All", value: "", label: "todos" }]
    array.map(el =>
      options.push({ id: el.id, value: id ? el.id : el.name, label: id ? el.id : el.name + " " + el.surname })
    )
    return options
  }

  const toggleModal = modal => {
    const mdl = { ...modals }
    mdl[modal] = !mdl[modal]
    setModals(mdl)
  }

  const getLot = el => {
    switch (el.type) {
      case "BENEFICIARY":
        return el.land ? el.land.slug : ""
        break
      case "VISITOR":
        return el.land ? el.land.slug : ""
        break
      case "EMPLOYEE":
        return el.land ? el.land.slug : ""
        break
      case "OWNER":
        return el.lots ? el.lots.map(lot => lot.slug).join() : ""
        break
    }
  }

  const columns = [
    { name: "#" },
    { name: "Tipo de usuario" },
    { name: "Parentesco" },
    { name: "Lote" },
    { name: "Nombre" },
    { name: "Apellido" },
    { name: "Correo" },
    { name: "Estado" },
    { name: "Fecha de ingreso"},
    { name: "Fecha de salida"},
    { name: "" },
  ]

  return (
    <React.Fragment>
      { modals.create ? ( <Create active={modals.create} toggle={toggleModal} /> ) : ( <></> )}
      { modals.delete ? ( <Delete active={modals.delete} user={user} toggle={toggleModal} /> ) : ( <></> )}
      { modals.update ? ( <Update active={modals.update} user={user} toggle={toggleModal} /> ) : ( <></> )}
      { modals.phones ? ( <PhoneViews active={modals.phones} user={user} toggle={toggleModal} phones={_phones}/> ) : ( <></> )}

      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="d-flex justify-content-start mb-4">
              {" "}
              <h4> Usuarios </h4>{" "}
            </Col>
            <Col xs={12} md={6}>
              <Row className="d-flex justify-content-end">
                <Col
                  xs={12}
                  md={4}
                  className="d-flex justify-content-end mb-4"
                ></Col>
                <Col xs={12} md={4} className="mb-4">
                  { backend.getUserType() === "SECURITY" ? ( <></> ) : (
                    <Button
                      type="button"
                      color="success"
                      className="w-100"
                      onClick={() => { toggleModal("create") }}
                    >
                      Crear
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Row className="mb-4 d-flex align-items-center">
                { backend.getUserType() === "SECURITY" ? ( <></> ) : (
                  <>
                    <Col xs={12} md={2} className="mt-3 mb-0">
                      <Label>Tipo de persona:</Label>
                      <Select
                        isMulti={true}
                        options={EnumService.personaType}
                        placeholder="Ingresar..."
                        onChange={selected => {
                          const result = selected.filter(el => el.id == "All")
                          const _form = { ...filter }
                          result[0]
                            ? (_form.persona_type = "")
                            : (_form.persona_type = selected
                                .map(el => el.value)
                                .join())
                          setFilter(_form)
                        }}
                      />
                    </Col>
                    <Col xs={12} md={2} className="mt-3 mb-0">
                      <Label>Tipo de usuario:</Label>
                      <Select
                        isMulti={true}
                        options={EnumService.UserTypes}
                        placeholder="Ingresar..."
                        onChange={selected => {
                          const result = selected.filter(el => el.id == "All")
                          const _form = { ...filter }
                          result[0]
                            ? (_form.type = "")
                            : (_form.type = selected.map(el => el.value).join())
                          setFilter(_form)
                        }}
                      />
                    </Col>
                  </>
                )}
                <Col xs={12} md={2} className="mt-3 mb-0">
                  <Label>Buscar por nombre:</Label>
                  <Select
                    isMulti={true}
                    options={options(id, false)}
                    placeholder="Ingresar..."
                    onChange={selected => {
                      const result = selected.filter(el => el.id == "All")
                      const _form = { ...filter }
                      result[0]
                        ? (_form.name = "")
                        : (_form.name = selected.map(el => el.value).join())
                      setFilter(_form)
                    }}
                  />
                </Col>
                <Col xs={12} md={2} className="mt-3 mb-0">
                  <Label>Lote:</Label>
                  <Select
                    isMulti={false}
                    placeholder="Ingresar..."
                    options={lotsOptions(true, lot)}
                    onChange={selected => {
                      const _form = { ...filter }
                      ;(_form.land_id = selected.id), setFilter(_form)
                    }}
                    // onChange={selected => {
                    //   const result = selected.filter(el => el.value == "All")
                    //   const _form = { ...filter }
                    //   result[0]
                    //     ? (_form.land_id = "")
                    //     : (_form.land_id = selected.map(el => el.value).join())
                    //   setFilter(_form)
                    // }}
                  />
                </Col>
                <Col xs={12} md={2}>
                  <br /> <br />
                  <Button
                    type="button"
                    color="primary"
                    className="w-100"
                    onClick={() => pullData()}
                  >
                    Filtrar
                  </Button>
                </Col>
              </Row>
              <Row>
                <Card>
                  <CardBody>
                    <div className="table-responsive text-nowrap">
                      <TableWrapper headers={columns}>
                        {rows ? (
                          rows.map(el => (
                            <tr key={el.id}>
                              <td className="text-nowrap">{el.id}</td>
                              <td className="text-nowrap">
                                {formatter.typeEnum(el.type)}
                              </td>
                              <td className="text-nowrap">{el.relationship ? el.relationship : ""}</td>
                              <td className="text-wrap" style={{maxWidth: '180px'}}>{getLot(el)}</td>
                              <td className="text-nowrap">{el.name}</td>
                              <td className="text-nowrap">{el.surname}</td>
                              <td className="text-nowrap">{el.email}</td>
                              <td className="text-nowrap"
                                  style={{
                                    color: el.debtStatus === 'Vencido' ? '#FF0000' : '#000'
                                  }}
                              >
                                {el.debtStatus === 'Vencido' ? el.debtStatus : 'Activo'}
                              </td>
                              <td className="text-nowrap">{el.visitDate? el.visitDate.slice(0, 10):''}</td>
                              <td className="text-nowrap">{el.exitDate? el.exitDate.slice(0,10):''}</td>
                              <td scope="Row">
                                {backend.getUserType() === "SECURITY" ? (
                                  <></>
                                ) : (
                                  <>
                                    <Row className="row-buttons-container flex-nowrap float-end">
                                      <Col xs={12} xxl={4} className="my-2">
                                        <Button
                                          type="button"
                                          color={"info"}
                                          data-toggle="modal"
                                          data-target="#myModal"
                                          className="font-size-15 h-100"
                                          onClick={() => {
                                            setUser(el.id)
                                            setPhones(el.phones)
                                            toggleModal("phones")
                                          }}
                                        >
                                          Telefonos
                                        </Button>
                                      </Col>
                                      <Col xs={12} xxl={4} className="my-2">
                                        <Button
                                          type="button"
                                          color={"info"}
                                          outline={true}
                                          data-toggle="modal"
                                          data-target="#myModal"
                                          className="font-size-15 h-100 w-100"
                                          onClick={() => {
                                            setUser(el.id)
                                            toggleModal("update")
                                          }}
                                        >
                                          Detalles
                                        </Button>
                                      </Col>
                                      <Col xs={12} xxl={4} className="my-2">
                                        <Button
                                          type="button"
                                          color="danger"
                                          outline={true}
                                          data-toggle="modal"
                                          className="font-size-15 h-100 w-100"
                                          onClick={() => {
                                            setUser(el.id)
                                            toggleModal("delete")
                                          }}
                                        >
                                          Eliminar
                                        </Button>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </TableWrapper>
                    </div>
                    <Row className="mt-4">
                      <CustomPagination
                        currentPage={page}
                        setPage={setPage}
                        totalPages={totalPages}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Users
