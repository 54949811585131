

import React, {useContext, useEffect, useState} from 'react';
import PropTypes, { bool } from 'prop-types';
import Select from "react-select";
import {MainContext} from '../../contexts/Main.context'
import {Input, Label, Modal, Row, CardTitle, Button, Col} from 'reactstrap';

export const Update = ({active, toggle, lot}) => {    
	
    const defaultForm = {
        landId: '',
        ownerId: '',
        pass: '',
    }

    const {backend, showLoader, changeHandler, toastAlert} = useContext(MainContext);
    const [form, setForm] = useState(defaultForm);
    const [users, setUsers] = useState([]);
    const [def, setDef] = useState({});  

    //get
    useEffect(() => {
            pullData();
        },[]
    );
    const pullData = async () => {
			const resp = await backend.getUsers({limit: 999});
			const info = await backend.getLot(lot);
			if(resp && resp.status){
				const _options = [];
				const owner = info.data.owner
				setDef({value: owner.id, label: owner.completeName ?? owner.name +' '+ owner.surname,})
				resp.data.map((el) => _options.push({
					id: el.id, 
					value: el.id, 
					label: el.completeName ?? el.name +' '+ el.surname,
				}));
			if(info && info.status) {
				const _form = {...form};
				_form.ownerId = info.data.ownerId;
				_form.landId = info.data.id;

				setForm(_form);
			}
				setUsers(_options);
			}
    };

    const submitHandler = () => {
        showLoader(true);
        uploadData()
    };
    const uploadData = async () => {
        const resp = await backend.updateLot(lot,form);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'Creado correctamente correctamente', color: 'primary'});
            toggle('update');
            location.reload();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
    };

    return (
        <Modal 
            size="l" 
            centered
            isOpen={active}
            toggle={() => { toggle('update'); }}>
            <Row>
                <CardTitle className="mt-4 mx-3">
                    Modificar dueño y contraseña
                </CardTitle>
                <Button 
                    onClick={() => {toggle('update');}} 
                    type="button" 
                    className="close"
                    data-dismiss="modal" 
                    aria-label="Close">
                </Button>
            </Row>
						<form>
            <Row className="modal-body">
                <Col xs={12} className="mb-3">
                    <Label>Dueño:</Label>
                    <Select 
                        required
                        isMulti={false}
                        name="personaType"
                        placeholder="Ingresar..."
                        options={users} 
                        value={def}
                        onChange={(selected)=> {
                            const _form = {...form};
                            _form.ownerId = selected.value;
                            setDef(selected);
                            setForm(_form);}}/>
                </Col>
								<Col xs={12} className="mb-3">
                    <Label>Crear nueva contraseña:</Label>
                    <Input 
                        name="pass"
                        type="password"
                        placeholder="*************"
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
								<Col xs={12}>
                    <Button
                        className="btn btn-success w-100 p-2"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={()=> submitHandler()}>
                            Guardar cambios
                    </Button>
                </Col>
            </Row>
						</form>
        </Modal>
    );
};
Update.propTypes = {
    active: PropTypes.bool,
    toggle: PropTypes.func,
    lot: PropTypes.any
};