import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Nav,
  Label,
  FormGroup,
  Input,
  Card,
  Modal,
  Table,
  Button,
  NavItem,
  NavLink,
  TabPane,
  Collapse,
  CardBody,
  CardTitle,
  Container,
  TabContent,
  CardSubtitle,
  Alert,
} from "reactstrap"
import classnames from "classnames"
import "react-image-lightbox/style.css"
import "react-modal-video/scss/modal-video.scss"
import "./printInvoince.scss"
import { MainContext } from "../../../../../contexts/Main.context"
import { EnumService } from "services/enum.service"
import { Single } from "components/Payments/Modals/single"
import { Invoice } from "components/Common/Invoice"

export const ModalDetails = ({ toggle, isOpen, paymentRequest = {} }) => {
  const defaultForm = {
    image: "",
    secondImage: "",
    date: "",
    method: "",
    bank: "",
    nop: "",
    payerNote: "",
  }

  const { backend, formatter, showLoader, changeHandler, toastAlert } =
    useContext(MainContext)

  const defaultPr = {
    land: {
      id: 1,
      deletedAt: null,
      cratedAt: "2022-06-11T17:55:23.000Z",
      updatedAt: null,
      code: "1",
      zone: "O",
      lot: "1",
      slug: "O1",
      ownerId: 1,
      owner_id: 1,
    },
  }
  const [modals, setModals] = useState({ create: false })
  const [image, setImage] = useState({})
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [showInvoice, setShowInvoice] = useState(false)
  const [form, setForm] = useState(defaultForm)
  const [pr, setPayReq] = useState(defaultPr)

  useEffect(() => {
    getPr()
  }, [])
  const getPr = async () => {
    const resp = await backend.getPayment(paymentRequest.id)
    if (resp && resp.status) {
      setPayReq(resp.data)
    }
  }

  const getColorBasedIOnType = type => {
    switch (type) {
      case "WARN":
        return "warning"
      case "ERROR":
        return "danger"
      case "SUCCESS":
        return ""
      default:
        return "light"
    }
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const [col1, setcol1] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
  }

  const toggleModal = modal => {
    const mdl = { ...modals }
    mdl[modal] = !mdl[modal]
    setModals(mdl)
    pullData()
  }

  //Update
  const submitHandler = () => {
    showLoader(true)
    uploadData()
  }
  const uploadData = async () => {
    const resp = await backend.paymentUpdate(pr.payment.id, form)
    showLoader(false)
    if (resp.status) {
      toastAlert({ body: "Actualizado correctamente", color: "primary" })
      // toggle('update');
      // location.reload();
    } else {
      toastAlert({ body: "Error inesperado", color: "warning" })
    }
  }

  console.log(pr)

  return (
    <>
      <Modal
        size="md"
        isOpen={isOpen}
        toggle={() => {
          toggle()
        }}
        centered
      >
        {modals.create ? (
          <Single
            active={modals.create}
            centered
            toggle={toggleModal}
            image={image}
          />
        ) : (
          <></>
        )}
        <div className="modal-header">
          <CardTitle>Orden de pago #{paymentRequest.id}</CardTitle>
          <button
            onClick={() => {
              toggle()
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className=" invoice-content d-flex justify-content-center  m-0">
          <Container>
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1")
                  }}
                  disabled={false}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home" />
                  </span>
                  <span className="d-none d-sm-block">Resumen</span>
                </NavLink>
              </NavItem>
              {pr.service && pr.service.amount ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2")
                    }}
                    disabled={false}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-envelope" />
                    </span>
                    <span className="d-none d-sm-block">Servicio</span>
                  </NavLink>
                </NavItem>
              ) : (
                <></>
              )}
              {pr.payment && pr.payment.id ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3")
                    }}
                    disabled={false}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-cog" />
                    </span>
                    <span className="d-none d-sm-block">Recibo</span>
                  </NavLink>
                </NavItem>
              ) : (
                <></>
              )}
            </Nav>

            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <p className="card-title">Estatus</p>
                          </td>
                          <td>
                            <p>{formatter.typeEnum(pr.status)}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="card-title">Lote</p>
                          </td>
                          <td>
                            <p>{pr.land ? pr.land.slug : <></>}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="card-title">Fecha de creacion</p>
                          </td>
                          <td>
                            <p>{formatter.date(pr.createdAt)}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="card-title">Fecha de Pago</p>
                          </td>
                          <td>
                            <p>{formatter.date(pr.payday)}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="card-title">Monto Base</p>
                          </td>
                          <td>
                            <p>{formatter.amount(pr.baseAmount ?? 0)}</p>
                          </td>
                        </tr>
                        {pr.fees?(<tr>
                          <td>
                            <p className="card-title">Tarifa</p>
                          </td>
                          <td>
                            <p>{formatter.amount(pr.fees ?? 0)}</p>
                          </td>
                        </tr>):(<></>)}
                        {pr.penalties?(<tr>
                          <td>
                            <p className="card-title">Sanciones</p>
                          </td>
                          <td>
                            <p>{formatter.amount(pr.penalties ?? 0)}</p>
                          </td>
                        </tr>) : (<></>)}
                        {pr.interests ? (<tr>
                          <td>
                            <p className="card-title">Intereses</p>
                          </td>
                          <td>
                            <p>{formatter.amount(pr.interests ?? 0)}</p>
                          </td>
                        </tr>) : (<></>)}
                        <tr>
                          <td>
                            <p className="card-title">Total</p>
                          </td>
                          <td>
                            <p>{formatter.amount(pr.baseAmount ? pr.baseAmount+pr.fees+pr.penalties+pr.interests : 0)}</p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    {!Array.isArray(pr.activities) ? (
                      <></>
                    ) : (
                      <Col>
                        <div className="accordion" id="accordion">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className={classnames(
                                  "accordion-button",
                                  "fw-medium",
                                  { collapsed: !col1 }
                                )}
                                type="button"
                                onClick={t_col1}
                                style={{ cursor: "pointer" }}
                              >
                                Historial
                              </button>
                            </h2>

                            <Collapse
                              isOpen={col1}
                              className="accordion-collapse"
                            >
                              <div className="accordion-body">
                                {pr.activities.map(activity => (
                                  <div key={activity.id} className="">
                                    <Alert
                                      color={getColorBasedIOnType(
                                        activity.messageType
                                      )}
                                    >
                                      <b>
                                        {formatter.date(
                                          activity.createdAt,
                                          true
                                        )}
                                        :
                                      </b>
                                      <br />
                                      <b>{activity.name}</b> -{" "}
                                      {activity.description}
                                    </Alert>
                                  </div>
                                ))}
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                {!pr.service ? (
                  <></>
                ) : (
                  <Row>
                    <Col sm="12">
                      <Table>
                        <tbody>
                          <tr>
                            <td>
                              <p className="card-title">Servicio</p>
                            </td>
                            <td>
                              <p>{formatter.typeEnum(pr.type)}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="card-title">Ultima Medición</p>
                            </td>
                            <td>
                              <p>
                                {formatter.typeEnum(pr.service.lastMeasurement)}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="card-title">Medición Actual</p>
                            </td>
                            <td>
                              <p>
                                {formatter.typeEnum(
                                  pr.service.currentMeasurement
                                )}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="card-title">Día de la Medición</p>
                            </td>
                            <td>
                              <p>
                                {formatter.date(pr.service.measurementDate)}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="card-title">Precio por Unidad:</p>
                            </td>
                            <td>
                              <p>{formatter.amount(pr.service.pricePerUnit)}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="card-title">Monto a Pagar</p>
                            </td>
                            <td>
                              <p>{formatter.amount(pr.service.amount)}</p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-center ">
                        <Row>
                          <Col xs={12} md={4}>
                            <Card
                              onClick={() => {
                                setImage(pr.service.latestMeasurementImage)
                                toggleModal("create")
                              }}
                            >
                              <CardBody>
                                <CardTitle>Medidor (Mes pasado)</CardTitle>
                                <CardSubtitle className="mb-3">
                                  Haga click para ampliar
                                </CardSubtitle>
                                <img
                                  src={pr.service.latestMeasurementImage}
                                  alt=""
                                  width="100%"
                                />
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs={12} md={4}>
                            <Card
                              onClick={() => {
                                setImage(pr.service.currentMeasurementImage)
                                toggleModal("create")
                              }}
                            >
                              <CardBody>
                                <CardTitle>Medidor (Este mes)</CardTitle>
                                <CardSubtitle className="mb-3">
                                  Haga click para ampliar
                                </CardSubtitle>
                                <img
                                  src={pr.service.currentMeasurementImage}
                                  alt=""
                                  width="100%"
                                />
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs={12} md={4}>
                            <Card
                              // onClick={() => {
                              //   setImage(pr.service.billImage)
                              //   toggleModal("create")
                              // }}
                            >
                              <CardBody>
                                <CardTitle>Recibo de servicio</CardTitle>
                                {/*<CardSubtitle className="mb-3">*/}
                                {/*  Haga click para ampliar*/}
                                {/*</CardSubtitle>*/}
                                {/*<img*/}
                                {/*  src={pr.service.billImage}*/}
                                {/*  alt=""*/}
                                {/*  width="100%"*/}
                                {/*/>*/}
                                <Button
                                    type="button"
                                    color={'success'}
                                    className="w-100"
                                    style={{ fontSize: '12px', marginTop: '20px'}}
                                    onClick={() => {window.open(pr.service.billImage);}}>
                                  Descargar recibo
                                </Button>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )}
              </TabPane>
              <TabPane tabId="3">
                {!pr.payment ? (
                  <></>
                ) : (
                  <Row>
                    <Col sm="12">
                      <Table>
                        <tbody>
                          <tr>
                            <td>
                              <p className="card-title">Id del Pago</p>
                            </td>
                            <td>
                              <p>{pr.payment.id}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="card-title">Método de Pago</p>
                            </td>
                            <td>
                              <p>{formatter.typeEnum(pr.payment.method)}</p>
                            </td>
                          </tr>
                          {!pr.payment.nop ? (
                            <></>
                          ) : (
                            <>
                              <tr>
                                <td>
                                  <p className="card-title">NOP</p>
                                </td>
                                <td>
                                  <p>{pr.payment.nop}</p>
                                </td>
                              </tr>
                            </>
                          )}
                          {!pr.payment.bank ? (
                            <></>
                          ) : (
                            <tr>
                              <td>
                                <p className="card-title">Banco</p>
                              </td>
                              <td>
                                <p>{pr.payment.bank}</p>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <p className="card-title">Fecha de pago</p>
                            </td>
                            <td>
                              <p>{formatter.date(pr.payment.date)}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="card-title">Estatus de Pago</p>
                            </td>
                            <td>
                              <p>{formatter.typeEnum(pr.payment.status)}</p>
                            </td>
                          </tr>
                          {!pr.payment.payerNote ? (
                            <></>
                          ) : (
                            <tr>
                              <td>
                                <p className="card-title">Comentarios</p>
                              </td>
                              <td>
                                <p>{pr.payment.payerNote}</p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <Row className="d-flex">
                        <Col xs={12} md={6}>
                          <Card
                            onClick={() => {
                              setImage(pr.payment.image)
                              toggleModal("create")
                            }}
                          >
                            <CardBody>
                              <CardTitle>Comprobante de pago</CardTitle>
                              <CardSubtitle className="mb-3">
                                Haga click para ampliar
                              </CardSubtitle>
                              <img
                                alt=""
                                width="100%"
                                className="float-left"
                                src={pr.payment.image}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12} md={6}>
                          <Card
                            onClick={() => {
                              setImage(pr.payment.secondImage)
                              toggleModal("create")
                            }}
                          >
                            <CardBody>
                              <CardTitle>Comprobante adicional</CardTitle>
                              <CardSubtitle className="mb-3">
                                Haga click para ampliar
                              </CardSubtitle>
                              <img
                                alt=""
                                width="100%"
                                className="float-left"
                                src={pr.payment.secondImage}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      {pr.payment.status == "USER_ERROR" ? (
                        <>
                          {pr.payment.status == "USER_ERROR" ? (
                            <Row>
                              <Col xs={12}>
                                <div className="mt-6 mb-4 alert-danger w-100 py-3 px-3 text-center">
                                  <h6>
                                    {pr.payment.errorMessage ??
                                      "Se le asigno un error a su pago, favor confirmar imagenes enviadas y el pago realizado"}
                                  </h6>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <></>
                          )}
                          <form>
                            <Row>
                              <div className="col-xl col-sm-6">
                                <FormGroup className="mt-6 mb-0">
                                  <Label>Fecha del Pago*</Label>
                                  <Input
                                    onChange={ev => {
                                      changeHandler(ev, form, setForm)
                                    }}
                                    className="form-control"
                                    type="date"
                                    name="date"
                                  ></Input>
                                </FormGroup>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <FormGroup className="mt-6 mb-0">
                                  <Label>Metodo de Pago*</Label>
                                  <Input
                                    type="select"
                                    className="form-control select2-search-disable"
                                    name="method"
                                    onChange={ev => {
                                      changeHandler(ev, form, setForm)
                                    }}
                                    required={true}
                                  >
                                    <option value="">Elige</option>
                                    {EnumService.paymentMethods
                                      .filter(pm => pm.id > 1)
                                      .map(pm => (
                                        <option key={pm.id} value={pm.slug}>
                                          {pm.label}
                                        </option>
                                      ))}
                                  </Input>
                                </FormGroup>
                              </div>
                            </Row>
                            {form.method === "BANK-TRANSFER" ||
                            form.method === "DEPOSIT" ||
                            form.method === "OTHER" ? (
                              <>
                                <Row className="mb-12">
                                  <div className="col-xl col-sm-6">
                                    <FormGroup className="mt-6 mb-0">
                                      <Label>NOP*</Label>
                                      <Input
                                        onChange={ev => {
                                          changeHandler(ev, form, setForm)
                                        }}
                                        className="form-control"
                                        type="text"
                                        name="nop"
                                        required={true}
                                      />
                                    </FormGroup>
                                  </div>
                                  <div className="col-xl col-sm-6">
                                    <FormGroup className="mt-6 mb-0">
                                      <Label>Banco*</Label>
                                      <Input
                                        type="select"
                                        className="form-control select2-search-disable"
                                        name="bank"
                                        onChange={ev => {
                                          changeHandler(ev, form, setForm)
                                        }}
                                        required={true}
                                      >
                                        <option value="">Elige</option>
                                        {EnumService.banks.map(pm => (
                                          <option key={pm.id} value={pm.slug}>
                                            {pm.label}
                                          </option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </div>
                                </Row>
                              </>
                            ) : (
                              <></>
                            )}
                            <Row className="mb-12">
                              <div className="col-xl col-sm-6">
                                <FormGroup className="mt-6 mb-0">
                                  <Label>Imagen del Comprobante*</Label>
                                  <Input
                                    onChange={ev => {
                                      changeHandler(ev, form, setForm, true)
                                    }}
                                    className="form-control"
                                    type="file"
                                    name="image"
                                  />
                                </FormGroup>
                              </div>
                              <div className="col-xl col-sm-6">
                                <FormGroup className="mt-6 mb-0">
                                  <Label>Imagen alternativa</Label>
                                  <Input
                                    className="form-control"
                                    type="file"
                                    name="secondImage"
                                    onChange={ev => {
                                      changeHandler(ev, form, setForm, true)
                                    }}
                                  />
                                </FormGroup>
                              </div>
                            </Row>
                            <Row>
                              <FormGroup>
                                <Label>Comentario</Label>
                                <Input
                                  className=""
                                  type="textarea"
                                  name="payerNote"
                                  onChange={ev => {
                                    changeHandler(ev, form, setForm)
                                  }}
                                  height={300}
                                />
                              </FormGroup>
                            </Row>
                            <Row>
                              <Col sm={12}>
                                <Button
                                  color="primary mb-3 w-100"
                                  onClick={() => submitHandler()}
                                >
                                  Renovar información
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </>
                      ) : (
                        <></>
                      )}
                      <Col md="12">
                        {showInvoice ? (
                          <>
                            <Modal isOpen={showInvoice} centered={true}>
                              <Card>
                                <CardBody>
                                  <Invoice
                                    paymentRequest={pr}
                                    payment={pr.payment}
                                    toggle={() => {
                                      setShowInvoice(!showInvoice)
                                    }}
                                  />
                                </CardBody>
                              </Card>
                            </Modal>
                          </>
                        ) : (
                          <></>
                        )}
                        <CardTitle>
                          Ház click aquí para descargar tu recibo
                        </CardTitle>
                        <Button
                          color="primary"
                          onClick={() => {
                            setShowInvoice(!showInvoice)
                          }}
                        >
                          <i className="bx bx-receipt" />{' '}
                          Ver Recibo
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                )}
              </TabPane>
            </TabContent>
          </Container>
        </div>
      </Modal>
    </>
  )
}

ModalDetails.propTypes = {
  toggle: PropTypes.func.isRequired,
  paymentRequest: PropTypes.any,
  isOpen: PropTypes.bool,
}

export default ModalDetails
