import React, { useState, useContext, useEffect } from "react";
import PropTypes from 'prop-types';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MainContext } from "contexts/Main.context";
import { EnumService } from "services/enum.service";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";

const NotificationDropdown = props => {

  const { backend, showLoader, formatter} = useContext(MainContext);
  const [menu, setMenu] = useState(false)
  const [rows, setRows] = useState([]);

  	//get
  useEffect(() => {
			pullData();
		},[]
  );
  const pullData = async () => {
		const {data} = await backend.getNotif();
		if(data){
			setRows(data);
		}
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li">
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown">
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounde d-pill"></span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 text-primary"> {props.t("Notificaciones")} </h6>
              </Col>
            </Row>
          </div>
          <SimpleBar style={{ height: "230px" }}>

          { rows.newPaymentRequest ? rows.newPaymentRequest.map((el)=>
          <Link to="" className="text-reset notification-item" key={el.id}>
              <div className="media">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="bx bx-calendar-event" />
                  </span>
                </div>
                <div className="media-body">
                  <span>Nuevo recibo de: </span>
                  <h6 className="mt-0 mb-1 d-inline">
                    {formatter.typeEnum(el.type)}
                  </h6> <br></br>
                  <span>Monto a pagar: </span>
                    <h6 className=" d-inline">
                      {el.baseAmount}
                    </h6>
                  <div className="my-2 font-size-12 text-muted">

                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      <span>Día de pago: </span>
                      {formatter.date(el.payday)}
                    </p>
                  </div>
                </div>
              </div>
            </Link> 
           ) : <></> }
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}
export default withTranslation()(NotificationDropdown)
NotificationDropdown.propTypes = {
  t: PropTypes.any
}