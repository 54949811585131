import React, { useState, useContext, useEffect }  from "react";
import { MainContext } from '../../contexts/Main.context';

import { Update } from "components/Representative/update";
import { RepresentativeCard } from "./representativecard";
import { Col, Container, Row } from "reactstrap"

const Representantive = () => {
  
  const representELements = [
    {isRepresentative: 'A', name: 'Seleccionar', surname: 'representante' },    
    {isRepresentative: 'B', name: 'Seleccionar', surname: 'representante' },
    {isRepresentative: 'C', name: 'Seleccionar', surname: 'representante' },
    {isRepresentative: 'D', name: 'Seleccionar', surname: 'representante' },
    {isRepresentative: 'E', name: 'Seleccionar', surname: 'representante' },
    {isRepresentative: 'F', name: 'Seleccionar', surname: 'representante' },
    {isRepresentative: 'G', name: 'Seleccionar', surname: 'representante' },
    {isRepresentative: 'H', name: 'Seleccionar', surname: 'representante' }
  ];

  const [representatives, setRepresentatives] = useState(representELements);
  const [modals, setModals] = useState({update: false});
  const {backend} = useContext(MainContext);
  const [zone, setZone ] = useState({});
  const [user, setUser ] = useState({});

	const attributes = [
		"isRepresentative",
		"name",
		"surname",
		"id"
	];

	//get
	useEffect(() => {
		pullData();
		},[]
	);
	const pullData = async () => {
		const {data} = await backend.getUsers({limit: -1, attributes}); 
		if(data){ 
			const newElements = data.filter( el => el.isRepresentative !== null )
      const aux = newElements.map((el) => el.isRepresentative);
      const resarray = representELements.filter(el => {
        if (aux.includes(el.isRepresentative)){ return false }
        return true;
      });
      const finalArray = [...newElements, ...resarray];
			setRepresentatives(finalArray);
		}
	}; 

	const toggleModal = (modal) => {
		const mdl = {...modals};
		mdl[modal] = !mdl[modal];
		setModals(mdl);
	};

  return (
    <React.Fragment>
			{modals.update ? (<Update active={modals.update} user={user} zone={zone} toggle={toggleModal} />) : (<></>)}
			<div className="page-content">
				<Container fluid>
					<Row className="align-items-center">
						<Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Representantes de zona </h4> </Col>
						<Col xs={12} md={6}>
							<Row className="d-flex justify-content-end">
								<Col xs={12} md={4} className="d-flex justify-content-end mb-4">
								</Col>
								<Col xs={12} md={4} className="mb-4">
								</Col>
							</Row>
						</Col>
					</Row>
            <div className="report-cards-container">
              <Row>
                { representatives ? representatives.map( el =>
                  <RepresentativeCard 
                    name={el.name +' '+el.surname} 
                    value={el.isRepresentative} 
                    key={el.isRepresentative} 
                    toggle={toggleModal} 
                    user={setUser} 
                    zone={setZone}
                  />
                ) : <></>}
              </Row>
            </div>
				</Container>
			</div>
	</React.Fragment>
  );
}

export default Representantive;