import React, {useContext, useState} from "react"
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import { Col, Row, Button} from "reactstrap"
import { Delete } from "components/Newsletter/delete";
import {MainContext} from "../../contexts/Main.context";

const NoticiasBlog = ({rows}) => {

  const {formatter} = useContext(MainContext);
  const [modals, setModals] = useState({delete: false});
  const [news, setNew] = useState({});

  const toggleModal = (modal) => {
    const mdl = {...modals};
    mdl[modal] = !mdl[modal];
    setModals(mdl);
  }

  return (
    <React.Fragment>
      {modals.delete ? (<Delete active={modals.delete} news={news} toggle={toggleModal} />) : (<></>)}
      {rows ? rows.map(posts => (
        <Row className="d-flex my-2" key={posts.id}>
          <Col xs={12} md={6} className="mb-4">
            <Link to={`/uv-newsletter/${posts.id}`} className="d-flex align-items-center">
              <Col xs={4} md={3} className="me-4">
                <img
                  src={posts.thumbnailUri}
                  className="avatar-md h-auto w-100 d-block rounded" />
              </Col>
              <Col className="overflow-hidden">
                  <h5 className="font-size-14 text-truncate"> {posts.name} </h5>
                <span className="text-muted mb-0">{formatter.date(posts.createdAt)}</span>
              </Col>
            </Link>
          </Col>
          <Col xs={12} md={6}>
            <Row className="d-flex justify-content-end">
              <Col xs={12} md={4} className="mb-4">
                <Link className="me-2" to={`/newsletter/edit-post/${posts.id}`}>
                  <Button 
                    type="button" 
                    color="success" 
                    className="w-100"
                    outline={ true }>
                      Editar 
                  </Button>
                </Link>
              </Col>
              <Col xs={12} md={4} className="mb-4">
                <Button 
                  type="button" 
                  color="danger" 
                  outline={ true } 
                  className="w-100"
                  data-toggle="modal"
                  onClick={() => {setNew(posts.id); toggleModal('delete')}}>
                    Eliminar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )) : <></> }
    </React.Fragment>
  );
};
NoticiasBlog.propTypes = {
  rows: PropTypes.any,
};
export {NoticiasBlog};
