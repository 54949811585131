import React, {useState, useEffect, useContext} from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import {withRouter, Link, Redirect} from "react-router-dom"
// users
import user1 from "../../../assets/images/users/user.jpg"
import {MainContext} from "../../../contexts/Main.context";

const ProfileMenu = props => {
  const {backend, setLoggedIn, setIsAdmin, isLoggedIn} = useContext(MainContext)
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState("Admin");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [])

  const logOut = () => {
    setLoggedIn(false);
    setIsAdmin(false);
    backend.logOut();
  }

  const userData  = backend.getSession();
  const  frontName =   userData.data.user ? userData.data.user.name : userData.data.lot.slug;

  return (
    <>
      { !isLoggedIn ? (<>
        <Redirect to={{ pathname: "/login" }}/>
      </>) : (<></>)}
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            { userData.data.user ? frontName : <>LOTE <b>{frontName}</b></>}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <button onClick={logOut} className="btn  btn">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span className="">Cerrar sesión</span>
          </button>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}
ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}
const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}
export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)