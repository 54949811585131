import React, {useContext}from "react";
import PropTypes from "prop-types";
import { MainContext } from "contexts/Main.context";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { Link } from "react-router-dom";

const ActivityComp = ({ activity }) => {
  const { formatter } = useContext(MainContext);
  return (
    <React.Fragment>
      <Card className="height-100">
        <CardBody>
          <CardTitle className=" pb-3 bg-transparent border-bottom">Actividad</CardTitle>
          <ul className=" list-unstyled" >
            {activity ? activity.slice(0,8).map(el=> (
            <li className="event-list pt-3" key={el.id}>
              <div className="d-flex">
                <div className="me-3">
                  <h5 className="font-size-14">
                    {/* {formatter.date(el.cratedAt)}{" "} */}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>{el.actionName}</div>
                  <span>Actividad: <b>{formatter.typeEnum(el.actionType)}</b></span>
                </div>
              </div>
            </li>)) : <></>}
          </ul>
          <div className="d-flex justify-content-center my-3">
            <Link to="/activities">
              <Button type="primary" color={'primary'}>
                Ver más
              </Button>
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
ActivityComp.propTypes = {
  activity: PropTypes.array,
};
export default ActivityComp;