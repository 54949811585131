import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import { Single } from './single';
import {MainContext} from '../../../contexts/Main.context';
import {
	Row,
	Nav,
	Col,
	Card,
	Form,
	Table,
	Modal,
	Input,
	Alert,
	Button,
	NavItem,
	TabPane,
	NavLink,
	CardBody,
	Collapse,
	CardTitle,
	Container,
	TabContent,
	CardSubtitle,
	InputGroup,
	InputGroupText,
} from 'reactstrap';
import { useEffect } from 'react';
import {PaymentRequestStatusBadge} from "../../Common/PaymentRequestStatusBadge";
import { Invoice } from 'components/Common/Invoice';
export const Update = ({isOpen, toggle, paymentRequest = {}}) => {

	console.log("paymentRequest", paymentRequest.id);
	
	const defaultForm = {
    "type": "",
  	"status": "",
    "landId": 1,
    "baseAmount": 0,
    "interests": 0,
		"fees": 0,
		"errorMessage": '',
	};

	const updatePDF = {
		"billImage": ''
	};

	
	const [col1, setcol1] = useState(false); 
	const [payRequest, setPayRequest] = useState({});
	const [form, setForm] = useState(defaultForm); console.log(form)
	const [pdfForm, setPdfForm] = useState(updatePDF); console.log(pdfForm)
	const [image, setImage] = useState({}); 
	const [showInvoice, setShowInvoice] = useState(false);
	const [customActiveTab, setcustomActiveTab] = useState("1");
	const {backend, showLoader, toastAlert, formatter, changeHandler} = useContext(MainContext);
	const [modals, setModals] = useState({ create: false, update: false, delete: false, modalproof: false });
	const [pr, setPayReq] = useState({});

	const historiToggle = () => {
		setcol1(!col1);
	};
	const toggleCustom = tab => {
		if (customActiveTab !== tab) {
			setcustomActiveTab(tab);
		}
	};
	console.log("test: ",  payRequest.service)
	
	//Get
	useEffect(()=>{
		getpayRequest();
		getPr();

	},[]);
	const getpayRequest = async () => {
		const resp = await backend.getPayment(paymentRequest.id);
		if(resp && resp.status){
			// console.log("req resp: ",resp)
			setPayRequest(resp.data);
			const vals = {
				"type": resp.data.type,
				"status": resp.data.status,
				"landId": resp.data.landId,
				"baseAmount": resp.data.baseAmount,
				"interests": resp.data.interests,
				"fees": resp.data.fees,
			};
			setForm(vals);
		}
	};
	const getPr = async () => {
		const resp = await backend.getPayment(paymentRequest.id);
		if(resp && resp.status){
			setPayReq(resp.data);
		}
	};


	//Update
	const submitHandler = () => {
		showLoader(true);
		uploadPDF()
		uploadData()
	};
	
	const uploadPDF = async () => {
		if ( !pdfForm.billImage ) { return }
		const resp = await backend.serviceExpensesUpdate(paymentRequest.id , pdfForm)
		showLoader(false);
		if (resp.status) {
			toastAlert({body: 'PDF Actualizado correctamente', color: 'primary'});
			toggle('update');
			location.reload();
		} else {
			toastAlert({body: 'Error inesperado', color: 'warning'});
		}
	}

	const uploadData = async () => {
		let resp = {};
		console.log('FORM',form);
		if ( form.status ===  'OPENED' || form.status ===  'DEFAULTED' || form.status ===  'EXPIRED' || form.status ===  'PROCESSING') {
			console.log("payment-request update"); 
			resp = await backend.updatePaymentRequests(paymentRequest.id, form)
		} else if( form.status ===  'USER_ERROR' || form.status ===  'PAID' || form.status ===  'VERIFIED') {
			console.log("payment update");
			resp = await backend.paymentUpdate(payRequest.payment.id , form)
		}
		showLoader(false);
		if (resp.status) {
			toastAlert({body: 'Actualizado correctamente', color: 'primary'});
			toggle('update');
			location.reload();
		} else {
			toastAlert({body: 'Error inesperado', color: 'warning'});
		}
	}

	const getColorBasedIOnType = (type) => {
		switch (type){
			case 'WARN': return 'warning'
			case 'ERROR': return 'danger'
			case 'SUCCESS': return ''
			default: return 'light'
		}
	};

	const toggleModal = (modal) => {
    const mdl = {...modals};
    mdl[modal] = !mdl[modal];
    setModals(mdl);
    pullData();
  };

	return (
		<>
		{modals.create ? (<Single active={modals.create} toggle={toggleModal} image={image}/>) : (<></>)}
			<Modal size="md" isOpen={isOpen} toggle={() => { toggle(); }} centered id="invoice-modal">
				<div className="modal-header">
					<h5 className="modal-title mt-0" id="myModalLabel"> Editar orden de pago </h5>
					<button type="button" onClick={() => {toggle('update');}} className="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div className=" invoice-content d-flex justify-content-center  m-0">
					<Container>
						<Nav tabs className="nav-tabs-custom nav-justified">
							<NavItem>
								<NavLink
									style={{ cursor: "pointer" }}
									className={classnames({active: customActiveTab === "1"})}
									onClick={() => {toggleCustom("1");}}
									disabled={false}
								>
									<span className="d-block d-sm-none"> <i className="fas fa-home"/> </span>
									<span className="d-none d-sm-block">Orden de Pago</span>
								</NavLink>
							</NavItem>

							{payRequest.service && payRequest.service.id ? (
								<NavItem>
									<NavLink
										style={{ cursor: "pointer" }}
										className={classnames({active: customActiveTab === "2"})}
										onClick={() => {toggleCustom("2");}}
										disabled={false}
									>
										<span className="d-block d-sm-none"> <i className="far fa-envelope"/> </span>
										<span className="d-none d-sm-block">Servicio</span>
									</NavLink>
								</NavItem>
							) : <></>}

							{payRequest.payment && payRequest.payment.id ? (
								<NavItem>
									<NavLink
										style={{ cursor: "pointer" }}
										className={classnames({active: customActiveTab === "3"})}
										onClick={() => {toggleCustom("3");}}
										disabled={false}
									>
										<span className="d-block d-sm-none"> <i className="fas fa-cog"/> </span>
										<span className="d-none d-sm-block">Detalles del Pago</span>
									</NavLink>
								</NavItem>
							) : <></>}
						</Nav>

						<TabContent activeTab={customActiveTab} className="p-3 text-muted">
							<TabPane tabId="1">
								<Row>
									<Col sm="12">
										<div className="mb-0 gap">

											<Form onSubmit={submitHandler}>
												<Table>
													<tbody>

														<tr>
															<td> <p className="card-title">Lote</p> </td>
															<td>
																<Input name="landId" disabled placeholder={payRequest.land ? payRequest.land.slug : ''}/>
															</td>
														</tr>
														<tr>
															<td> <p className="card-title">Tarifa</p> </td>
															<td>
																<InputGroup>
																	<InputGroupText> S/ </InputGroupText>
																	<Input 
																		name='fees'
																		value={form.fees}
																		onChange={(ev)=>{changeHandler(ev,form, setForm)}}/>
																</InputGroup>
															</td>
														</tr>
														{!form.interests ? <></> :
															<tr>
																<td> <p className="card-title">Intereses</p> </td>
																<td>
																	<InputGroup>
																		<InputGroupText> S/ </InputGroupText>
																		<Input
																			disabled
																			name="interests"
																			value={form.interests}
																			onChange={(ev)=>{changeHandler(ev,form, setForm)}}/>
																	</InputGroup>
																</td>
															</tr>
														}
														<tr>
															<td> <p className="card-title">Monto Base</p> </td>
															<td>
																<InputGroup>
																	<InputGroupText> S/ </InputGroupText>
																	<Input
																		disabled={payRequest.status !== 'OPENED'}
																		name="baseAmount" 
																		value={form.baseAmount}
																		onChange={(ev)=>{changeHandler(ev,form, setForm)}}/>
																</InputGroup>
															</td>
														</tr>
														<tr>
															<td> <p className="card-title">Total</p> </td>
															<td>
															<InputGroup>
																<InputGroupText> S/ </InputGroupText>
																<Input 
																	placeholder={parseInt(form.baseAmount) + parseInt(form.fees) + parseInt(form.interests)}
																	disabled/>
															</InputGroup>
															</td>
														</tr>
														<tr>	
															<td> <p className="card-title">Recibo PDF</p> </td>
															<td>
																<Input
																	className="form-control"
																	name="billImage"
																	type="file"
																	accept=".pdf"
																	onChange={(ev)=>{changeHandler(ev,pdfForm,setPdfForm, true)}}
																/>
															</td>
														</tr>
														<tr>	
															<td> <p className="card-title">Estatus</p> </td>
															<td>
																{ payRequest.status === 'PROCESSING' ?
																	<Input
																		type='select'
																		name='status'
																		onChange={(ev)=>{changeHandler(ev,form, setForm)}}
																		placeholder="seleccionar estado" >
																			<option value="">Mantener estatus</option>
																			<option value="USER_ERROR">Presenta errores</option>
																			<option value="VERIFIED">Pagado</option>
																	</Input> : <><PaymentRequestStatusBadge status={payRequest.status ?? 'OPENED'}/></>
																}
															</td>
														</tr>

														{ form.status == 'USER_ERROR' ?
															<tr>	
																<td> <p className="card-title">Comentario</p> </td>
																<td>
																	<Input 
																	name='errorMessage'
																	type='textarea'
																	placeholder="deja un comentario"
																	onChange={(ev)=>{changeHandler(ev,form, setForm)}}
																	>
																	</Input>
																</td>
															</tr>
														: <></>}
													</tbody>
												</Table>

												{!Array.isArray(payRequest.activities) ? <></> :
													<Col>
														<div className="accordion" id="accordion">
															<div className="accordion-item">
																<span className="accordion-header" id="headingOne">
																	<button
																		type="button"
																		onClick={historiToggle}
																		style={{cursor: "pointer"}}
																		className={classnames( "accordion-button", "fw-medium", {collapsed: !col1} )}
																	>
																		Historial
																	</button>
																</span>

																<Collapse isOpen={col1} className="accordion-collapse">
																	<div className="accordion-body">
																		{payRequest.activities.map((activity) =>
																			<div key={activity.id} className="">
																				<Alert color={getColorBasedIOnType(activity.messageType)}>
																						<b>{formatter.date(activity.createdAt, true)}:</b><br/>
																						<b>{activity.name}</b>{' '}-{' '}
																						{activity.description}
																				</Alert>
																			</div> 
																		)}
																	</div>
																</Collapse>
															</div>
														</div>
													</Col>
												}
											<div className="mt-3">
												<Button 
													className=" w-100" 
													color="success" 
													type="button"
													onClick={()=> submitHandler()}>
														Guardar Cambios
												</Button>
											</div>
										</Form>
									</div>
								</Col>
							</Row>
						</TabPane>
						<TabPane tabId="2">
							{!payRequest.service ? <></> : (
								<Row>
									<Col sm="12">
										<Table>
											<tbody>
												<tr>
													<td> <p className="card-title">Servicio</p> </td>
													<td>
														<p>{formatter.typeEnum(payRequest.type)}</p>
													</td>
												</tr>
												<tr>
													<td> <p className="card-title">Ultima Medición</p> </td>
													<td>
														<p>{formatter.typeEnum(payRequest.service.lastMeasurement)}</p>
													</td>
												</tr>
												<tr>
													<td> <p className="card-title">Medición Actual</p> </td>
													<td>
														<p>{formatter.typeEnum(payRequest.service.currentMeasurement)}</p>
													</td>
												</tr>
												<tr>
													<td> <p className="card-title">Día de la Medición</p> </td>
													<td>
														<p>{formatter.date(payRequest.service.measurementDate)}</p>
													</td>
												</tr>
												<tr>
													<td> <p className="card-title">Precio por Unidad:</p> </td>
													<td>
														<p>{formatter.amount(payRequest.service.pricePerUnit)}</p>
													</td>
												</tr>
												<tr>
													<td> <p className="card-title">Monto a Pagar</p> </td>
													<td>
														<p>{formatter.amount(payRequest.service.amount)}</p>
													</td>
												</tr>
											</tbody>
										</Table>
										<div className="d-flex justify-content-center ">
											<Row>
												<Col xs={12} md={4}>
													<Card onClick={()=> {setImage(payRequest.service.latestMeasurementImage); toggleModal('create')}}>
														<CardBody>
															<CardTitle>Medidor (Mes pasado)</CardTitle>
															<CardSubtitle className="mb-3">
																Haga click para ampliar
															</CardSubtitle>
																<img
																	src={payRequest.service.latestMeasurementImage}
																	alt=""
																	width="100%"
																/>
														</CardBody>
													</Card>
												</Col>
												<Col xs={12} md={4}>
													<Card onClick={()=> {setImage(payRequest.service.currentMeasurementImage); toggleModal('create')}}>
														<CardBody>
															<CardTitle>Medidor (Este mes)</CardTitle>
															<CardSubtitle className="mb-3">
																Haga click para ampliar
															</CardSubtitle>
																<img
																	src={payRequest.service.currentMeasurementImage}
																	alt=""
																	width="100%"
																/>
														</CardBody>
													</Card>
												</Col>
												<Col xs={12} md={4}>
													<Card
														// onClick={()=> {setImage(payRequest.service.billImage);
														// 	toggleModal('create')}}
													>
														<CardBody>
															<CardTitle>Recibo de servicio</CardTitle>
															{/*<CardSubtitle className="mb-3">*/}
															{/*		Haga click para ampliar*/}
															{/*</CardSubtitle>*/}
															<Button
																type="button"
																color={'success'}
																className="w-100"
																style={{ fontSize: '12px', marginTop: '20px'}}
																onClick={() => {window.open(pr.service.billImage);}}>
																Descargar recibo
															</Button>
														</CardBody>
													</Card>
												</Col>
											</Row>
										</div>
									</Col>
								</Row>
							)}
							</TabPane>
								<TabPane tabId="3">
									{!payRequest.payment ? <></> : (
										<Row>
											<Col sm="12">
												<Table>
													<tbody>
														<tr>
															<td> <p className="card-title">Id del Pago</p> </td>
															<td>
																<p>{payRequest.payment.id}</p>
															</td>
														</tr>
														<tr>
															<td> <p className="card-title">Método de Pago</p> </td>
															<td>
																<p>{formatter.typeEnum(payRequest.payment.method)}</p>
															</td>
														</tr>
														{payRequest.payment.nop ? 
															<tr>
																<td> <p className="card-title">NOP</p> </td>
																<td>
																	<p>{payRequest.payment.nop}</p>
																</td>
															</tr> 
														: <></>}
														{!payRequest.payment.bank ? <></> : 
															<tr>
																<td> <p className="card-title">Banco</p> </td>
																<td>
																	<p>{payRequest.payment.bank}</p>
																</td>
															</tr>
														}
														<tr>
															<td> <p className="card-title">Fecha de pago</p> </td>
															<td>
																<p>{formatter.date(payRequest.payday)}</p>
															</td>
														</tr>
														{!payRequest.payment.payerNote ? <></> : 
															<tr>
																<td> <p className="card-title">Comentarios</p> </td>
																<td>
																	<p>{payRequest.payment.payerNote}</p>
																</td>
															</tr>
														}
													</tbody>
												</Table>
												<Row className="d-flex">
													<Col xs={12} md={6}>
														<Card onClick={()=> {setImage(payRequest.payment.image); toggleModal('create')}}>
															<CardBody>
															<CardTitle>Comprobante de pago</CardTitle>
															<CardSubtitle className="mb-3">
																Haga click para ampliar
															</CardSubtitle>
																<img
																	alt=""
																	width="100%"
																	className="float-left"
																	src={payRequest.payment.image}/>
															</CardBody>
														</Card>
													</Col>
													<Col xs={12} md={6}>
														<Card onClick={()=> {setImage(payRequest.payment.secondImage); toggleModal('create')}}>
															<CardBody>
															<CardTitle>Comprobante adicional</CardTitle>
															<CardSubtitle className="mb-3">
																Haga click para ampliar
															</CardSubtitle>
																<img
																	alt=""
																	width="100%"
																	className="float-left"
																	src={payRequest.payment.secondImage}/>
															</CardBody>
														</Card>
													</Col>
												</Row>
												<Col md="12">
													{showInvoice ? <>
														<Modal isOpen={showInvoice} centered={true}>
															<Card>
																<CardBody>
																	<Invoice paymentRequest={pr} payment={pr.payment} toggle={()=>{
																			setShowInvoice(!showInvoice);
																	}}/>
																</CardBody>
															</Card>
														</Modal>
													</>:<></>}
													<CardTitle>Ház click aquí para descargar tu recibo</CardTitle>
													<Button color="primary" onClick={()=>{setShowInvoice(!showInvoice);}}>
															<i className="bx bx-receipt"/>{' '}
															Ver Recibo
													</Button>
												</Col>
												</Col>
										</Row>)}
							</TabPane>
						</TabContent>
					</Container>
				</div>
			</Modal>
		</>
	);
};
Update.propTypes = {
    isOpen: PropTypes.any,
    toggle: PropTypes.func,
    paymentRequest: PropTypes.any
}