import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import {MainContext} from '../../contexts/Main.context'
import {Input, Label, Modal, Row, CardTitle, Button, Col} from 'reactstrap';
import {EnumService} from "../../services/enum.service";

export const Create = ({active, toggle}) => {
    
    const defaultForm = {
        description:'',
        creatorId:'',
        comment:'',
        method:'',
        amount:0,
        date:''
    }

    const {backend, showLoader, changeHandler, toastAlert} = useContext(MainContext);
    const [form, setForm] = useState(defaultForm);

    const options = (withAll = true, array) => {
        const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
        array.map((el) => _options.push({id:el.id, value: el.value ? el.value : el.id,  label: el.label ? el.label : el.id}));
        return _options;
    };

    useEffect(() => {
        author();
    }, [])
    const author = () => {
        const userData = backend.getSession();
        const  authorId =   userData.data.user ? userData.data.user.id : '';
        const _form = {...form}
        _form.creatorId = authorId
        setForm(_form)
    }

    const submitHandler = () => {
        showLoader(true);
        uploadData()
    };
    const uploadData = async () => {
        const resp = await backend.newTransaction(form);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'Creado correctamente correctamente', color: 'primary'});
            toggle('create');
            location.reload();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
    };

    return (
        <Modal 
            size="l" 
            centered
            isOpen={active}
            toggle={() => { toggle('create'); }}>
            <Row>
                <CardTitle className="mt-4 mx-3">
                    Ingresa los datos de la nueva transacción
                </CardTitle>
                <Button 
                    onClick={() => {toggle('create');}} 
                    type="button" 
                    className="close"
                    data-dismiss="modal" 
                    aria-label="Close">
                </Button>
            </Row>
            <Row className="modal-body">
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Fecha:</Label>
                    <Input 
                        type="date" 
                        name="date"
                        className="form-control" 
                        placeholder="Seleccionar fecha"
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Metodo de Pago</Label>
                    <Select 
                        isMulti={false}
                        placeholder="Ingresar..."
                        options={options(true, EnumService.paymentMethods)} 
                        onChange={(selected)=> {
                            const _form = {...form};
                            _form.method = selected.value,
                            setForm(_form);}}
                    />
                </Col>
                <Col xs={12} className="mb-3">
                    <Label>Monto:</Label>
                    <Input 
                        name="amount"
                        type='number'
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} className="mb-3">
                    <Label>Descripción:</Label>
                    <Input 
                        name="description"
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} className="mb-3">
                    <Label>Comentarios:</Label>
                    <Input 
                        name="comment"
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12}>
                    <Button
                        className="btn btn-success w-100 p-2"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={()=> submitHandler()}>
                            Guardar cambios
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};
Create.propTypes = {
    active: PropTypes.bool,
    toggle: PropTypes.func
}