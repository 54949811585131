import React from "react"
import PropTypes from 'prop-types';
import { Row, Nav, Col, Card, Form, Table, Modal, Input, Alert, Button, NavItem,TabPane, NavLink, CardText, CardBody, Collapse, CardTitle, Container, TabContent, CardSubtitle, InputGroup, InputGroupText }from 'reactstrap';

export const Single = ({active, toggle, image}) => {
	return(
		<>
			<Modal 
				centered
				size="xl"
				isOpen={active}
				toggle={() => { toggle('create'); }}>
				<Row>
					<CardTitle className="mt-4 mx-3"> Imagen </CardTitle>
					<Button 
						type="button" 
						className="close"
						data-dismiss="modal" 
						aria-label="Close"
						onClick={() => {toggle('create');}} >
					</Button>
				</Row>
				<figure className="d-flex justify-content-center align-items-center w-100 h-100">
					<img
						style={{maxWidth : '100%'}}
						src={image} alt=""
						className=""/>
				</figure>
			</Modal>
		</>
	);
};
Single.propTypes = {
	active: PropTypes.any,
	toggle: PropTypes.func,
	image: PropTypes.any
}