import {Modal} from "reactstrap";
import PropTypes from "prop-types";
import React, {useContext} from 'react';
import { MainContext } from 'contexts/Main.context';

export const Delete = ({active, toggle, meeting}) => {

    const {backend, showLoader, toastAlert} = useContext(MainContext);

    const submitHandler = () => {
        showLoader(true);
        uploadData()
    };
    const uploadData = async () => {
        const resp = await backend.deleteMeeting(meeting);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'ELiminado correctamente', color: 'primary'});
            toggle('update');
            location.reload();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
    }

    return (
        <Modal isOpen={active} toggle={() => {toggle('delete');}} backdrop={'static'} id="staticBackdrop" centered>
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Remover Orden de Pago</h5>
                <button type="button" className="btn-close" onClick={() => {toggle('delete');}} aria-label="Close">
                </button>
            </div>
            <div className="modal-body">
                <p>Has intentado remover una Orden de Pago. ¿Esta seguro?</p>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-light" onClick={() => {toggle('delete');}}>
                    Cancelar
                </button>
                <button type="button" className="btn btn-danger"  onClick={() => {toggle('delete'); submitHandler()}}>
                    Remover
                </button>
            </div>
        </Modal>
    );
}

Delete.propTypes = {
    active: PropTypes.bool,
    toggle: PropTypes.func,
    meeting: PropTypes.any
}