import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"

const StackedColumnChart = ({ periodData, admin }) => {
  
  const actualMonth = () => {
    const date = new Date();
    const month = date.getMonth();
    switch (month){
      case 0:
        return ["Ago", "Sep", "Oct", "Nov", "Dic", "Ene"];
      break;
      case 1:
        return ["Sep", "Oct", "Nov", "Dic", "Ene", "Feb"];
        break;
      case 2:
        return ["Oct", "Nov", "Dic", "Ene", "Feb", "Mar"];
        break;
      case 3:
        return ["Nov", "Dic", "Ene", "Feb", "Mar", "Abr"];
        break;
      case 4:
        return ["Dic", "Ene", "Feb", "Mar", "Abr", "May"];
        break;
      case 5:
        return ["Ene", "Feb", "Mar", "Abr", "May", "Jun"];
        break;
      case 6:
        return ["Feb", "Mar", "Abr", "May", "Jun", "jul"];
        break;
      case 7:
        return ["Mar", "Abr", "May", "Jun", "jul", "Ago"];
        break;
      case 8:
        return ["Abr", "May", "Jun", "jul", "Ago", "Sep"];
        break;
      case 9:
        return ["May", "Jun", "jul", "Ago", "Sep", "Oct"];
        break;
      case 10:
        return ["Jun", "jul", "Ago", "Sep", "Oct", "Nov"];
        break;
      case 11:
        return ["jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
        break;
    }
  };

  const uvOptions = {
    chart: {
      stacked: true,
      toolbar: {show: true},
      zoom: {enabled: false},
      animations: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
        borderRadius: 5
      }
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return formatter.amount(val);
      },
      style: {
        colors: ['#FFFFFF'],
        fontSize: '12px',
      },
      background: {
        enabled: false,
        padding: 4,
        borderRadius: 10,
        borderWidth: 0,

      },
    },
    xaxis: {
      show: true,
      categories: actualMonth(),
      labels: {
        show: true
      }
    }, 
    colors: ["#268c34", "#52f868","#0f39de", "#21cbf1", "#fc3838","#fa7676", "#840098", "#c248d3"],
    legend: {
      position: "bottom"
    },
    fill: {
      opacity: 1
    }
  };

  const adminOptions = () => {
    const newValues = {...uvOptions};
    newValues.plotOptions = { 
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 5,
        distributed: true,}
    };
    newValues.xaxis = { 
      show: true,
      categories: ["Cuota Ordinaria", "Pagos Extraordinarios","Servicios", "Cuota de Admisión", "Sanciones", "Otros" ],
      labels: { show: false }
    };
    newValues.colors = ["#268c34", "#52f868","#0f39de", "#f1e921", "#fd4b4b", "#c7c7c7" ];
    return newValues;
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={admin ? adminOptions() : uvOptions}
        series={periodData}
        type="bar"
        height="351"
        className="apex-charts "
      />
    </React.Fragment>
  );
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
  admin: PropTypes.bool,
}
export  { StackedColumnChart };
