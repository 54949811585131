import React from "react";
import {Button, ModalBody} from "reactstrap";

const ModalPay = () => {
    return(
        <ModalBody>
            Pagar con tarjeta de credito o debito

            <Button color="primary" className="mb-3">
                Haz click aquí para pagar
            </Button>
        </ModalBody>
    )
}

export default ModalPay