import React, {useContext, useEffect, useState} from "react";
import {EnumService} from "../../services/enum.service";
import { MainContext } from '../../contexts/Main.context';
import { TableWrapper } from "../../components/Helpers/TableWrapper";
import {Card, CardBody, Container, Input, Col, Modal, Row, Form, FormGroup, Label, CardTitle, Badge, Button} from "reactstrap";
import "../Payments/uv-Styles.scss"
import ModalProof from "../../components/UV/Dashboard/Payments/modal/modalProof";
import ModalPay from "../../components/UV/Dashboard/Payments/modal/modalPay";
import ModalDetails from "../../components/UV/Dashboard/Payments/modal/modalDetails";
import {CustomPagination} from "../../components/Common/CustomPagination";

const UvPayments = () => {
    //->
    const defaultFilter = {
        type: '',
        status: '',
    };

    const {backend, formatter, changeHandler} = useContext(MainContext);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filter, setFilter] = useState(defaultFilter);
    const [rows, setRows] = useState([]);
    const [modal_pay, setModal_pay] = useState(false);
    const [modal_details, setModal_details] = useState(false);
    const [modal_proof, setModal_proof] = useState(false);
    const [openedPaymentRequest, setOpenedPaymentRequest] = useState({});

    useEffect(
      () => {pullData();},
      [page]
    );
    const filt = () => {
        pullData();
    };

    const pullData = async () => {
        const {data, meta} = await backend.getPaymentRequests({page, where: filter});
        if(data){
            setTotalPages(meta.lastPage);
            setRows(data);
        }
    };

    const tog_pay = () => {
        setModal_pay(!modal_pay);
        removeBodyCss();
    };
    const tog_details = () => {
        setModal_details(!modal_details);
        removeBodyCss();
    };
    const tog_proof = () => {
        setModal_proof(!modal_proof);
        pullData();
        removeBodyCss();
    };
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const getBadgeStatus = (status) => {
        const colorList = {
            'ERROR': 'danger',
            'OPENED': 'info',
            'DEFAULTED': 'dark',
            'EXPIRED': 'warning',
            'PROCESSING': 'secondary',
            'PAID': 'success',
        };
        const color = colorList[status] ?? 'warning';
        return(
          <Badge pill color={color} style={{backgroundColor: "transparent !important"}}>
              <p className={`font-size-14 p-1 m-0 ${color === 'warning' ? 'text-black' : ''}`}>
                  {formatter.typeEnum(status)}
              </p>
          </Badge>
        );
    };
	return( 
		<>
            {modal_details ? <ModalDetails isOpen={modal_details} toggle={tog_details} paymentRequest={openedPaymentRequest} /> : <></>}
			<div className="page-content" id="uv_payments">
				<Container fluid>
                <Row className="align-items-center">
                    <Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Pagos </h4> </Col>
                    <Col xs={12} md={6}>
                        <Row className="d-flex justify-content-end">
                        <Col xs={12} md={4} className=""></Col>
                        <Col xs={12} md={4} className=""></Col>
                        </Row>
                    </Col>
                </Row>
					<Form action="">
						<Row className="align-items-end">
							<Col sm={12} md={2}>
								<FormGroup>
									<Label>Tipo de pago</Label>
										<Input
                                            type="select"                                                     
                                            className="form-control"
                                            name="type"
                                            value={filter.type}
                                            onChange={(ev)=>{changeHandler(ev,filter,setFilter)}}>
                                            <option value="">Todos</option>
                                            {EnumService.paymentRequestTypes.map(el => (
                                              <option key={el.id} value={el.slug}>{el.label}</option>
                                            ))}
										</Input>
								</FormGroup>
							</Col>
							<Col sm={12} md={2} >
							<FormGroup>
									<Label>Estatus del Pago</Label>
										<Input type="select" className="form-control" name="status" value={filter.status}
                                               onChange={(ev)=>{changeHandler(ev,filter,setFilter)}}>
                                            <option value="">Todos</option>

                                            {EnumService.paymentRequestStatus.map(el => (
                                              <option key={el.id} value={el.slug}>{el.label}</option>
                                            ))}
										</Input>
								</FormGroup>
							</Col>
							<Col sm={12} md={4} >
                                <Button color="primary" className="mb-3" onClick={filt}>
                                    Filtrar
                                </Button>
                                <Button color="primary" className="mb-3 mx-3" onClick={() =>setFilter(defaultFilter)}>
                                    Eliminar filtro
                                </Button>
							</Col>
						</Row>
					</Form>
					<Col lg="12 mb-4">
						<Card className="no-hover">
							<CardBody>
								<div className="table-responsive text-nowrap">
									<TableWrapper headers={[
                                        {name: '#'},
                                        {name: 'Fecha de pago'},
                                        {name: 'Tipo'},
                                        {name: 'Estatus'},
                                        {name: 'Total'},
                                        {name: 'Acciones'},
                                    ]}>
										{rows ? rows.map((paymentRequest =>
										<tr key={paymentRequest.id} style={{verticalAlign: 'middle'}} className={`
										    ${paymentRequest.status === 'DEFAULTED' ? 'bg-danger bg-soft' : ''}
										    ${paymentRequest.status === 'EXPIRED' ? 'bg-warning bg-soft' : ''}`}>
											<td className="text-nowrap font-size-14">{paymentRequest.id}</td>
                                            <td className="text-nowrap font-size-14">{formatter.date(paymentRequest.payday)}</td>
                                            {/*<td className="text-nowrap font-size-14">{paymentRequest.land_id}</td>*/}
                                            <td className="text-nowrap font-size-14">{formatter.typeEnum(paymentRequest.type)}</td>
											<td className="text-nowrap font-size-14 text-center">{getBadgeStatus(paymentRequest.status)}</td>
											<td className="text-nowrap font-size-16">{formatter.amount(paymentRequest.total)}</td>
											<td className="text-nowrap font-size-14">
												<div className="row-buttons-container flex-nowrap">
														<Button type="button" color={'info'} outline={true} onClick={() => { setOpenedPaymentRequest(paymentRequest); tog_details(); }} className="btn"
															data-toggle="DetailsModal" data-target="#myModal">
                                                            <div className="m-0 font-size-15">
                                                                Detalles
                                                            </div>
														</Button>
                                                        {paymentRequest.status == 'PAID' || paymentRequest.status == 'PROCESSING' || paymentRequest.status == 'ERROR' ? (
                                                          <>
                                                          </>
                                                        ) : 
                                                        <>
                                                            <Button type="button" color="success" outline={true} onClick={() => { setOpenedPaymentRequest(paymentRequest); tog_proof(); }} className="btn m-2"
                                                                data-toggle="ProofModal" data-target="#myModal">
                                                                <p className="m-0 font-size-15">
                                                                    Comprobante de Pago
                                                                </p>
                                                            </Button>
                                                            <Button type="button" size="sm" onClick={() => { tog_pay(); }} className="btn btn-warning text-black "
                                                                      data-toggle="PayModal" data-target="#myModal" >
                                                                  <p className="m-0 text-black font-size-15">
                                                                      Pagar
                                                                  </p>
                                                            </Button>
                                                        </>}
												</div>
											</td>                                        
										</tr>)) : (<></>)}
									</TableWrapper>
								</div>
                                <Row className="mt-4">
                                    <CustomPagination currentPage={page} setPage={setPage} totalPages={totalPages}/>
                                </Row>
							</CardBody>
						</Card>
					</Col>
				</Container>
			</div>
            <>
                <Modal isOpen={modal_pay} toggle={() => { tog_pay(); }} centered >
                    <div className="modal-header">
                        <button onClick={() => {tog_pay();}} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal_content d-flex justify-content-center">
                        <Col md="12" xl="11">
                            <ModalPay/>
                        </Col>
                    </div>
                </Modal>
                <Modal isOpen={modal_proof} toggle={() => { tog_proof(); }} centered >
                    <div className="modal-header">
                        <CardTitle>Subir nuevo comprobante de pago</CardTitle>
                        <button onClick={() => {tog_proof();}} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal_content d-flex justify-content-center">
                        <Col md="12" xl="11">
                            <ModalProof toggle={tog_proof} paymentRequest={openedPaymentRequest}/>
                        </Col>
                    </div>
                </Modal>
            </>
		</>
	);
};
export default UvPayments;
