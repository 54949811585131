import React, {useContext} from 'react';
import {Badge} from 'reactstrap';
import PropTypes from 'prop-types';
import {MainContext} from '../../contexts/Main.context';

export const PaymentRequestStatusBadge = ({status}) => {
    const {formatter} = useContext(MainContext);
    const colorList = {
        'ERROR':'danger',
        'USER_ERROR':'danger',
        'OPENED': 'info',
        'DEFAULTED': 'dark',
        'EXPIRED': 'warning',
        'PROCESSING': 'secondary',
        'PAID': 'success',
    };
    const color = colorList[status] ?? 'warning';
    return(
      <Badge pill color={color} style={{backgroundColor: "transparent !important"}}>
          <p className={`font-size-14 p-1 m-0 ${color === 'warning' ? 'text-black' : ''}`}>
              {formatter.typeEnum(status)}
          </p>
      </Badge>
    );
};

PaymentRequestStatusBadge.propTypes = {
    status: PropTypes.string
}