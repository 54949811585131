import React, {useContext, useState, useEffect} from "react"
import Select from "react-select";
import MetaTags from 'react-meta-tags';
import {MainContext} from '../../contexts/Main.context';
import { CustomPagination } from "components/Common/CustomPagination";
import {TableWrapper} from "../../components/Helpers/TableWrapper";
import { Card, CardBody, Col, Container, FormGroup, Label, Row, Input, Button} from "reactstrap"



const Activities = () => {

     const defaultFilter = {
        date_from: "",
        date_to: '',
        id: '',
    };

    const {backend, formatter} = useContext(MainContext);
    const [form, setForm] = useState(defaultFilter);
    const [page, setPage] = useState(1);
    const [id, setId] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [rows, setRows] = useState([]);
    //get
    useEffect(() => {
            pullData();
            pullId();
        },[page]
    );
    const pullData = async () => {
        const {data, meta} = await backend.getActivities({where: form, page});
        if(data){
            setTotalPages(meta.lastPage);
            setRows(data);
        }
    };

    //Ids
    const pullId = async () => {
        const {data} = await backend.getActivities({page, limit: 999, attributes:["id"]});
        if(data){
            setId(data);
        }
    };

    const options = (withAll = true, array) => {
        const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
        array.map((el) => _options.push({id:el.id, value: el.id,  label: el.label ? el.label : el.id}));
        return _options;
    };

    const columns = [
        {name: '#'},
        {name: 'Fecha'},
        {name: 'Usuario'},
        {name: 'Acción'},
        {name: ''},
    ];

    const nameFormatter = (name, surname, company) => {
        if( name || surname) {
            return name +' '+ surname;
        }else {
            return company
        } 
    }
    
    return (
        <React.Fragment>
            <MetaTags> <title> Registro de Actividad | Condominio la raya </title> </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Registro de actividad </h4> </Col>
                    </Row>
                    <Row className="mb-4 d-flex align-items-center">
                        <Col xs={12} md={2} className="mt-3 mb-0">
                            <Label className=""> Desde: </Label>
                            <Input
                                name='date_from'
                                onChange={(ev)=> {
                                    const _form = {...form};
                                    _form.date_from = ev.target.value;
                                    setForm(_form);
                                }}
                                type="date"
                                className="form-control"
                            />
                        </Col>
                        <Col xs={12} md={2} className="mt-3 mb-0">
                            <Label className=""> Hasta: </Label>
                            <Input
                                type="date"
                                onChange={(ev)=> {
                                    const _form = {...form};
                                    _form.date_to = ev.target.value;
                                    setForm(_form);
                                }}
                                name='date_to'
                                className="form-control"
                            />
                        </Col>
                        <Col xs={12} md={2} className="mt-3 mb-0">
                            <Label>Buscar por #:</Label>
                            <Select 
                                isMulti={true}
                                placeholder="Ingresar..."
                                options={options(true, id)} 
                                onChange={(selected)=> {
                                    const result = selected.filter(el => el.value == 'All');
                                    const _form = {...form};
                                    result[0] ? _form.id = "" : _form.id = selected.map(el => el.value).join();
                                    setForm(_form);}}
                            />
                        </Col>
                        <Col xs={12} md={2}> <br/> <br/>
                            <Button type="button" color="primary" className="w-100" onClick={()=> pullData()}>
                                Filtrar
                            </Button>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <div className="table-responsive text-nowrap">
                                <TableWrapper headers={columns}>
                                    {rows ? rows.map((el => <tr key={el.id}>
                                    <td className="text-nowrap" >{el.id}</td>
                                    <td className="text-nowrap" >{formatter.date(el.createdAt)}</td>
                                    <td className="text-nowrap" >{el.UsersModel ? nameFormatter(el.UsersModel.name, el.UsersModel.surname, el.UsersModel.companyName) : '' }</td>
                                    <td className="text-nowrap" >{el.actionName}</td>
                                    </tr>)) : <></> }
                                </TableWrapper>
                            </div>
                            <Row className="mt-4">
                                <CustomPagination currentPage={page} setPage={setPage} totalPages={totalPages}/>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Activities;