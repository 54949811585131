import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import {MainContext} from '../../contexts/Main.context'
import {Input, Label, Modal, Row, CardTitle, Button, Col} from 'reactstrap';
import {EnumService} from "../../services/enum.service";

export const Update = ({active, toggle, user}) => {
    
    const defaultForm = {
        completeName:'',
        personaType:'',
        companyName:'',
        altEmail:'',
        password:'',
        username:'',
        comments:'',
        surname:'',
        address:'',
        active:1,
        email:'',
        type:'',
        name:'',
        document:'',
        typeDocument:'',
    }


    const {backend, showLoader, changeHandler, toastAlert, formatter} = useContext(MainContext);
    const [form, setForm] = useState(defaultForm);

    const options = (withAll = true, array) => {
        const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
        array.map((el) => _options.push({id:el.id, value: el.value,  label: el.label ? el.label : el.slug}));
        return _options;
    };

    //get
    useEffect(() => {
            pullData();
        },[]
    );
    const pullData = async () => {
        const resp = await backend.getUser(user);
        if(resp && resp.status){
			setForm(resp.data); 
		}
    };

    const submitHandler = () => {
        showLoader(true);
        uploadData()
    };
    const uploadData = async () => {
        const resp = await backend.updateUser(user, form);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'Creado correctamente correctamente', color: 'primary'});
            toggle('update');
            location.reload();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
    };

    return (
        <Modal 
            size="l" 
            centered
            isOpen={active}
            toggle={() => { toggle('update'); }}>
            <Row>
                <CardTitle className="mt-4 mx-3">
                    Actualizar usuario
                </CardTitle>
                <Button 
                    onClick={() => {toggle('update');}} 
                    type="button" 
                    className="close"
                    data-dismiss="modal" 
                    aria-label="Close">
                </Button>
            </Row>
            <Row className="modal-body">
                {!form.type ? <></> : <>
                    <Col xs={12} className="mb-3">
                        <Label>Tipo de usuario:</Label>
                        <Select
                            required
                            name="type"
                            isMulti={false}
                            value={{value: form.type, label: formatter.typeEnum(form.type)}}
                            placeholder="Ingresar..."
                            options={options(false, EnumService.UserTypes)}
                            onChange={(selected)=> {
                                const _form = {...form};
                                _form.type = selected.value;
                                setForm(_form);
                            }}
                        />
                    </Col>
                </> }
                <Col xs={12} className="mb-3">
                    <Label>¿Persona o empresa?:</Label>
                    <Select
                      required
                      isMulti={false}
                      name="personaType"
                      placeholder="Ingresar..."
                      value={{value: form.personaType, label: formatter.typeEnum(form.personaType)}}
                      options={options(false, EnumService.personaType)}
                      onChange={(selected)=> {
                          const _form = {...form};
                          _form.personaType = selected.value;
                          setForm(_form);}}
                    />
                </Col>
                <Col xs={12} className="mb-3">
                    <Label>Estado:</Label>
                    <Select
                      required
                      isMulti={false}
                      name="active"
                      placeholder="Ingresar..."
                      value={{value: form.active, label: form.active ? 'Activo' : 'Inactivo'}}
                      options={options(false, EnumService.ActiveValues)}
                      onChange={(selected)=> {
                          const _form = {...form};
                          _form.active = selected.value;
                          setForm(_form);}}
                    />
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Tipo de documento:</Label>
                    <Select 
                        required
                        isMulti={false}
                        name="typeDocument"
                        placeholder="Ingresar..."
                        value={{value: form.typeDocument, label: formatter.typeEnum(form.typeDocument)}}
                        options={options(false, EnumService.typeDocument)} 
                        onChange={(selected)=> {
                            const _form = {...form};
                            _form.typeDocument = selected.value;
                            setForm(_form);}}
                    />
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Documento:</Label>
                    <Input 
                        required
                        name="document"
                        type='number'
                        value={form.document}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Nombre:</Label>
                    <Input 
                        required
                        name="name"
                        value={form.name}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Apellido:</Label>
                    <Input 
                        required
                        name="surname"
                        value={form.surname}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                {form.type === 'OWNER' || form.type === 'BENEFICIARY' ? <></> : <>
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Nombre de usuario:</Label>
                    <Input 
                        required
                        autoComplete={'off'}
                        name="username"
                        value={form.username}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                </>}
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Compañia:</Label>
                    <Input 
                        required
                        name="companyName"
                        value={form.companyName}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Correo:</Label>
                    <Input 
                        required
                        name="email"
                        type='email'
                        value={form.email}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                {/* <Col xs={12} sm={6} className="mb-3">
                    <Label>Correo alterno:</Label>
                    <Input 
                        type='email'
                        name="altEmail"
                        value={form.altEmail}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col> */}
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Dirección:</Label>
                    <Input
                        name="address"
                        autoComplete={'off'}
                        value={form.address ?? ''}
                        className="form-control"
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                {form.type === 'BENEFICIARY' ? <></> : <>
                    <Col xs={12} sm={6} className="mb-3">
                        <Label>Contraseña:</Label>
                        <Input
                            name="password"
                            type="password"
                            autoComplete={'off'}
                            value={form.password}
                            className="form-control"
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                </>}
                <Col xs={12} className="mb-3">
                    <Label>Comentarios:</Label>
                    <Input 
                        name="comments"
                        autoComplete={'off'}
                        value={form.comments}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12}>
                    <Button
                        className="btn btn-success w-100 p-2"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={()=> submitHandler()}>
                            Guardar cambios
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};
Update.propTypes = {
    active: PropTypes.bool,
    toggle: PropTypes.func,
    user: PropTypes.any
};