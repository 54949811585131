import React, {useContext, useEffect, useState} from "react";
import { MainContext } from '../../contexts/Main.context';
import { TableWrapper } from "../../components/Helpers/TableWrapper";
import { CustomPagination } from "components/Common/CustomPagination";
import { Create } from "components/Files/create";
import { Delete } from "components/Files/delete";
import Select from "react-select";
import {Card, CardBody, Container, Col, Row, Button, Label, Input} from "reactstrap";
import "../Payments/uv-Styles.scss";

export const Files = () => {

  const defaultFilter = {
    date_from:'',
    date_to: '',
    name:'',
    id:'',
    category: 'documents',
    subcategory: ''
  };

  const {backend,showLoader, formatter} = useContext(MainContext);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(defaultFilter);
  const [id, setId] = useState([]);
  const [name, setName] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [rows, setRows] = useState([]);
  const [modals, setModals] = useState({create: false, update: false, delete: false});
  const [files, setFiles ] = useState({});
  const [selectedTab, setSelectedTab ] = useState('1');
  const [selectedSubTab, setSelectedSubTab] = useState(null);


  //get
  useEffect(() => {
      pullData();
      pullId();
    },[page]
  );

  useEffect(()=>{
    pullData()
    console.log(rows)
  }, [filter])

  const pullData = async () => {
    const {data, meta} = await backend.getFiles({page, where: filter});
    if(data){
      setTotalPages(meta.lastPage);
      setRows(data);
    }
  };
  //Ids
  const pullId = async () => {
    const {data} = await backend.getFiles({page, limit: 999, attributes:["id", "name"]});
    if(data){
      setId(data);
      setName(data);

    }
  };

  const options = (withAll = true, array, name = false) => {
    const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
    array.map((el) => _options.push({id:el.id, value: name ? el.name : el.id,  label: name ? el.name : el.id}));
    return _options;
  };

  const downloadDocument = async (filename, url) => {
    if (filename) {
      showLoader(true);
      const resp = await backend.getProtectedImageUrl(filename);
      window.open(resp.data);
      showLoader(false);
    } else {
      showLoader(true);
      window.open(url);
      showLoader(false);
    }
  }

  const toggleModal = (modal) => {
    const mdl = {...modals};
    mdl[modal] = !mdl[modal];
    setModals(mdl);
  };

  const filterCategories = [
    { id: '1', label: 'Documentos', value: 'documents' },
    { id: '8', value: 'newsletters', label: 'Boletines'},
    { id: '2', label: 'Asambleas', value: 'assemblies' },
    { id: '3', label: 'Balances', value: 'balance_j' },
    { id: '4', label: 'Documentos de agua', value: 'water_documents',
      subcategories: [
        { id: '1', label: 'Captación de agua de rio Lurin', value: 'water_collection_lurin', },
        { id: '2', label: 'Estudio de pozo', value: 'well_study' },
        { id: '3', label: 'Proyecto de regadío preliminar', value: 'pre_irrigation_project' },
        { id: '4', label: 'Estudio de agua', value: 'water_study' },
        { id: '5', label: 'Documentos varios', value: 'various_documents' }
      ]
    },
    // { id: '5', label: 'Estudio de agua', value: 'water_study' },
    { id: '6', label: 'Saneamiento', value: 'sanitation' },
    { id: '7', label: 'Proyecto nueva asociación', value: 'new_association' },
    { id: '9', label: 'Informes', value: 'reports' },
    { id:'10', label: 'Reunión CD La Raya con CD Cooperativa de Essalud', value:'essalud_meeting' },
    { id:'11', label: 'Arrendamiento a terceros de área de uso exclusivo', value:'exclusive_area_rental' }
  ]

  const columns = [
    // {name: '#'},
    {name: 'Lote'},
    {name: 'Nombre del archivo'},
    {name: 'Fecha'},
    {name: 'Descargar'},
    {name: ''}
  ];

  const active = {
    background: '#57bb6e',
    borderColor: '#57bb6e',
    color: '#fff',
    marginTop: '10px'
  }

  const deactive = {
    backgroundColor: '#ececec',
    borderColor: '#b5b3b3',
    color: '#7a7777',
    marginTop: '10px'
  }

  return(
    <React.Fragment>
      {modals.create ? (<Create active={modals.create} toggle={toggleModal} />) : (<></>)}
      {modals.delete ? (<Delete active={modals.delete} file={files} toggle={toggleModal} />) : (<></>)}

      <div className="page-content" id="uv_payments">
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Documentos </h4> </Col>
            <Col xs={12} md={6}>
              <Row className="d-flex justify-content-end">
                <Col xs={12} md={4} className="mb-4">
                </Col>
                <Col xs={12} md={4} className="mb-4">
                  <Button
                    type="button"
                    color="success"
                    className="w-100"
                    onClick={() => {toggleModal('create');}}>
                    Subir
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-4 d-flex align-items-center">
            <Col xs={12} md={2} className="mt-3 mb-0">
              <Label className=""> Desde: </Label>
              <Input
                type="date"
                className="form-control"
                onChange={(ev)=> {
                  const _form = {...filter};
                  _form.date_from = ev.target.value;
                  setFilter(_form);
                }}
              />
            </Col>
            <Col xs={12} md={2} className="mt-3 mb-0">
              <Label className=""> Hasta: </Label>
              <Input
                type="date"
                className="form-control"
                onChange={(ev)=> {
                  const _form = {...filter};
                  _form.date_to = ev.target.value;
                  setFilter(_form);
                }}
              />
            </Col>
            <Col xs={12} md={2} className="mt-3 mb-0">
              <Label>Nombre:</Label>
              <Select
                isMulti={true}
                placeholder="Ingresar..."
                options={options(true, name, true)}
                onChange={(selected)=> {
                  const result = selected.filter(el => el.value == 'All');
                  const _form = {...filter};
                  result[0] ? _form.name = "" : _form.name = selected.map(el => el.value).join();
                  setFilter(_form);}}
              />
            </Col>
            {/*<Col xs={12} md={2} className="mt-3 mb-0">*/}
            {/*  <Label>Buscar por #:</Label>*/}
            {/*  <Select*/}
            {/*    isMulti={true}*/}
            {/*    placeholder="Ingresar..."*/}
            {/*    options={options(true, id)}*/}
            {/*    onChange={(selected)=> {*/}
            {/*      const result = selected.filter(el => el.value == 'All');*/}
            {/*      const _form = {...filter};*/}
            {/*      result[0] ? _form.id = "" : _form.id = selected.map(el => el.value).join();*/}
            {/*      setFilter(_form);}}*/}
            {/*  />*/}
            {/*</Col>*/}
            <Col xs={12} md={2}> <br/> <br/>
              <Button type="button" color="primary" className="w-100" onClick={()=> pullData()}>
                Filtrar
              </Button>
            </Col>
          </Row>
          <Row className="mb-4 d-flex align-items-center">
            <Col xs={12} >
              <Label className="mt-3"> Categoría: </Label>
            </Col>
            {filterCategories ? filterCategories.map(((cat, index) =>
                <Col key={index} xs={'auto'} md={'auto'} className="mt-0 mb-0">
                  <Button
                    id={cat.id}
                    value={cat.value}
                    style={selectedTab === cat.id ?  active : deactive}
                    onClick={(ev)=> {
                      const _form = {...filter};
                      _form.category = ev.target.value;
                      if(ev.target.value === 'water_documents') {
                        _form.subcategory = 'water_collection_lurin';
                        setSelectedSubTab('1');
                      } else _form.subcategory = '';
                      setFilter(_form);
                      setSelectedTab(ev.target.id);
                    }}
                  >
                    {cat.label}
                  </Button>
                </Col>
            )):(<></>)}
            {selectedTab && (() => {
              const selectedCategory = filterCategories.find(cat => cat.id === selectedTab);
              return selectedCategory && selectedCategory.subcategories ? (
                <Col xs={12} className="mt-3">
                  <Label className="mt-3"> Subcategoría: </Label>
                  <Row>
                    {selectedCategory.subcategories.map((subcat, subIndex) => (
                      <Col key={subIndex} xs={'auto'} md={'auto'} className="mt-0 mb-0">
                        <Button
                          id={subcat.id}
                          value={subcat.value}
                          style={selectedSubTab === subcat.id ? active : deactive}
                          onClick={(ev) => {
                            const _form = { ...filter };
                            _form.subcategory = ev.target.value;
                            setFilter(_form);
                            setSelectedSubTab(ev.target.id);
                          }}
                        >
                          {subcat.label}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </Col>
              ) : null;
            })()}
          </Row>
          <Col lg="12 mb-4">
            <Card className="no-hover">
              <CardBody>
                <div className="table-responsive text-nowrap">
                  <TableWrapper headers={columns}>
                    {rows ? rows.map((doc =>
                      <tr key={doc.id}>
                        {/*<td className="text-nowrap">{doc.id}</td>*/}
                        <td className="text-nowrap">{doc.land ? doc.land.slug : (doc.landId === 0 ? 'Todos': doc.landId)}</td>
                        <td className="text-nowrap">{doc.name}</td>
                        <td className="text-nowrap">{formatter.date(doc.createdAt)}</td>
                        <td className="text-nowrap font-size-14">
                          <Row className="row-buttons-container flex-nowrap">
                            <Col xs={12} md={6} className="my-2">
                              <Button
                                type="button"
                                color={'warning'}
                                data-target="#myModal"
                                className="font-size-15 h-100 w-100"
                                onClick={()=>{downloadDocument(doc.filename, doc.documentUrl)}}>
                                Archivo
                              </Button>
                            </Col>
                            <Col xs={12} md={6} className="my-2">
                              <Button
                                type="button"
                                color="danger"
                                outline={true}
                                data-toggle="modal"
                                className="font-size-15 h-100 w-100"
                                onClick={() => {setFiles(doc.id); toggleModal('delete')}}>
                                Eliminar
                              </Button>
                            </Col>
                          </Row>
                        </td>
                      </tr>)) : (<></>)}
                  </TableWrapper>
                </div>
                <Row className="mt-4">
                  <CustomPagination currentPage={page} setPage={setPage} totalPages={totalPages}/>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};
