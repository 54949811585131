import React, {useContext, useState, useEffect}  from "react";
import { MainContext } from '../../contexts/Main.context';
import Select from 'react-select'
import { Update } from "components/Lots/update";
import { TableWrapper } from "components/Helpers/TableWrapper";
import { CustomPagination } from "components/Common/CustomPagination";
import { Button, Card, CardBody, Col, Container, Row, Label } from "reactstrap"

export const Lots = () => {
	const defaultFilter = {
		id: '',
		zone: '',
  };

	const {backend, showLoader} = useContext(MainContext);
	const [modals, setModals] = useState({create: false, update: false, delete: false});
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [rows, setRows] = useState([]);
	const [lot, setLot ] = useState(defaultFilter);
	const [filter, setFilter ] = useState({});
	const [names, setNames ] = useState([{}]);

	//get
  useEffect(() => {
			pullData();
			pullFilters();
		},[page]
  );
  const pullData = async () => {
		showLoader(true);
		const {data, meta} = await backend.getLots({page, where: filter});
		if(data){
			setTotalPages(meta.lastPage);
			console.log(data)
			setRows(data.sort((a, b) => (a.slug > b.slug) ? 1 : -1));
		}
		showLoader(false);
  };
	const pullFilters = async () => {
		showLoader(true);
		const {data} = await backend.getLots({page, limit: 999,  attributes:["id", "slug", "zone"]});
		if(data){
			setNames(data)
		}
		showLoader(false);
  };

	const options = (array) => { 
    const options = [{id: "All", value: "",  label: "todos"}]
    array.map((el) => options.push({id: el.id, value: el.value ? el.value : el.id, label: el.slug ? el.slug : el.id}));
    return options
  };

	const _options = (array) => {
		const options = [{id: "All", value: "",  label: "todos"}]
		const zones = array.reduce((accumulator, current) => {
			accumulator.add(current.zone);
			return accumulator;
		}, new Set());
		const values = [...zones]
    values.map((el) => options.push({id: el, value: el, label: el}));
    return options
  };

	const toggleModal = (modal) => {
    const mdl = {...modals};
    mdl[modal] = !mdl[modal];
    setModals(mdl);
  };
	
	const columns = [
		{name: 'lote'},
    {name: 'Propietario'},
		{name: 'Número'},
		{name: 'Zona'},
	];

  return (
    <React.Fragment>

			<div className="page-content">
			{modals.update ? (<Update active={modals.update} toggle={toggleModal} lot={lot} />) : (<></>)}
				<Container fluid>
					<Row className="align-items-center">
						<Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Lotes </h4> </Col>
						<Col xs={12} md={6}>
							<Row className="d-flex justify-content-end">
								<Col xs={12} md={4} className="d-flex justify-content-end mb-4">
								</Col>
								<Col xs={12} md={4} className="mb-4">
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="mb-4 d-flex align-items-center">
            <Col xs={12} sm={4} md={2} className="mt-3 mb-0">
              <Label className=""> Lotes: </Label>
							<Select
								isMulti={true}
								options={options(names)}
								placeholder="Ingresar..."
								onChange={(selected)=>{
									const result = selected.filter(el => el.id == 'All')
									const _filter = {...filter};
									result[0] ? _filter.id = "" : _filter.id = selected.map(el => el.value).join();
									setFilter(_filter);
								}}
							/> 
            </Col>
						<Col xs={12} sm={4} md={2} className="mt-3 mb-0">
              <Label className="">Zona:</Label>
							<Select
								isMulti={true}
								options={_options(names)}
								placeholder="Ingresar..."
								onChange={(selected)=>{
										const result = selected.filter(el => el.id == 'All')
										const _filter = {...filter};
										result[0] ? _filter.zone = "" : _filter.zone = selected.map(el => el.value).join();
										setFilter(_filter);}}
							/> 
            </Col>
						<Col xs={12} md={2}> <br/> <br/>
							<Button type="button" color="primary" className="w-100" onClick={()=> pullData()}>
								Filtrar
							</Button>
						</Col>
					</Row>
					<Row>
						<Col lg="12">
							<Row>
								<Card>
									<CardBody>
										<div className="table-responsive text-nowrap">
											<TableWrapper headers={columns}>
													{rows ? rows.map((el => <tr key={el.id}>
														<td className="text-nowrap">{el.slug}</td>
														{el.owner ? <>
														<td className="text-nowrap">{`${el.owner.name} ${el.owner.surname}`}</td>
														</> : <></>}
														<td className="text-nowrap">{el.lot}</td>
														<td className="text-nowrap">{el.zone}</td>
														<td className="text-nowrap">
															<Row className="row-buttons-container flex-nowrap">
																{backend.getUserType() === 'SECURITY' ? <>
																</> : <>
																		<Col xs={12} xxl={6} className="my-2">
																				<Button
																					type="button"
																					color={'info'}
																					outline={true}
																					data-toggle="modal"
																					data-target="#myModal"
																					className="font-size-15 h-100 w-100"
																					onClick={() => {setLot(el.id); toggleModal('update')}}>
																						Modificar
																				</Button>
																		</Col>
																</>}
															</Row>
														</td>
													</tr>)): <></>}
											</TableWrapper>
										</div>
										<Row className="mt-4">
											<CustomPagination currentPage={page} setPage={setPage} totalPages={totalPages}/>
										</Row>
									</CardBody>
								</Card>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
	</React.Fragment>
  );
};