import React, {useContext, useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {Input, Label, Modal, Row, Button, Col, CardTitle, Form, CardBody} from 'reactstrap';
import { MainContext } from "contexts/Main.context";

export const Details = ({active, pay, toggle, admin}) => {

	const {backend, formatter} = useContext(MainContext);
	const [data, setData] = useState({});

	//get
	useEffect(() => {
		pullData();
	}, [])
	const pullData = async () => {
		const resp = await backend.getPayment(pay);
		if(resp.data){
			const _data = {...data} 
			_data.baseAmount = resp.data.baseAmount;
			_data._updatedAt = resp.data.updatedAt;
			_data.status = resp.data.status;
			_data.type = resp.data.type;
			_data.id = resp.data.id;
			_data.nop = resp.data.payment.nop;
			_data.bank = resp.data.payment.bank;
			_data.amount = resp.data.payment.amount;
			_data.method = resp.data.payment.method;
			_data.payerNote = resp.data.payment.payerNote;
			_data.updatedAt = resp.data.payment.updatedAt;
			setData(_data)
		}
	};

	return(
		<Modal
			size="l" 
			centered
			isOpen={active}
			toggle={() => {toggle('details');}}>
			<Row>
				<CardTitle className="mt-4 mb-0 mx-3">
						Información de pago: <span>#<strong>{data.id ?? 'Sin información'}</strong></span> <br />
				</CardTitle>
				<CardTitle className="mx-3 my-2"> Estado: <strong>{formatter.typeEnum(data.status) ?? 'Sin información'}.</strong></CardTitle>
				<Button 
						onClick={()=> {toggle('details');}} 
						type="button" 
						className="close"
						data-dismiss="modal" 
						aria-label="Close">
				</Button>
			</Row>
			<Form>
				<Row className="modal-body">
					<Col xs={12}  sm={6} className="mb-3">
						<Label>Monto a pagar:</Label>
						<Input 
							disabled
							name="baseAmount"
							value={formatter.amount(data.baseAmount) ?? 'Sin información'}
							className="data-control" 
							placeholder=""/>
					</Col>
					<Col xs={12}  sm={6} className="mb-3">
						<Label>Monto pagado:</Label>
						<Input 
							disabled
							name="amount"
							value={formatter.amount(data.amount) ?? 'Sin información'}
							className="data-control" 
							placeholder=""/>
					</Col>
					<Col xs={12}  sm={6} className="mb-3">
						<Label>Metodo de pago:</Label>
						<Input 
							disabled
							name="method"
							value={formatter.typeEnum(data.method) ?? 'Sin información'}
							className="data-control" 
							placeholder=""/>
					</Col>
					<Col xs={12}  sm={6} className="mb-3">
						<Label>Banco:</Label>
						<Input 
							disabled
							name="bank"
							value={data.bank ?? 'Sin información'}
							className="data-control" 
							placeholder=""/>
					</Col>
					<Col xs={12}  sm={6} className="mb-3">
						<Label>Numero de operación:</Label>
						<Input 
							disabled
							name="nop"
							value={data.nop ?? 'Sin información'}
							className="data-control" 
							placeholder=""/>
					</Col>
					<Col xs={12}  sm={6} className="mb-3">
						<Label>Tipo de pago:</Label>
						<Input 
							disabled
							name="type"
							value={formatter.typeEnum(data.type) ?? 'Sin información'}
							className="data-control" 
							placeholder=""/>
					</Col>
					<Col xs={12}  sm={6} className="mb-3">
						<Label>Día de creación:</Label>
						<Input 
							disabled
							name="_updatedAt"
							value={formatter.date(data._updatedAt) ?? 'Sin información'}
							className="data-control" 
							placeholder=""/>
					</Col>
					<Col xs={12}  sm={6} className="mb-3">
						<Label>Día pagado:</Label>
						<Input 
							disabled
							name="updatedAt"
							value={formatter.date(data.updatedAt) ?? 'Sin información'}
							className="data-control" 
							placeholder=""/>
					</Col>
					<Col xs={12} className="mb-3">
						<Label>Comentarios:</Label>
						<Input 
							disabled
							type="textarea"
							name="payerNote"
							value={data.payerNote ?? 'Sin información'}
							className="data-control" 
							placeholder=""/>
					</Col>
					<Col xs={12}>
						<Link to={admin ? "/payments" : "/uv-payments"}>
							<Button
								className="btn btn-success w-100 p-2"
								data-dismiss="modal"
								aria-label="Close">
									Ir a Pagos
							</Button>
						</Link>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};
Details.propTypes = {
	active: PropTypes.bool,
	toggle: PropTypes.func,
	pay: PropTypes.any,
	admin: PropTypes.any
};