import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import {MainContext} from '../../contexts/Main.context'
import {Input, Label, Modal, Row, CardTitle, Button, Col} from 'reactstrap';
import {EnumService} from "../../services/enum.service";

export const GuestUpdate = ({active, toggle, user}) => {
    
    const defaultForm = {
        completeName:'',
        personaType:'',
        companyName:'',
        altEmail:'',
        password:'',
        username:'',
        comments:'',
        surname:'',
        address:'',
        active:1,
        email:'',
        type:'',
        name:'',
        document:'',
        typeDocument:'',
        visitDate:'',
        exitDate:''
    }

    const {backend, showLoader, changeHandler, toastAlert, formatter} = useContext(MainContext);
    const [form, setForm] = useState(defaultForm);

    const options = (withAll = true, array) => {
        const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
        array.map((el) => _options.push({id:el.id, value: el.value,  label: el.label ? el.label : el.slug}));
        return _options;
    };

    //get
    useEffect(() => {
            pullData();
        },[]
    );
    const pullData = async () => {
        const resp = await backend.getUser(user);
        if(resp && resp.status){
			setForm(resp.data); 
		}
    };

    const submitHandler = () => {
        showLoader(true);
        uploadData()
    };
    const uploadData = async () => {
        const resp = await backend.updateUser(user, form);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'Creado correctamente correctamente', color: 'primary'});
            toggle('guestUpdate');
            location.reload();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
    };

    return (
        <Modal 
            size="l" 
            centered
            isOpen={active}
            toggle={() => { toggle('guestUpdate'); }}>
            <Row>
                <CardTitle className="mt-4 mx-3">
                    Actualizar invitado
                </CardTitle>
                <Button 
                    onClick={() => {toggle('guestUpdate');}} 
                    type="button" 
                    className="close"
                    data-dismiss="modal" 
                    aria-label="Close">
                </Button>
            </Row>
            <Row className="modal-body">
                {!form.type ? <></> : <>
                    <Col xs={12} className="mb-3">
                        <Label>Tipo de usuario:</Label>
                        <Select
                            required
                            name="type"
                            isMulti={false}
                            value={{value: form.type, label: formatter.typeEnum(form.type)}}
                            placeholder="Ingresar..."
                            options={options(false, EnumService.UserTypesToOwner)}
                            onChange={(selected)=> {
                                const _form = {...form};
                                _form.type = selected.value;
                                setForm(_form);
                            }}
                        />
                    </Col>
                </> }
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Nombre:</Label>
                    <Input
                        required
                        name="name"
                        value={form.name}
                        className="form-control"
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Apellido:</Label>
                    <Input
                        required
                        name="surname"
                        value={form.surname}
                        className="form-control"
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Label>Tipo de documento:</Label>
                    <Select 
                        required
                        isMulti={false}
                        name="typeDocument"
                        placeholder="Ingresar..."
                        value={{value: form.typeDocument, label: formatter.typeEnum(form.typeDocument)}}
                        options={options(false, EnumService.typeDocument)} 
                        onChange={(selected)=> {
                            const _form = {...form};
                            _form.typeDocument = selected.value;
                            setForm(_form);}}
                    />
                </Col>
                <Col xs={12} sm={6} className="mb-3">
                    <Label>N° Documento:</Label>
                    <Input 
                        required
                        name="document"
                        type='number'
                        value={form.document}
                        className="form-control" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={6} className="mb-3">
                    <Label>Día de ingreso:</Label>
                    <Input
                        required
                        name="visitDate"
                        type='date'
                        value={form.visitDate.slice(0, 10)}
                        className="form-control"
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={6} className="mb-3">
                    <Label>Día de salida:</Label>
                    <Input
                        required
                        name="exitDate"
                        type='date'
                        value={form.exitDate.slice(0, 10)}
                        className="form-control"
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12}>
                    <Button
                        className="btn btn-success w-100 p-2"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={()=> submitHandler()}>
                            Guardar cambios
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};
GuestUpdate.propTypes = {
    active: PropTypes.bool,
    toggle: PropTypes.func,
    user: PropTypes.any
};