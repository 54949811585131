

import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import {MainContext} from '../../contexts/Main.context'
import {Input, Label, Modal, Row, CardTitle, Button, Col} from 'reactstrap';
import {EnumService} from "../../services/enum.service";

export const GuestCreate = ({active, toggle}) => {
    const defaultForm = {
        completeName:'',
        username:'',
        visitDate:'',
        exitDate:'',
        surname:'',
        active:1,
        type:'',
        name:'',
        document:'',
        typeDocument:'',
    }

    const {backend, showLoader, changeHandler, toastAlert} = useContext(MainContext);
    const [form, setForm] = useState(defaultForm);
    const [lot, setLots] = useState([]);
    
    useEffect(() => {
        pullData();
    }, [])
    const pullData = async () => {
        const {data} = await backend.getLots({limit: 999});
        if(data){
            setLots(data);
        }
    };
    const options = (withAll = true, array) => {
        const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
        array.map((el) => _options.push({id:el.id, value: el.value ? el.value : el.id,  label: el.label ? el.label : el.slug}));
        return _options;
    };  

    const submitHandler = () => {        
        const _form = {...form};
        _form.completeName = form.name +' '+ form.surname;
        setForm(_form);
        showLoader(true);
        uploadData(_form)
    };
    const uploadData = async (_form) => {
        const resp = await backend.newGuest(_form ?? form);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'Creado correctamente correctamente', color: 'primary'});
            toggle('guestCreate');
            location.reload();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
    };

    return (
        <Modal 
            size="l" 
            centered
            isOpen={active}
            toggle={() => { toggle('guestCreate'); }}>
            <Row>
                <CardTitle className="mt-4 mx-3">
                    Ingresa los datos del nuevo invitado
                </CardTitle>
                <Button 
                    onClick={() => {toggle('guestCreate');}} 
                    type="button" 
                    className="close"
                    data-dismiss="modal" 
                    aria-label="Close">
                </Button>
            </Row>
            <form action="">
                <Row className="modal-body">
                    <Col xs={12} className="mb-3">
                        <Label>Tipo:</Label>
                        <Select 
                            required
                            name="type"
                            isMulti={false}
                            placeholder="Ingresar..."
                            options={options(false, EnumService.UserTypesToOwner)} 
                            onChange={(selected)=> {
                                const _form = {...form};
                                _form.type = selected.value,
                                setForm(_form);}}
                        />
                    </Col>
                    <Col xs={12} sm={6} className="mb-3">
                        <Label>Nombre:</Label>
                        <Input
                            required
                            name="name"
                            className="form-control"
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                    <Col xs={12} sm={6} className="mb-3">
                        <Label>Apellido:</Label>
                        <Input
                            required
                            name="surname"
                            className="form-control"
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                    <Col xs={12} sm={6} className="mb-3">
                        <Label>Tipo de documento:</Label>
                        <Select 
                            required
                            isMulti={false}
                            name="typeDocument"
                            placeholder="Ingresar..."
                            options={options(false, EnumService.typeDocument)} 
                            onChange={(selected)=> {
                                const _form = {...form};
                                _form.typeDocument = selected.value,
                                setForm(_form);}}
                        />
                    </Col>
                    <Col xs={12} sm={6} className="mb-3">
                        <Label>N° Documento:</Label>
                        <Input 
                            required
                            name="document"
                            type='number'
                            className="form-control" 
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <Label>Fecha de ingreso:</Label>
                        <Input 
                            required
                            name="visitDate"
                            type='date'
                            className="form-control" 
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <Label>Fecha de salida:</Label>
                        <Input
                            required
                            name="exitDate"
                            type='date'
                            className="form-control"
                            onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                    </Col>
                    <Col xs={12}>
                        <Button
                            className="btn btn-success w-100 p-2"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={()=> submitHandler()}>
                                Guardar cambios
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
GuestCreate.propTypes = {
    active: PropTypes.bool,
    toggle: PropTypes.func
};