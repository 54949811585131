import PropTypes from "prop-types";
import { MainContext } from "contexts/Main.context";
import React, {useContext, useState, useEffect} from "react";
import {Input, Label, Modal, Row, Button, Col, CardTitle} from 'reactstrap';


export const Update = ({active, toggle, meeting}) => {

	const defaultForm = {
		"description":'',
		"finishDate":'',
		"authorId":'',
		"title":'',
		"date":'',
		"url":'',
	}

	const {backend, showLoader, toastAlert, changeHandler} = useContext(MainContext);
	const [form, setForm] = useState(defaultForm);
	
	//get
	useEffect(() => {
		pullData();
	},[])
	const pullData = async () => {
		const resp = await backend.getMeeting(meeting);
		if(resp && resp.status){
			const vals = {
				"description":resp.data.description,
				"finishDate":resp.data.finishDate,
				"authorId":resp.data.authorId,
				"title":resp.data.title,
				"date":resp.data.date,
				"url":resp.data.url,
			};
			setForm(vals);
		}
	};
	//update
	const submitHandler = () => {
		showLoader(true);
		uploadData()
	};
	const uploadData = async () => {
			const resp = await backend.updateMeeting(meeting, form);
			showLoader(false);
			if (resp.status) {
					toastAlert({body: 'Actualizado correctamente', color: 'primary'});
					toggle('update');
					location.reload();
			} else {
					toastAlert({body: 'Error inesperado', color: 'warning'});
			}
	}

	return(
		<Modal isOpen={active} toggle={() => {toggle('update');}} backdrop={'static'} id="staticBackdrop" centered>
			<Row>
				<CardTitle className="mt-4 mx-3">
						Actualizar reunion
				</CardTitle>
				<Button 
					onClick={() => {toggle('update');}} 
					type="button" 
					className="close"
					data-dismiss="modal" 
					aria-label="Close"/>
			</Row>
			<Row className="modal-body">
				<Col xs={12} className="mb-3" sm={6}>
					<Label>Fecha de inicio:</Label>
					<Input 
						type="date" 
						name="date"
						value={form.date.slice(0, 10)}
						className="form-control" 
						placeholder="Select date"
						onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
				</Col>
				<Col xs={12} className="mb-3" sm={6}>
					<Label>Fecha de cierre:</Label>
					<Input 
						type="date" 
						name="finishDate"
						className="form-control" 
						value={form.finishDate.slice(0, 10)}
						placeholder="Select date"
						onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
				</Col>
				<Col xs={12} className="mb-3">
					<Label>Título:</Label>
					<Input 
						name="title"
						required={true}
						value={form.title}
						className="form-control" 
						placeholder="Escribe un Título" 
						onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
				</Col>
				<Col xs={12} className="mb-3">
					<Label>Descripción:</Label>
					<Input 
						name="description"
						className="form-control" 
						value={form.description}
						placeholder="Escribe una Descripción"
						onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
				</Col>
				<Col xs={12} className="mb-3">
					<Label>Url:</Label>
					<Input 
						name="url"
						className="form-control" 
						value={form.url}
						placeholder="Inserte el Url"
						onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
				</Col>
				<Col xs={12}>
					<Button
						className="btn btn-success w-100 p-2"
						data-dismiss="modal"
						aria-label="Close"
						onClick={()=> submitHandler()}>
							Guardar cambios
					</Button>
				</Col>
			</Row>
		</Modal>
	);
};
Update.propTypes = {
	active: PropTypes.bool,
	toggle: PropTypes.func,
	meeting: PropTypes.any
}