import Select from "react-select";
import MetaTags from 'react-meta-tags';
import { EnumService } from "services/enum.service";
import {Create} from "components/Transaction/create";
import {Delete} from "components/Transaction/delete";
import {Update} from "components/Transaction/update";
import { StatusUpdate } from "components/Transaction/status-update";
import {MainContext} from '../../contexts/Main.context';
import React, {useContext, useEffect, useState} from "react"
import {TableWrapper} from "../../components/Helpers/TableWrapper";
import {CustomPagination} from "../../components/Common/CustomPagination";
import {Button, Card, CardBody, Col, Container, Label, Row, Input, CardTitle} from "reactstrap"

const Transactions = () => {
    const defaultFilter = {
        date_from:'',
        date_to:'',
        method:'',
        id:'',
    };

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [transaction, setTransaction ] = useState({});
    const {backend, formatter} = useContext(MainContext);
    const [id, setId] = useState([]);
    const [status, setStatus] = useState([]);
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState(defaultFilter);
    const [modals, setModals] = useState({create: false, update: false, delete: false, status: false});

    //get
    useEffect(() => {
        pullData();
        pullId();
        pullStatus()
    }, [page])
    const pullData = async () => {
        const {data, meta} = await backend.getTransactions({page, where: filter});
        if(data){
            // setTotalPages(meta.lastPage);
            setRows(data);
        }
    };
    const pullId = async () => {
        const {data} = await backend.getTransactions({page, limit: 999, attributes:["id"]});
        if(data){
            setId(data);
        }
    };
    const pullStatus = async () => {
        const {data} = await backend.getStatus();
        if(data){
            setStatus(data);
        }
    };
    const options = (withAll = true, array) => {
        const _options = withAll ? [{id: 'All', value: 'All',  label: 'Todos'}] : [];
        array.map((el) => _options.push({id:el.id, value: el.value ? el.value : el.id,  label: el.label ? el.label : el.id}));
        return _options;
    };

    const toggleModal = (modal) => {
        const mdl = {...modals};
        mdl[modal] = !mdl[modal];
        setModals(mdl);
    };

    const columns = [
        {name: '#'},
        {name: 'Fecha'},
        {name: 'Total'},
        {name: 'Metodo de Pago'},
        {name: 'Tipo'},
        {name: 'Descripción'},
        {name: 'Comentarios'},
        {name: ''},
    ];
    return (
        <React.Fragment>
            <MetaTags> <title> Ordenes | Condominio la raya </title> </MetaTags>
            {modals.create ? (<Create active={modals.create} toggle={toggleModal} />) : (<></>)}
            {modals.delete ? (<Delete active={modals.delete} transaction={transaction} toggle={toggleModal} />) : (<></>)}
            {modals.update ? (<Update active={modals.update} transaction={transaction} toggle={toggleModal} />) : (<></>)}
            {modals.status ? (<StatusUpdate active={modals.status} transaction={transaction} toggle={toggleModal} />) : (<></>)}

            <div className="page-content">
                <Container fluid>
                    <Row>
                      <Col sm="12">
                        <Card className="mb-5" color="">
                          <CardBody className="d-flex justify-content-between align-items-center">
                                <CardTitle className="mb-0">
                                    Estado de cuenta
                                </CardTitle>
                                { status ?  <>
                                    <div className="">
                                        <h5 className="mb-0"> S/.
                                            {status.amount ? status.amount : <> Sin confirmar </>}
                                        </h5>
                                    </div>
                                    <div className="">
                                        <h5 className="mb-0">
                                            {status.updatedAt ? formatter.date(status.updatedAt) : <> Sin confirmar </>}
                                        </h5>
                                    </div>                            
                                </> : 
                                <>
                                    <div className="">
                                        <h5 className="mb-0">
                                            Sin estado confirmado.
                                        </h5>
                                    </div>                
                                </> }
                              <Button 
                                type="button" 
                                color={'info'} 
                                outline={true}                                 
                                className="w-25"
                                onClick={() => {toggleModal('status');}}>
                                  Editar
                              </Button>
                          </CardBody>
                        </Card>									
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Transacciones </h4> </Col>
                        <Col xs={12} md={6}>
                            <Row className="d-flex justify-content-end">
                            <Col xs={12} md={4} className="mb-4">
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Button 
                                type="button" 
                                color="success"
                                className="w-100"
                                onClick={() => {toggleModal('create');}}>
                                    Registrar egreso
                                </Button>
                            </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-4 d-flex align-items-center">
                        <Col xs={12} md={2} className="mt-3 mb-0">
                            <Label className=""> Desde: </Label>
                            <Input
                                type="date"
                                className="form-control"
                                onChange={(ev)=> {
                                    const _form = {...filter};
                                    _form.date_from = ev.target.value;
                                    setFilter(_form);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={2} className="mt-3 mb-0">
                            <Label className=""> Hasta: </Label>
                            <Input
                                type="date"
                                className="form-control"
                                onChange={(ev)=> {
                                    const _form = {...filter};
                                    _form.date_to = ev.target.value;
                                    setFilter(_form);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={2} className="mt-3 mb-0">
                            <Label>Buscar por #:</Label>
                            <Select 
                                isMulti={true}
                                placeholder="Ingresar..."
                                options={options(true, id)} 
                                onChange={(selected)=> {
                                    const result = selected.filter(el => el.value == 'All');
                                    const _form = {...filter};
                                    result[0] ? _form.id = "" : _form.id = selected.map(el => el.value).join();
                                    setFilter(_form);}}
                            />
                        </Col>
                        <Col xs={12} md={2} className="mt-3 mb-0">
                            <Label>Metodo de pago:</Label>
                            <Select
                                isMulti={true}
                                placeholder="Ingresar..."
                                options={options(true, EnumService.paymentMethods)}
                                onChange={(selected)=> {
                                    const result = selected.filter(el => el.value == 'All');
                                    const _form = {...filter};
                                    result[0] ? _form.method = "" : _form.method = selected.map(el => el.value).join();
                                    setFilter(_form);}}
                            />
                        </Col>
                        <Col xs={12} md={2}> <br/> <br/>
                            <Button type="button" color="primary" className="w-100" onClick={()=> pullData()}>
                            Filtrar
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive  text-nowrap">
                                        <TableWrapper headers={columns}>
                                            {rows ? rows.map((el => <tr key={el.id}>
                                            <td  className="text-nowrap">{el.id}</td>
                                            <td  className="text-nowrap">{formatter.date(el.createdAt)}</td>
                                            <td  className="text-nowrap">{formatter.amount(el.amount)}</td>
                                            <td  className="text-nowrap">{formatter.typeEnum(el.method)}</td>
                                            <td  className="text-nowrap">{formatter.typeEnum(el.type)}</td>
                                            <td  className="text-nowrap">{el.description}</td>
                                            <td  className="text-nowrap">{el.comment}</td>
                                                { formatter.typeEnum(el.type) == 'Ingreso'? <></> :
                                            <td className="text-nowrap font-size-14">
                                                <Row className="row-buttons-container flex-nowrap">
                                                    <Col xs={6} className="my-2">
                                                        <Button 
                                                            type="button" 
                                                            color={'info'} 
                                                            outline={true} 
                                                            data-toggle="modal" 
                                                            data-target="#myModal"
                                                            className="font-size-15 h-100 w-100"
                                                            onClick={() => {setTransaction(el.id); toggleModal('update')}}>
                                                                Detalles
                                                        </Button>
                                                    </Col>
                                                    <Col xs={6} className="my-2">
                                                        <Button 
                                                            type="button" 
                                                            color="danger" 
                                                            outline={true} 
                                                            data-toggle="modal"
                                                            className="font-size-15 h-100 w-100"
                                                            onClick={() => {setTransaction(el.id); toggleModal('delete')}}>
                                                                Eliminar
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </td>
                                                }
                                            </tr>)) : <></>}
                                        </TableWrapper>
                                    </div>
                                    <Row className="mt-4">
                                        <CustomPagination currentPage={page} setPage={setPage} totalPages={totalPages}/>
                                    </Row>
                                </CardBody>
                            </Card>
                         </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Transactions;