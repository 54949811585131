export class FormatterService {
  enumTranslation = {
    USERS_CONFIG: "Configuracion de Usuario",
    UPDATE: "Actualizar",
    PAID: "Pagado",
    VERIFIED: "Pagado",
    EXPIRED: "Vencido",
    DEFAULTER: "Moroso",
    ORDINARY_FEE: "Cuota Ordinaria",
    "ORDINARY FEE": "Cuota Ordinaria",
    LIGHT_SERVICE: "Luz",
    LIGHT: "Luz",
    WATER_SERVICE: "Agua",
    WATER: "Agua",
    OTHER_SERVICE: "Otro Servicio",
    "OTHER SERVICE": "Otro Servicio",
    EXTRAORDINARY: "Gasto Extraordinario",
    SANCTIONS: "Sanciones",
    ADMISSION_FEE: "Cuota de Admision",
    ADMISSION: "Cuota de Admision",
    OTHER: "Otro",
    "PAYMENT-GATEWAY": "Pasarela de Pago",
    CASH: "Efectivo",
    BANK_TRANSFER: "Transferencia Bancaria",
    DEPOSIT: "Depósito",
    "WESTERN-UNION": "Western Union",
    PRE_PAID: "Pagado por Adelantado",
    OTRO: "Otro",
    CREATE: "Crear",
    DELETE: "Eliminar",
    CONTACT_DATA: "Datos de Contacto",
    PAYMENT: "Pagos",
    ADMIN_CONFIG: "Configuración de Adminitrador",
    OTHERS: "Otros",
    "BANK-TRANSFER": "Transferencia Bancaria",
    "PRE-PAID": "Pre-pagado",
    OPENED: "Nuevo",
    DEFAULTED: "En mora",
    HOME: "Casa",
    OFFICE: "Oficina",
    MOBILE: "Móvíl",
    PROCESSING: "En revisión",
    OWNER: "Propietario",
    VISITOR: "Visitante",
    EMPLOYEE: "Trabajador",
    TRANSACTION: "Egreso",
    PAYMENT: "Ingreso",
    BENEFICIARY: "Beneficiario",
    TREASURER: "Tesoreria",
    FIELD_MANAGER: "Administrador de Campo",
    SECURITY: "Seguridad",
    ADMIN: 'Administrador',
  }

  construct() {}

  addLeftZeros(str) {
    const preStr = 5 - str.length > 0 ? "0".repeat(5 - str.length) : ""
    return `${preStr}${str}`
  }

  typeEnum(value) {
    if (this.enumTranslation[value]) {
      return this.enumTranslation[value]
    }
    return value
  }

  date(date, hour = false) {
    if (!date) return false
    if (typeof date === "string") {
      date = new Date(date)
    }
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: undefined,
      minute: undefined,
      second: undefined,
      timeZone: "GMT",
    }
    let out = date.toLocaleTimeString("es-ES", options)
    if (!hour) out = out.split(",")[0]
    return out
  }

  dateFilter(date, hour = false) {
    if (!date) return false
    if (typeof date === "string") {
      date = new Date(date)
    }
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: undefined,
      minute: undefined,
      second: undefined,
    }
    let out = date.toLocaleDateString("zh-Hans-CN", options)
    if (!hour) out = out.split(",")[0]
    return out
  }

  amount(value) {
    const currencyFormatter = new Intl.NumberFormat("es-PE", {
      style: "currency",
      currency: "PEN",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return currencyFormatter.format(value)
  }

  dateToTimestamp(date) {
    if (typeof date === "string") date = new Date(date)
    return date.toISOString().slice(0, 19).replace("T", " ")
  }

  async forceDownloadUrl(url) {
    console.log(url)
    try {
      const resp = await fetch(url)
      const blob = await resp.blob()
      const url_ = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      console.log(blob)
      a.href = url_
      a.download = `file.${blob.type.split("/")[1]}`
      document.body.appendChild(a)
      a.click()
      a.remove()
    } catch (e) {
      console.error(e)
      return { status: false, message: "Unexpected Error" }
    }
  }
}
