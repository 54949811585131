import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {MainContext} from '../../contexts/Main.context'
import {Input, Label, Modal, Row, Button, Col, CardTitle} from 'reactstrap';

export const Create = ({active, toggle}) => {

    const defaultForm = {
        "description":'',
        "finishDate":'',
        "authorId":'',
        "title":'',
        "date":'',
        "url":'',
    }

    const {backend, showLoader, toastAlert, changeHandler} = useContext(MainContext);
    const [form, setForm] = useState(defaultForm); 

    useEffect(() => {
        author();
    }, [])

    const author = () => {
        const userData = backend.getSession();
        const  authorId =   userData.data.user ? userData.data.user.id : '';
        const _form = {...form}
        _form.authorId = authorId
        setForm(_form)
    }

    const submitHandler = () => {
        showLoader(true);
        uploadData()
    };
    const uploadData = async () => {
        const resp = await backend.newMeeting(form);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'Subido correctamente', color: 'primary'});
            toggle('create');
            location.reload();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
    }

    return (
        <Modal 
            size="l" 
            centered
            isOpen={active}
            toggle={() => { toggle('create'); }}>
            <Row>
                <CardTitle className="mt-4 mx-3">
                    Ingresa Los Datos De La Nueva Reunión
                </CardTitle>
                <Button 
                    onClick={() => {toggle('create');}} 
                    type="button" 
                    className="close"
                    data-dismiss="modal" 
                    aria-label="Close">
                </Button>
            </Row>
            <Row className="modal-body">
                <Col xs={12}  sm={6} className="mb-3">
                    <Label>Fecha de inicio:</Label>
                    <Input 
                        type="date" 
                        name="date"
                        className="form-control" 
                        placeholder="Select date"
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12}  sm={6} className="mb-3">
                    <Label>Fecha de cierre:</Label>
                    <Input 
                        type="date" 
                        name="finishDate"
                        className="form-control" 
                        placeholder="Select date"
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} className="mb-3">
                    <Label>Título:</Label>
                    <Input 
                        name="title"
                        required={true}
                        className="form-control" 
                        placeholder="Escribe un Título" 
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>

                <Col xs={12} className="mb-3">
                    <Label>Descripción:</Label>
                    <Input 
                        name="description"
                        className="form-control" 
                        placeholder="Escribe una Descripción"
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12} className="mb-3">
                    <Label>Url:</Label>
                    <Input 
                        name="url"
                        className="form-control" 
                        placeholder="Inserte el Url"
                        onChange={(ev)=>{changeHandler(ev,form,setForm)}}/>
                </Col>
                <Col xs={12}>
                    <Button
                        className="btn btn-success w-100 p-2"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={()=> submitHandler()}>
                            Guardar cambios
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};
Create.propTypes = {
    active: PropTypes.bool,
    toggle: PropTypes.func
}