import React from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody, Button, Label, Input, Form,} from "reactstrap";
import { withRouter } from "react-router-dom";
import avatar from "../../assets/images/users/user.jpg";

const UserProfile = props => {
  const name = "Admin";
  const email = "themesbrand@gmail.com"
  const idx = 1;
  const telefeno1 = "+51 968 779 731";
  const telefeno2 = "+52 188 329 731";
  const telefeno3 = "+59 198 382 231";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Condominio la raya</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
        <h4>Perfil de Usuario</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center mx-3">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">Id no: #{idx}</p>
                        <p className="mb-1">{email}</p>
                        <p className="mb-1">Telefonos:</p>
                        <p>{telefeno1}</p>
                        <p>{telefeno2}</p>
                        <p>{telefeno3}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Cambiar Datos</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal">
                <div className="form-group">
                  <Label className="form-label mt-4">Nuevo nombre de usuario</Label>
                  <Input name="username" className="form-control" placeholder="Enter User Name" type="text"/>
                </div>
                <div className="form-group">
                  <Label className="form-label mt-4">Nuevo E-mail</Label>
                  <Input name="username" className="form-control" placeholder="Enter User Name" type="text"/>
                </div>
                <div className="form-group">
                  <Label className="form-label mt-4">Nuevo Numero de Teléfono</Label>
                  <Input name="username" className="form-control" placeholder="Enter User Name" type="text"/>
                </div>

                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Guardar Cambios
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
