import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";

//UTILS
import MetaTags from "react-meta-tags";
import {Container, Row, Col, CardBody, Card, CardTitle, Button} from "reactstrap";

//COMPONENTS
import Posts from "components/UV/Dashboard/Posts";
import CardWelcome from "components/UV/Dashboard/Welcome";
import {MainContext} from "../../contexts/Main.context";
import { Lastest } from "components/Dashboard/Lastest";
import { StackedColumnChart } from "components/Helpers/StackedColumnChart";

const UvDashboard = () => {

	const {formatter, backend, showLoader} = useContext(MainContext);
	const [rows, setRows] = useState([]);
	const [elements, setElements] = useState([]);

	useEffect(() => {
		pullData();
	},[]);
	const pullData = async () => {
		showLoader(true);
		const {data} = await backend.getDashboardOwner();
		if(data){
			setRows(data);
			const options = [];
			data.totPayments ? data.totPayments.forEach((el) => options.push({data:el.data.slice(0,6).reverse(), name: formatter.typeEnum(el.name)})) :'';
			setElements(options);
		}
		showLoader(false);
	};
	const userData  = backend.getSession();
	const  frontName =   userData.data.user ? userData.data.user.name : userData.data.lot.slug;
		
	return(
		<React.Fragment>
			<div className="page-content">
				<MetaTags>
					<title> Dashboard | Condominio la raya</title>
				</MetaTags>
				<Container fluid>
					<Row className="align-items-center">
						<Col xs={12} md={6} className="d-flex justify-content-start mb-4"> <h4> Dashboard </h4> </Col>
						<Col xs={12} md={6}>
							<Row className="d-flex justify-content-end">
								<Col xs={12} md={4} className=""></Col>
								<Col xs={12} md={4} className=""></Col>
							</Row>
						</Col>
					</Row>          
					<Row>
						<Col xl="12">
							<Row>
								<Col sm="12" md="12" xl="4">
									<Col>
										<CardWelcome lote={frontName}/>
									</Col>
									<Col>
										<Card className="hover" color="">
											<CardBody>
													<CardTitle className="mb-4">
														Estado de cuenta de la asociación
													</CardTitle>
													{/*<div>*/}
													{/*		<h5 className="mb-3">*/}
													{/*			{rows.accountStatus ? formatter.amount(rows.accountStatus) : <> S/. 1823790428 </>}*/}
													{/*		</h5>*/}
													{/*</div>*/}
													<Link to="/uv-files">
														<Button 
															type="button" 
															color={'success'} 
															className="w-100"
															onClick={() => {toggleModal('create');}}>
																Ir a Documentos
														</Button>
													</Link>
											</CardBody>
										</Card>									
									</Col>
									<Col>
										<Posts data={rows.ultBoletines}/>
									</Col>
								</Col>
								<Col md="12" xl="8">
									{/* Cartas de reporte*/}
									<Row>
										<Col md={4} xs={12}>
												<Card className="hover" color="" style={{backgroundColor: '#FDDC3F'}}>
														<CardBody>
																<CardTitle className="mb-4">
																		<i className="mdi mdi-alert-outline m-3 " />
																			Deuda total de:
																</CardTitle>
																<div>
																	<h5 className="mb-3">{rows.totalDebt ?
																		formatter.amount(rows.totalDebt) : <>No Hay Deudas Pendientes</>}
																	</h5>
																</div>
														</CardBody>
												</Card>
										</Col>
										<Col md={4} xs={12}>
												<Card className="hover" color="">
														<CardBody>
																<CardTitle className="mb-4">
																		<i className="bx bx-calendar-check me-3" />
																		Proxima fecha de cobro
																</CardTitle>
																<div>
																		<h5 className="mb-3">
																			{rows.nextBillDate ? formatter.date(rows.nextBillDate) : <>No agendado</>}
																		</h5>
																</div>
														</CardBody>
												</Card>
										</Col>
										<Col md={4} xs={12}>
												<Card className="hover" color="">
														<CardBody>
																<CardTitle className="mb-4">
																		<i className="bx bx-video me-3" />
																		Proxima reunión
																</CardTitle>
																<div>
																		<h5 className="mb-3">
																			{rows.nextMeetDate ? formatter.date(rows.nextMeetDate) : <>No agendado</>}
																		</h5>
																</div>
														</CardBody>
												</Card>
										</Col>
									</Row>
									{/* Tabla de pagos */}
									<Col>
										<Card>
											<CardBody>
												<h4 className="card-title mb-4 mx-2">Relacion de Gastos Mensual</h4>
												<StackedColumnChart periodData={elements} admin={false}/>
											</CardBody>
										</Card>
									</Col>
									{/* Ultimos pagos */}
									<Col>
										<Lastest data={rows.ultimosPagos} />
									</Col>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};
export default UvDashboard