import React, {useContext, useEffect, useState} from 'react';
import Select from 'react-select'
import {MainContext} from '../../../contexts/Main.context';
import { FormGroup, Input, Label, Modal, Row, Form, InputGroup, InputGroupText, Button} from 'reactstrap';
import PropTypes from 'prop-types';
import {EnumService} from "../../../services/enum.service";
import formatCsv from '../../../assets/formato.csv';

export const BulkInsert = ({active, toggle}) => {
    //Main context
    const {backend, showLoader, toastAlert, changeHandler} = useContext(MainContext);

    const defaultForm = {
        file: '',
        service: '',
        payday: ''
    };

    const [services, setServices] = useState(false)
    const [form, setForm] = useState(defaultForm);

    //Asinar lotes y  lasts
    useEffect(() => {
        pullData();
    },[]);
    const pullData = async () => {
        const {data} = await backend.getLots({limit: 999, attributes: ["slug", "id"], order: ["slug", "asc"]});
        if(data){
            setLots(data);
        }
    };

    const pullLasts = async () => {
        const {data} = await backend.getServiceExpenses(parseInt(form.landId));
        if(data){
            setLasts(data);
            lastsUpdate();
        }
    };

    //Sent data
    const submitHandler = (ev) => {
        showLoader(true);
        ev.preventDefault();
        uploadData();
    };
    const uploadData = async () => {
        const resp = await backend.bulkInsertServices(form);
        showLoader(false);
        if (resp.status) {
            toastAlert({body: 'El archivo se esta procesando, se completara en unos minutos', color: 'primary'});
            toggle('bulk');
            location.reload();
        } else {
            toastAlert({body: 'Error inesperado', color: 'warning'});
        }
        {toggle('bulk');}
    }
    return (
      <Modal size="l" isOpen={active}
             toggle={() => { toggle('bulk'); }}
             centered>
          <div className="modal-header">
              <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                  Ingresa los Datos de la nueva Orden, descarga el formato <a href={formatCsv} download={true}>aquí</a>
              </h5>
              <button onClick={() => {toggle('bulk');}} type="button" className="close"
                      data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body">
              <Row className="mb-12">
                  <Form onSubmit={submitHandler}>
                      <div className="col-md-12 col-sm-12">
                          <Label>Suba el archivo</Label>
                          <Input type="file" name="file" onChange={(ev)=>{changeHandler(ev, form, setForm, true)}} required={true}/>
                      </div>
                      <div className="col-md-12 col-sm-12">
                          <Label className="mt-2">Seleccione el servicio</Label>
                          <Input type="select" name="service" onChange={(ev)=>{changeHandler(ev, form, setForm)}} required={true}>
                              <option value="">Seleccione</option>
                              <option value="LIGHT_SERVICE">Luz</option>
                              <option value="WATER_SERVICE">Agua</option>
                          </Input>
                      </div>
                      <div className="col-md-12 col-sm-12">
                          <Label className="mt-2">Dia de pago</Label>
                          <Input type="date" name="payday" onChange={(ev)=>{changeHandler(ev, form, setForm)}} required={true}/>
                      </div>
                      <div className="modal-footer">
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn btn-danger "
                            onClick={() => {toggle('bulk');}} >
                              Cancelar
                          </button>
                          <button
                            disabled={false}
                              type="submit"
                              aria-label="Close"
                              data-dismiss="modal"
                              className="btn btn-success">
                              Subir
                          </button>
                      </div>
                  </Form>
              </Row>
          </div>
      </Modal>
    );
}

BulkInsert.propTypes = {
    active: PropTypes.bool,
    toggle: PropTypes.func
}

