import React from "react";
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from "reactstrap";

const CardContact = props => {
  const { user } = props
  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>
            <div className="avatar-sm mx-auto mb-4">
              <span
                className={
                  "avatar-title rounded-circle bg-soft bg" +
                  " text-" +
                  " font-size-16"}>
                    {user.name.charAt(0)}
              </span>
            </div>
            <h5 className="mb-1">
              <span className="font-size-14">
                {user.name + ' '+ user.surname}
              </span>
            </h5>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}
CardContact.propTypes = {
  user: PropTypes.object
}
export default CardContact
